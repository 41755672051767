import { enUS } from "./enUS.js";
import { jaJP } from "./jaJP.js";
import { deDE } from "./deDE.js";

window.$currentLocale = "enUS";

function getStringTable(locale) {
   if (!locale) {
      return enUS;
   }

   switch(locale){
      default:
      case "enUS": return enUS;
      case "jaJP": return jaJP;
      case "deDE": return deDE;
   }
}

function getText(key, type, table) {
   if (!key) {
      return "";
   }
   
   const entry = table[key];

   if (entry && entry[type]) {
      return entry[type];
   }

   const index = key.indexOf("_");

   if (index > 0) {
      const altEntry = table[key.substr(index + 1)];
      if (altEntry && altEntry[type]) {
         return altEntry[type];
      }
   }

   if (table !== enUS) {
      return getText(key, type, enUS);   
   }

   return key;
}

export const setCurrentLocale = (locale) => {
   window.$currentLocale = locale;
}

export const getDisplayText = (key) => getText(key, "label", getStringTable(window.$currentLocale));
export const getHelpText = (key) => getText(key, "help", getStringTable(window.$currentLocale));
