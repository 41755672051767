import React, { useState, useEffect, useCallback } from "react";

import * as FormRow from "./FormRow.js";
import * as Utils from "./Utils.js";
import * as Locales from "./locales";
import * as Icons from '@fortawesome/free-regular-svg-icons'
import { MultiSelectList } from "./MultiSelectList";
import { SingleItemPage } from "./SingleItemPage";
import { DocumentVersionList } from "./DocumentVersionPage";
import { FileUpload } from "./FileUpload.js";
import { ProfileExport } from "./ProfileExport.js";

const PageCacheName = Utils.PageNames.ProfileGroups;
const PageApiPath = Utils.PagePaths.ProfileGroups;
const PageName = PageCacheName;

const FieldNames = {
   Name: Utils.FieldNames.Name,
   WorkingGroupId: Utils.FieldNames.WorkingGroupId,
   Sort: Utils.FieldNames.Sort,
   CoreDependencyId: "coreDependencyId",
   ReplacedById: "replacedById",
   NotImplementable: "notImplementable",
   HasTestCases: "hasTestCases"
};

const ChildListNames = {
   Dependencies: "dependencies",
   Specifications: "specifications"
}; 

const FieldDefinitions = [
   {
      name: FieldNames.Name,
      defaultValue: "",
      viewControlType: FormRow.FieldType.Label,
      editControlType: FormRow.FieldType.TextInput,
      maxTextLength: 80,
      placeholder: "X.YY or <Qualifier> X.YY"
   },
   {
      name: FieldNames.WorkingGroupId,
      defaultValue: 1,
      viewControlType: FormRow.FieldType.Label,
      editControlType: FormRow.FieldType.Label
   },
   {
      name: FieldNames.CoreDependencyId,
      defaultValue: Utils.DefaultProfileGroup,
      viewControlType: FormRow.FieldType.Label,
      editControlType: FormRow.FieldType.OptionSet
   },
   {
      name: FieldNames.ReplacedById,
      defaultValue: null,
      viewControlType: FormRow.FieldType.Label,
      editControlType: FormRow.FieldType.OptionSet
   },
   {
      name: FieldNames.NotImplementable,
      defaultValue: false,
      viewControlType: FormRow.FieldType.Label,
      editControlType: FormRow.FieldType.CheckBox
   },
   {
      name: FieldNames.HasTestCases,
      defaultValue: false,
      viewControlType: FormRow.FieldType.Label,
      editControlType: FormRow.FieldType.CheckBox
   },
   {
      name: FieldNames.Sort,
      defaultValue: 0,
      viewControlType: FormRow.FieldType.Label,
      editControlType: FormRow.FieldType.NumberInput,
      minValue: 0,
      maxValue: 10000,
      editorOnly: true
   }
];

const UploadFieldDefinitions = [
   {
      name: "root",
      defaultValue: "Server",
      viewControlType: FormRow.FieldType.Label,
      editControlType: FormRow.FieldType.TextInput,
      maxTextLength: 40
   }
];

const ChildDefinitions = [
   {
      name: ChildListNames.Dependencies,
      url: `${PageApiPath}dependencies/`,
      expanded: true,
      noFilters: true,
      renderList: (props) => <ProfileGroupList {...props} useMultiSelect={true} />
   },
   {
      name: ChildListNames.Specifications,
      url: `${PageApiPath}specifications/`,
      noFilters: true,
      renderList: (props) => <DocumentVersionList {...props} useMultiSelect={true} />
   }
];

const CommandDefinitions = [
   {
      name: "CreateCopy",
      url: `${PageApiPath}copy/`,
      confirmText: () => Locales.getHelpText("profileGroups_CreateCopyConfirmText")
   },
   {
      name: "UploadTestCases",
      renderCommand: (props) => 
            <FileUpload
               {...props}
               caption={"profileGroups_UploadTestCases"}
               dialogYesText={"profileGroups_Upload"}
               uploadUrl={`api${PageApiPath}upload/${props.item.id}`}
               definitions={UploadFieldDefinitions}
            />
   },
   {
      name: "ExportProfileDocument",
      renderCommand: (props) =>
         <ProfileExport
            {...props}
            caption={"profileGroups_ExportProfilesAsWord"}
            fileType="word"
         />
   },
   {
      name: "ExportProfileXml",
      renderCommand: (props) =>
         <ProfileExport
            {...props}
            caption={"profileGroups_ExportProfilesAsXml"}
            fileType="xml"
         />
   }
];

function renderFieldValue(context, name, item, field) {
   if (!item) {
      return null;
   }
   switch (name) {
      case FieldNames.Name: {
         return <FormRow.LinkField
            id={item.id}
            displayText={item.fullName}
            path={Utils.PagePaths.ProfileGroups}
            location={context.location}
            noLink={context.noLink}
            />;
      }
      case FieldNames.WorkingGroupId: {
         let wg = Utils.findInCache(Utils.PagePaths.WorkingGroups, 0, item.workingGroupId);
         return (wg) ? wg.name : item.workingGroupId;
      }
      case FieldNames.CoreDependencyId: {
         let pg = Utils.findInCache(Utils.PagePaths.ProfileGroups, 0, item.coreDependencyId);
         return (pg) ? pg.fullName : '';
      }
      case FieldNames.ReplacedById: {
         if (Utils.isNullId(item.replacedById)) {
            return "";
         }
         let pg = Utils.findInCache(Utils.PagePaths.ProfileGroups, 0, item.replacedById);
         return <FormRow.LinkField
            id={pg.id}
            displayText={pg.fullName}
            path={Utils.PagePaths.ProfileGroups}
            parentPath={context.location}
            noLink={context.noLink}
         />;
      }
      case FieldNames.NotImplementable: {
         return <FormRow.IconField
            falseState={(item.notImplementable) ? Icons.faCheckSquare : Icons.faSquare}
            color={"DarkGray"}
            style={{ marginLeft: '0px' }}
         />;
      }
      case FieldNames.HasTestCases: {
         return <FormRow.IconField
            falseState={(item.hasTestCases) ? Icons.faCheckSquare : Icons.faSquare}
            color={"DarkGray"}
            style={{ marginLeft: '0px' }}
         />;
      }
      default: {
         break;
      }
   }
   return item[name];
}

function toJson(item) {
   const json = {};
   if (item.id) json.id = item.id;
   if (item.name) json.name = item.name;
   if (item.sort) json.sort = item.sort;
   if (item.workingGroupId) json.workingGroupId = item.workingGroupId;
   if (item.coreDependencyId) json.coreDependencyId = item.coreDependencyId;
   if (item.replacedById) json.replacedById = item.replacedById;
   if (item.notImplementable) json.notImplementable = item.notImplementable;
   if (item.hasTestCases) json.hasTestCases = item.hasTestCases;
   return json;
}

function initializeNewItem(props, newItem) {
   const {
      item,
      pageName,
      parent
   } = props;
   if (item?.name) newItem.name = `${item.name} (Copy)`;
   if (item?.sort) newItem.sort = item.sort + 1;
   if (item?.workingGroupId) newItem.workingGroupId = item.workingGroupId;
   if (item?.coreDependencyId) newItem.coreDependencyId = item.coreDependencyId;
   if (parent && pageName === Utils.PageNames.WorkingGroups) {
      newItem.workingGroupId = parent.id;
   }
}

export const ProfileGroupList = (props) => {
   const {
      parent,
      url,
      containerName,
      storageKey
   } = props;

   const columns = [{ name: FieldNames.CoreDependencyId, width: 4 }];
   const sortColumn = { name: FieldNames.Sort };

   return (
      <MultiSelectList
         {...props}
         pageName={PageName}
         containerName={containerName}
         storageKey={storageKey}
         parent={parent}
         url={url}
         pathApiUrl={PageApiPath}
         fieldDefinitions={FieldDefinitions}
         toJson={toJson}
         renderFieldValue={renderFieldValue}
         initializeNewItem={initializeNewItem}
         columns={columns}
         sortColumn={sortColumn}
      />);
}

export const ProfileGroupPage = (props) => {

   const {
      parent,
      url,
      containerName,
      storageKey
   } = props;

   const [profileGroups, setProfileGroups] = useState([]);

   useEffect(() => {
      const profileGroups = Utils.findInCache(Utils.PagePaths.ProfileGroups, 60);
      (profileGroups?.length) ? setProfileGroups(profileGroups) : fetch();
      async function fetch() {
         setProfileGroups(await Utils.fetchRecords(Utils.PagePaths.ProfileGroups));
      };
   }, []);

   const getEditorOptions = useCallback((context, item) => {
      const dependencies = profileGroups.filter(x => x.workingGroupId === Utils.DefaultWorkingGroup)
         .map((ii) => {
            return { id: ii.id, name: ii.fullName, sort: ii.sort };
         }).sort(Utils.sortBySortThenName);
      const replacements = profileGroups.filter(x =>
         x.workingGroupId === item.workingGroupId &&
         x.id !== item.id &&
         x.replacedById !== item.id &&
         x.coreDependencyId >= item.coreDependencyId)
            .map((ii) => {
               return { id: ii.id, name: ii.fullName, sort: ii.sort };
            }).sort(Utils.sortBySortThenName);
      return {
         [FieldNames.CoreDependencyId]: (item.workingGroupId === Utils.DefaultWorkingGroup) ? [Utils.NoneValue] : dependencies,
         [FieldNames.ReplacedById]: [Utils.NoneValue, ...replacements]
      };
   }, [profileGroups]);

   const updateAfterChange = useCallback((parent, items) => {
      if (items) {
         items.map(ii => {
            ii.errorText = (ii.coreDependencyId > parent.coreDependencyId)
               ? Locales.getHelpText(`${PageName}_coreDependencyMismatch`)
               : null;
            return ii;
         });
      }
   }, []);

   return (
      <SingleItemPage
         {...props}
         pageName={PageName}
         pathApiUrl={PageApiPath}
         containerName={containerName}
         storageKey={storageKey}
         parent={parent}
         url={url}
         fieldDefinitions={FieldDefinitions}
         toJson={toJson}
         childDefinitions={ChildDefinitions}
         commandDefinitions={CommandDefinitions}
         renderFieldValue={renderFieldValue}
         getEditorOptions={getEditorOptions}
         updateAfterChange={updateAfterChange}
         initializeNewItem={initializeNewItem}
         profileGroups={profileGroups}
      />);
}
