import React, { useState, useEffect, useRef } from "react";

import * as FormRow from "./FormRow.js";
import * as Utils from "./Utils.js";
import * as Locales from "./locales";
import { Row, Col } from 'reactstrap';
import { MultiSelectList } from "./MultiSelectList";
import { SingleItemPage } from "./SingleItemPage";

const PageCacheName = Utils.PageNames.TestCases;
const PageApiPath = Utils.PagePaths.TestCases;
const PageName = PageCacheName;

export const FieldNames = {
   Name: Utils.FieldNames.Name,
   Details: "details",
   Preconditions: "preconditions",
   Version: "version",
   ExecutionType: "executionType",
   ExecutionDuration: "executionDuration",
   Importance: "importance",
   Keywords: "keywords",
   Steps: "steps",
   ReleaseStatus: Utils.FieldNames.ReleaseStatus,
   Description: Utils.FieldNames.Description,
   InlineDescription: Utils.FieldNames.InlineDescription,
   Search: Utils.FieldNames.Search,
   SearchDescription: Utils.FieldNames.SearchDescription
};

export const ChildListNames = {
   Steps: "steps"
};

const FieldDefinitions = [
   {
      name: FieldNames.Name,
      defaultValue: "",
      viewControlType: FormRow.FieldType.Label,
      maxTextLength: 40
   },
   {
      name: FieldNames.Version,
      defaultValue: "",
      viewControlType: FormRow.FieldType.Label
   },
   {
      name: FieldNames.ReleaseStatus,
      defaultValue: Utils.ReleaseStatus.Draft,
      viewControlType: FormRow.FieldType.Label
   },
   {
      name: FieldNames.Keywords,
      defaultValue: "",
      viewControlType: FormRow.FieldType.ChipArray
   },
   {
      name: FieldNames.Description,
      defaultValue: "",
      viewControlType: FormRow.FieldType.BlockLabel,
      showLabel: true
   },
   {
      name: FieldNames.Preconditions,
      defaultValue: "",
      viewControlType: FormRow.FieldType.BlockLabel,
      showLabel: true
   },
   {
      name: FieldNames.Steps,
      defaultValue: "",
      viewControlType: FormRow.FieldType.BlockCustom,
      showLabel: true
   }
];

const FilterDefinitions = [
   {
      name: FieldNames.Search,
      defaultValue: "",
      editControlType: FormRow.FieldType.TextInput,
      maxTextLength: 40
   },
   {
      name: FieldNames.SearchDescription,
      defaultValue: false,
      editControlType: FormRow.FieldType.CheckBox
   },
];

const TestSteps = (props) => {
   const {
      item
   } = props;

   const [steps, setSteps] = useState([]);
   const itemId = item?.id;
   const mounted = useRef(true);

   useEffect(() => {
      return () => {
         mounted.current = false;
      }
   }, []);

   // fetch
   useEffect(() => {
      fetch(itemId);
      async function fetch(id) {
         const response = await Utils.httpGet(`api/testcase/steps/${id}`);
         if (!mounted.current) {
            return;
         }
         if (response && !response.failed) {
            setSteps(response.result);
         }
         else {
            setSteps([]);
         }
      }
   }, [itemId]);

   if (!steps?.length) {
      return <span>{Locales.getDisplayText(`${PageName}_noStepsFound`)}</span>;
   }

   let count = 1;

   return <div className="p-0 m-0 opc-box-rounded opc-grey fluid w-100">
      <Row className="opc-dark-grey mx-0">
         <Col className="col-auto">#</Col>
         <Col>{Locales.getDisplayText(`testSteps_actions`)}</Col>
         <Col className="me-auto">{Locales.getDisplayText(`testSteps_results`)}</Col>
      </Row>
      {steps.map(ii => {
         return (
            <Row key={count++} className={((count % 2 === 1) ? 'border-bottom border-dark mx-0' : 'border-bottom border-dark mx-0')}>
               <Col className="col-auto">{ii.sort}</Col>
               <Col>{ii.actions}</Col>
               <Col className="me-auto">{ii.description}</Col>
            </Row>);
      })}
   </div>;
}

function renderFieldValue(context, name, item, field) {
   const value = FormRow.renderFieldValue(context, name, item, field, Utils.PagePaths.TestCases);
   if (value) {
      return value;
   }
   switch (name) {
      case FieldNames.ReleaseStatus: {
         const name = Object.keys(Utils.ReleaseStatus).find((ii) => Utils.ReleaseStatus[ii] === item.releaseStatus);
         return Locales.getDisplayText(`releaseStatus_${name}`);
      }
      case FieldNames.Steps: {
         return <TestSteps item={item} />;
      }
      default: {
         return item[name];
      }
   }
}

function toJson(item) {
   return {
      id: item.id,
      name: item.name
   }
}

function updateInitialFilter(props, filters) {
   filters[FieldNames.Search] = "";
   filters.exclude = [
      Utils.FieldNames.ProfileGroupId,
      FieldNames.SearchDescription
   ];
};

function updateBeforeApplyFilters(filters, items) {
   items.map(ii => {
      ii.search = (filters.searchDescription) ? ii.description : ii.name;
      return ii;
   });
};

export const TestCaseList = (props) => {
   const {
      parent,
      url,
      containerName,
      storageKey
   } = props;

   const columns = [{ name: FieldNames.InlineDescription, width: "10" }];
   const sortColumn = { name: FieldNames.Sort };

   return (
      <MultiSelectList
         {...props}
         pageName={PageName}
         containerName={containerName}
         storageKey={storageKey}
         parent={parent}
         url={url}
         pathApiUrl={PageApiPath}
         fieldDefinitions={FieldDefinitions}
         toJson={toJson}
         renderFieldValue={renderFieldValue}
         filterDefinitions={FilterDefinitions}
         updateInitialFilter={updateInitialFilter}
         updateBeforeApplyFilters={updateBeforeApplyFilters}
         columns={columns}
         sortColumn={sortColumn}
         noCreate={true}
         noDelete={true}
      />);
}

export const TestCasePage = (props) => {

   const {
      parent,
      url,
      containerName,
      storageKey
   } = props;

   return (
      <SingleItemPage
         {...props}
         pageName={PageName}
         pathApiUrl={PageApiPath}
         containerName={containerName}
         storageKey={storageKey}
         parent={parent}
         url={url}
         fieldDefinitions={FieldDefinitions}
         toJson={toJson}
         renderFieldValue={renderFieldValue}
      />);
}
