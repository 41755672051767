import React, { useState, useEffect, useCallback, useMemo } from "react";

import * as FormRow from "./FormRow.js";
import * as Utils from "./Utils.js";
import * as Locales from "./locales";
import { MultiSelectList } from "./MultiSelectList";
import { SingleItemPage } from "./SingleItemPage";
import { PersonList } from "./PersonPage";
import { DocumentVersionList } from "./DocumentVersionPage";
import { TagList } from "./TagPage";

const PageCacheName = Utils.PageNames.Documents;
const PageApiPath = Utils.PagePaths.Documents;
const PageName = PageCacheName;

export const DocumentTypes = {
   Series: 1,
   Specification: 2,
   Guideline: 3,
   Template: 4,
   Whitepaper: 5,
   Other: 6,
   Procedures: 7
}

export const AccessLevels = {
   Everyone: 1,
   Registered: 2,
   PaidMember: 3,
   CorporateMember: 4
}

export const FieldNames = {
   Name: Utils.FieldNames.Name,
   WorkingGroupId: Utils.FieldNames.WorkingGroupId,
   ShortName: "shortName",
   DocumentType: "documentType",
   DocumentNumber: "documentNumber",
   MinPartNumber: "minPartNumber",
   MaxPartNumber: "maxPartNumber",
   Scope: "scope",
   ShortNameAndSeries: "shortNameAndSeries",
   Search: "search",
   SearchDescription: "searchDescription",
   LicenseId: "licenseId",
   AccessLevel: "accessLevel",
   IssueReportingUrl: "issueReportingUrl"
};

export const ChildListNames = {
   Editors: "editors",
   ChildDocuments: "childDocuments",
   DocumentVersions: "documentVersions",
   Namespaces: "namespaces"
};

const FieldDefinitions = [
   {
      name: FieldNames.Name,
      defaultValue: "",
      viewControlType: FormRow.FieldType.Label,
      editControlType: FormRow.FieldType.TextInput,
      maxTextLength: 80
   },
   {
      name: FieldNames.ShortName,
      defaultValue: "",
      viewControlType: FormRow.FieldType.Label,
      editControlType: FormRow.FieldType.TextInput,
      maxTextLength: 40
   },
   {
      name: FieldNames.DocumentType,
      defaultValue: 2,
      viewControlType: FormRow.FieldType.Label,
      editControlType: FormRow.FieldType.OptionSet
   },
   {
      name: FieldNames.AccessLevel,
      defaultValue: 2,
      viewControlType: FormRow.FieldType.Label,
      editControlType: FormRow.FieldType.OptionSet
   },
   {
      name: FieldNames.DocumentNumber,
      defaultValue: 10000,
      viewControlType: FormRow.FieldType.Label,
      editControlType: FormRow.FieldType.NumberInput,
      minValue: 1,
      maxValue: 100000
   },
   {
      name: FieldNames.MinPartNumber,
      defaultValue: 1,
      viewControlType: FormRow.FieldType.Label,
      editControlType: FormRow.FieldType.NumberInput,
      minValue: 0,
      maxValue: 1000
   },
   {
      name: FieldNames.MaxPartNumber,
      defaultValue: 1,
      viewControlType: FormRow.FieldType.Label,
      editControlType: FormRow.FieldType.NumberInput,
      minValue: 0,
      maxValue: 1000
   },
   {
      name: FieldNames.LicenseId,
      defaultValue: null,
      viewControlType: FormRow.FieldType.Label,
      editControlType: FormRow.FieldType.OptionSet
   },
   {
      name: FieldNames.IssueReportingUrl,
      defaultValue: "",
      viewControlType: FormRow.FieldType.Label,
      editControlType: FormRow.FieldType.Label
   },
   {
      name: FieldNames.WorkingGroupId,
      defaultValue: 1,
      viewControlType: FormRow.FieldType.Label,
      editControlType: FormRow.FieldType.Label
   },
   {
      name: FieldNames.Scope,
      defaultValue: "",
      viewControlType: FormRow.FieldType.BlockLabel,
      editControlType: FormRow.FieldType.TextArea
   }
];

const FilterDefinitions = [
   {
      name: FieldNames.Search,
      defaultValue: "",
      editControlType: FormRow.FieldType.TextInput,
      maxTextLength: 40
   },
   {
      name: FieldNames.SearchDescription,
      defaultValue: false,
      editControlType: FormRow.FieldType.CheckBox
   }
];

const ChildDefinitions = [
   {
      name: ChildListNames.DocumentVersions,
      url: `${PageApiPath}versions/`,
      expanded: true,
      shouldRenderList: (props, parent) => (parent.documentType !== DocumentTypes.Series),
      renderList: (props) => <DocumentVersionList {...props} />
   },
   {
      name: ChildListNames.ChildDocuments,
      url: `${PageApiPath}children/`,
      expanded: true,
      shouldRenderList: (props, parent) => (parent.documentType === DocumentTypes.Series),
      renderList: (props) => <DocumentList {...props} />
   },
   {
      name: ChildListNames.Editors,
      url: `${PageApiPath}editors/`,
      renderList: (props) => <PersonList {...props} useMultiSelect={true} />
   },
   {
      name: ChildListNames.Namespaces,
      url: `${PageApiPath}namespaces/`,
      noFilters: true,
      renderList: (props) => <TagList {...props} useMultiSelect={true} type={Utils.TagType.Namespace} />
   }
];

function getShortNameAndSeries(item) {
   let value = "";
   if (item.documentNumber >= 10000) {
      value += `${item.documentNumber}`;
      if (item.minPartNumber > 0) {
         if (item.documentType === DocumentTypes.Series) {
            value += `-${item.minPartNumber}...${item.maxPartNumber}`;
         }
         else {
            value += `-${String(item.minPartNumber).padStart(3, '0')}`;
         }
      }
   }
   if (!value.length) {
      value = "---";
   }

   return value;
}

function renderFieldValue(context, name, item, field) {
   switch (name) {
      case FieldNames.Name: {
         return <FormRow.LinkField
            id={item.id}
            displayText={item.name}
            path={Utils.PagePaths.Documents}
            location={context.location}
            noLink={context.noLink}
         />;
      }
      case FieldNames.WorkingGroupId: {
         let wg = Utils.findInCache(Utils.PagePaths.WorkingGroups, 0, item.workingGroupId);
         return (wg) ? wg.name : item.workingGroupId;
      }
      case FieldNames.IssueReportingUrl: {
         if (item.issueReportingUrl) {
            return (
               <a
                  href={item.issueReportingUrl}
                  target="_blank"
                  rel="noreferrer noopener"
               >
                  <img src='https://mantis.opcfoundation.org/images/favicon.ico' alt={item.issueReportingUrl}></img>
               </a>
            );
         }
         return null;
      }
      case FieldNames.DocumentType: {
         const name = Object.keys(DocumentTypes).find((ii) => DocumentTypes[ii] === item.documentType);
         return Locales.getDisplayText(`documentType_${name}`);
      }
      case FieldNames.AccessLevel: {
         const name = Object.keys(AccessLevels).find((ii) => AccessLevels[ii] === item.accessLevel);
         return Locales.getDisplayText(`accessLevel_${name}`);
      }
      case FieldNames.LicenseId: {
         if (Utils.isNullId(item.licenseId)) {
            return "";
         }
         let license = Utils.findInCache(Utils.TagType.License, 0, item.licenseId);
         if (!license) {
            return `Not Found (${item.licenseId})`;
         }
         return <a
            className='m-0 p-0 nav-link'
            target='_blank'
            rel='noopener noreferrer'
            href={license.url}>
            {license.name}
         </a>;
      }
      case FieldNames.ShortNameAndSeries: {
         const value = item[FieldNames.ShortNameAndSeries] = getShortNameAndSeries(item);
         return value;
      }
      default: {
         return item[name];
      }
   }
}

function toJson(item) {
   return {
      id: item.id,
      name: item.name,
      shortName: item.shortName,
      documentType: item.documentType,
      documentNumber: item.documentNumber,
      minPartNumber: item.minPartNumber,
      maxPartNumber: item.maxPartNumber,
      workingGroupId: item.workingGroupId,
      scope: item.scope,
      licenseId: item.licenseId,
      accessLevel: item.accessLevel
   }
}

function initializeNewItem(props, newItem) {
   const {
      item,
      pageName,
      parent
   } = props;
   if (item?.name) newItem.name = `(Copy) ${item.name}`;
   if (item?.shortName) newItem.shortName = `(Copy) ${item.shortName}`;
   if (item?.documentType) newItem.documentType = item.documentType;
   if (item?.documentNumber) newItem.documentNumber = item.documentNumber;
   if (item?.minPartNumber) newItem.minPartNumber = item.minPartNumber;
   if (item?.maxPartNumber) newItem.maxPartNumber = item.maxPartNumber;
   if (item?.workingGroupId) newItem.workingGroupId = item.workingGroupId;
   if (item?.licenseId) newItem.licenseId = item.licenseId;
   if (item?.accessLevel) newItem.accessLevel = item.accessLevel;

   if (parent) {
      if (pageName === Utils.PageNames.WorkingGroups) {
         newItem.workingGroupId = parent.id;
      }
      else if (pageName === Utils.PageNames.Documents) {
         newItem.workingGroupId = parent.workingGroupId;
         newItem.documentNumber = parent.documentNumber;
         newItem.minPartNumber = parent.minPartNumber;
         newItem.maxPartNumber = parent.minPartNumber;
         newItem.accessLevel = parent.accessLevel;
      }
   }
}

function updateInitialFilter(context, filters) {
   filters[FieldNames.Search] = "";
   filters.exclude = [
      Utils.FieldNames.ProfileGroupId,
      FieldNames.SearchDescription
   ];
};

function updateBeforeApplyFilters(filters, items) {
   items.map(ii => {
      ii.search = (!filters.searchDescription) ? ` ${ii.name} ${ii.shortName} ${getShortNameAndSeries(ii)}` : ii.scope;
      return ii;
   });
};

async function fetchLicenses() {
   const url = `api${Utils.PagePaths.Tags}?type=${Utils.TagType.License}`;
   const response = await Utils.httpGet(url);
   if (response?.errorCode) {
      return [];
   }
   let newLicenses = Utils.processGetResponseAndUpdateList(url, response);
   Utils.updateCache(Utils.TagType.License, newLicenses, true);
   return newLicenses;
}

export const DocumentList = (props) => {

   const {
      parent,
      url,
      containerName,
      storageKey
   } = props;

   const [licenses, setLicenses] = useState([]);

   useEffect(() => {
      const licenses = Utils.findInCache(Utils.TagType.License, 60);
      (licenses?.length) ? setLicenses(licenses) : fetch();
      async function fetch() {
         setLicenses(await fetchLicenses());
      };
   }, []);

   const columns = [{ name: FieldNames.ShortNameAndSeries, width: 3 }, { name: FieldNames.DocumentType, width: 2 }];
   const sortColumn = { name: FieldNames.ShortNameAndSeries };

   return (
      <MultiSelectList
         {...props}
         pageName={PageName}
         containerName={containerName}
         storageKey={storageKey}
         parent={parent}
         url={url}
         pathApiUrl={PageApiPath}
         fieldDefinitions={FieldDefinitions}
         toJson={toJson}
         renderFieldValue={renderFieldValue}
         filterDefinitions={FilterDefinitions}
         updateInitialFilter={updateInitialFilter}
         initializeNewItem={initializeNewItem}
         updateBeforeApplyFilters={updateBeforeApplyFilters}
         columns={columns}
         sortColumn={sortColumn}
         licenses={licenses}
      />);
}

export const DocumentPage = (props) => {

   const {
      parent,
      url,
      containerName,
      storageKey
   } = props;

   const [licenses, setLicenses] = useState([]);

   useEffect(() => {
      const licenses = Utils.findInCache(Utils.TagType.License, 60);
      (licenses?.length) ? setLicenses(licenses) : fetch();
      async function fetch() {
         setLicenses(await fetchLicenses());
      };
   }, []);

   const fixedOptions = useMemo(() => {
      const documentTypes = Object.keys(DocumentTypes).map((ii) => {
         return {
            id: DocumentTypes[ii],
            name: Locales.getDisplayText(`documentType_${ii}`)
         };
      });
      const accessLevels = Object.keys(AccessLevels).map((ii) => {
         return {
            id: AccessLevels[ii],
            name: Locales.getDisplayText(`accessLevel_${ii}`)
         };
      });
      return {
         [FieldNames.DocumentType]: documentTypes,
         [FieldNames.AccessLevel]: accessLevels
      };
   }, []);

   const getEditorOptions = useCallback((context, item) => {
      return {
         ...fixedOptions,
         [FieldNames.LicenseId]: [
            ...licenses.map((ii) => {
               return { id: ii.id, name: ii.name };
            }).sort(Utils.sortByName)
         ]
      };
   }, [licenses, fixedOptions]);

   return (
      <SingleItemPage
         {...props}
         pageName={PageName}
         pathApiUrl={PageApiPath}
         containerName={containerName}
         storageKey={storageKey}
         parent={parent}
         url={url}
         fieldDefinitions={FieldDefinitions}
         toJson={toJson}
         childDefinitions={ChildDefinitions}
         renderFieldValue={renderFieldValue}
         getEditorOptions={getEditorOptions}
         initializeNewItem={initializeNewItem}
         licenses={licenses}
      />);
}
