import React, { useState, useEffect, useCallback } from "react";
import { Container, Row, Col } from 'reactstrap';
import { RowIcons } from "./RowIcons";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { withStyles } from '@mui/styles';

import Box from '@mui/material/Box';

import * as Locales from "./locales";
import * as Utils from "./Utils.js";
import * as FormRow from "./FormRow.js";

export const StyledDialog = withStyles({
   paperScrollPaper: {
      minWidth: '768px'
   }
})(Dialog);

export const ItemCommands = (props) => {
   const {
      containerName,
      childListName,
      commands,
      storageKey,
      item,
      onCompleted
   } = props;

   const [expanded, setExpanded] = useState(props.expanded);
   const [error, setError] = useState(null);
   const [activeCommand, setActiveCommand] = useState({});
   const [caption, setCaption] = useState("");
   const [showDialog, setShowDialog] = useState(false);

   useEffect(() => {
      setExpanded(props.expanded);
   }, [props.expanded]);

   useEffect(() => {
      let newExpanded = Utils.load(storageKey + "/expanded", { expanded: props.expanded });
      setExpanded(newExpanded.expanded);
   }, [storageKey, props.expanded]);

   useEffect(() => {
      Utils.save(storageKey + "/expanded", { expanded: expanded });
   }, [expanded, storageKey]);

   const toggleExpanded = (e, item) => {
      e.preventDefault();
      setExpanded(!expanded);
   };

   const onCancel = useCallback((e) => {
      e.preventDefault();
      setShowDialog(false);
   }, []);

   const onClick = useCallback(async (e, command) => {
      e.preventDefault();
      const text = `${(command.confirmText) ? command.confirmText(item) : Locales.getDisplayText("confirmCommand")}`
      await setActiveCommand(command);
      await setCaption(text);
      await setShowDialog(true);
   }, [item]);

   const onOk = useCallback(async (e, command) => {
      e.preventDefault();
      await setShowDialog(false);
      setError({ errorCode: "Processing...", failed: false });
      const response = await Utils.httpGet(`api${command.url}${item.id}`);
      if (response?.errorCode) {
         response.failed = true;
         setError(response);
      }
      else {
         if (onCompleted) {
            onCompleted(response.result);
         }
         setError({ errorCode: null, failed: false });
      }

   }, [item, onCompleted]);

   let body = null;

   if (expanded) {
      let count = 1
      body = (<Row className="m-0 p-0 opc-button-row gx-1">
         <Col className="m-0 mt-1 mb-1 me-auto">
            {commands.map(ii => {
               if (ii.renderCommand) {
                  return <div className="d-inline-flex pe-1" key={count++} >
                     {ii.renderCommand({
                        ...props,
                        containerName,
                        item,
                        definition: ii,
                        onCompleted: onCompleted
                     })}
                  </div>
               }
               return <div className="d-inline-flex pe-1" key={count++} >
                  <Utils.StyledTooltip title={Locales.getHelpText(`${containerName}_${ii.name}`)}>
                     <div className="d-inline border-0 m-0 p-0">
                        <Button
                           disabled={!item.hasWriteAccess}
                           onClick={(e) => onClick(e, ii)}
                        >
                           {Locales.getDisplayText(`${containerName}_${ii.name}`)}
                        </Button>
                     </div>
                  </Utils.StyledTooltip>
               </div>
            })}
         </Col>
      </Row>);
   }

let errorRow = null;

if (error?.errorCode?.length) {
   errorRow = <FormRow.ErrorRow item={error} failed={error.failed} className="mb-2 mt-2" />
}

return (
   <Container className="fluid border-0 m-0 p-0 mt-2">
      <Box
         boxShadow={2}
         bgcolor="background.paper"
         className="m-0 p-0 mt-1 mb-2 w-100"
      >
         <Row className="m-0 fw-bold opc-dark-yellow">
            <RowIcons
               icons={[{ name: "toggle", state: expanded, onClick: toggleExpanded }]}
               right={true}
               dark={true}
            />
            <Col
               xs="auto"
               className='p-0 m-0 ms-1 me-auto'
               style={{ cursor: 'default' }}
            >
               <Utils.StyledTooltip title={Locales.getHelpText(childListName)}>
                  <span>{Locales.getDisplayText(childListName)}</span>
               </Utils.StyledTooltip>
            </Col>
         </Row>
         {errorRow}
         {body}
      </Box>
      <StyledDialog
         open={showDialog}
         onClose={onCancel}
         aria-labelledby="alert-dialog-title"
         aria-describedby="alert-dialog-description"
      >
         <DialogContent>{caption}</DialogContent>
         <DialogActions>
            <Button onClick={(e) => onOk(e, activeCommand)} color="primary" autoFocus>
               Yes
            </Button>
            <Button onClick={(e) => onCancel(e, activeCommand)} color="primary">
               No
            </Button>
         </DialogActions>
      </StyledDialog>
   </Container>);
}