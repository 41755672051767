import React from "react";
import { useLocation } from "react-router-dom";
import { ErrorRow } from "./FormRow";

export const ErrorPage = (props) => {
   const query = new URLSearchParams(useLocation().search);

   let code = query.get("error");
   let description = query.get("error_description");
   let uri = query.get("error_uri");

   if (props.item) {
      code = props.item.errorCode;
      description = props.item.errorText;
   }

   return <ErrorRow item={{ errorCode: code, errorText: description, errorUri: uri, failed: true }} />
}
