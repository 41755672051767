import React, { useState, useEffect, useRef } from "react";

import * as FormRow from "./FormRow.js";
import * as Utils from "./Utils.js";
import { MultiSelectList } from "./MultiSelectList";
import { SingleItemPage } from "./SingleItemPage";
import { TestCaseList } from "./TestCasePage";

const PageCacheName = Utils.PageNames.TestSuites;
const PageApiPath = Utils.PagePaths.TestSuites;
const PageName = PageCacheName;

export const FieldNames = {
   Name: Utils.FieldNames.Name,
   Description: Utils.FieldNames.Description,
   ConformanceUnit: "conformanceUnitGuid",
   HasTestCases: "hasTestCases",
   Keywords: "keywords",
   InlineDescription: Utils.FieldNames.InlineDescription,
   Search: Utils.FieldNames.Search,
   SearchDescription: Utils.FieldNames.SearchDescription,
   HasConformanceUnit: "hasConformanceUnit"
};

export const ChildListNames = {
   Children: "children",
   TestCases: "testCases"
};

const FieldDefinitions = [
   {
      name: FieldNames.Name,
      defaultValue: "",
      viewControlType: FormRow.FieldType.Label,
      maxTextLength: 40
   },
   {
      name: FieldNames.ConformanceUnit,
      defaultValue: "",
      viewControlType: FormRow.FieldType.Label
   },
   {
      name: FieldNames.Keywords,
      defaultValue: "",
      viewControlType: FormRow.FieldType.ChipArray
   },
   {
      name: FieldNames.Description,
      defaultValue: "",
      viewControlType: FormRow.FieldType.BlockLabel,
      showLabel: true
   }
];

const FilterDefinitions = [
   {
      name: FieldNames.Search,
      defaultValue: "",
      editControlType: FormRow.FieldType.TextInput,
      maxTextLength: 40
   },
   {
      name: FieldNames.SearchDescription,
      defaultValue: false,
      editControlType: FormRow.FieldType.CheckBox
   },
   {
      name: FieldNames.HasTestCases,
      defaultValue: true,
      editControlType: FormRow.FieldType.CheckBox
   },
   {
      name: FieldNames.HasConformanceUnit,
      defaultValue: true,
      editControlType: FormRow.FieldType.CheckBox
   }
];

const ChildDefinitions = [
   {
      name: ChildListNames.Children,
      url: "/testsuite/children/",
      expanded: false,
      noFilters: true,
      shouldRenderList: (props, parent) => (!parent?.hasTestCases),
      renderList: (props) => <TestSuiteList {...props} />
   },
   {
      name: ChildListNames.TestCases,
      url: "/testsuite/testcases/",
      expanded: true,
      shouldRenderList: (props, parent) => (parent?.hasTestCases),
      renderList: (props) => <TestCaseList {...props} />
   }
];

const ConformanceUnitName = (props) => {
   const {
      location,
      noLink,
      item
   } = props;

   const mounted = useRef(true);
   const [conformanceUnit, setConformanceUnit] = useState(null);
   const conformanceUnitGuid = item?.conformanceUnitGuid;
   const profileGroupId = item?.conformanceUnitPgId;

   useEffect(() => {
      return () => {
         mounted.current = false;
      }
   }, []);

   useEffect(() => {
      (conformanceUnitGuid?.length) ? fetch() : setConformanceUnit(null);
      async function fetch() {
         const url = `api${Utils.PagePaths.ConformanceUnits}getbyguid/${conformanceUnitGuid}/${profileGroupId}`;
         const response = await Utils.httpGet(url);
         if (!mounted.current) {
            return;
         }
         setConformanceUnit(oldItem => {
            if (!response?.errorCode) {
               const item = Utils.processGetReponseAndUpdateItem(url, response);
               return item;
            }
            return null;
         });
      }
   }, [conformanceUnitGuid, profileGroupId]);

   if (!conformanceUnit?.name) {
      return "";
   }
   return <FormRow.LinkField
      id={conformanceUnit.id}
      item={conformanceUnit}
      checkDependencies={true}
      displayText={conformanceUnit.name}
      path={Utils.PagePaths.ConformanceUnits}
      location={location}
      noLink={noLink}
   />;
}

function renderFieldValue(context, name, item, field) {
   const value = FormRow.renderFieldValue(context, name, item, field, Utils.PagePaths.TestSuites);
   if (value) {
      return value;
   }
   switch (name) {
      case FieldNames.ConformanceUnit: {
         return <ConformanceUnitName {...context} item={item} />;
      }
      default: {
         return item[name];
      }
   }
}

function toJson(item) {
   return {
      id: item.id,
      name: item.name
   }
}

function updateInitialFilter(props, filters) {
   filters[FieldNames.Search] = "";
   filters.exclude = [
      Utils.FieldNames.ProfileGroupId,
      FieldNames.SearchDescription
   ];
};

function updateBeforeApplyFilters(filters, items) {
   items.map(ii => {
      ii.search = (filters.searchDescription) ? ii.description : ii.name;
      ii.hasConformanceUnit = (ii.conformanceUnitGuid?.length) ? true : false;
      return ii;
   });
};

export const TestSuiteList = (props) => {
   const {
      parent,
      url,
      containerName,
      storageKey
   } = props;

   const columns = [{ name: FieldNames.InlineDescription, width: "8" }];
   const sortColumn = { name: FieldNames.Sort };

   return (
      <MultiSelectList
         {...props}
         pageName={PageName}
         containerName={containerName}
         storageKey={storageKey}
         parent={parent}
         url={url}
         filterByUserContext={true}
         pathApiUrl={PageApiPath}
         fieldDefinitions={FieldDefinitions}
         toJson={toJson}
         renderFieldValue={renderFieldValue}
         filterDefinitions={FilterDefinitions}
         updateInitialFilter={updateInitialFilter}
         updateBeforeApplyFilters={updateBeforeApplyFilters}
         noCreate={true}
         noDelete={true}
         columns={columns}
         sortColumn={sortColumn}
      />);
}

export const TestSuitePage = (props) => {

   const {
      parent,
      url,
      containerName,
      storageKey
   } = props;

   return (
      <SingleItemPage
         {...props}
         pageName={PageName}
         pathApiUrl={PageApiPath}
         containerName={containerName}
         storageKey={storageKey}
         parent={parent}
         url={url}
         fieldDefinitions={FieldDefinitions}
         toJson={toJson}
         noCreate={true}
         noDelete={true}
         childDefinitions={ChildDefinitions}
         renderFieldValue={renderFieldValue}
      />);
}
