import React, { useState, useEffect } from "react";

import * as Utils from "./Utils.js";

export const PageTitle = (props) => {
   const [pageTitle, setPageTitle] = useState();

   useEffect(() => {
      setPageTitle(Utils.getPageTitle());
   }, [props.cacheVersion]);

   return <span className="text-light">{`${pageTitle}`}</span>
}
