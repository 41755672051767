import React from "react";
import { Col, Container } from 'reactstrap';
import { ErrorRow } from "./FormRow";

export function ReadOnlyForm(props) {
   let error = null;

   if (props.item && props.item.errorCode && props.item.errorCode.length > 0) {
      error =
         <Container className="p-1 pb-0 m-0" >
            <ErrorRow item={props.item} failed={props.item.failed} />
         </Container>;
   }

   return (
      <Col xs="12" className={"p-0 m-0 bg-white opc-blue" + ((props.borderStyle) ? " " + props.borderStyle : "")}>
         {error}
         <Container className="p-0 m-0">
            {props.renderFields(props, props.item)}
         </Container>
      </Col>);
}
