import React, { useEffect, useState } from 'react';
import { useLocation, useHistory } from "react-router-dom";

import * as Utils from "./Utils.js";

export const Home = (props) => {

   const [profileGroups, setProfileGroups] = useState([]);

   const history = useHistory();
   const query = new URLSearchParams(useLocation().search);
   const profileGroupName = query.get("pg");
   const releaseStatus = query.get("rs");
   const pageName = query.get("pn");

   useEffect(() => {
      const pageTitle = "OPC UA Profile Reporting Application";
      Utils.updatePageTitle(pageTitle);
   }, [props.cacheVersion]);

   useEffect(() => {
      const profileGroups = Utils.findInCache(Utils.PagePaths.ProfileGroups, 60);
      (profileGroups?.length) ? setProfileGroups(profileGroups) : fetch();
      async function fetch() {
         setProfileGroups(await Utils.fetchRecords(Utils.PagePaths.ProfileGroups));
      };
   }, []);

   useEffect(() => {
      if (profileGroupName?.length && profileGroups?.length) {
         fetch();
      }
      async function fetch() {
         profileGroups.some((ii) => {
            if (ii.fullName === profileGroupName) {
               const user = Utils.getCurrentUser();
               Utils.setCurrentUser({
                  ...user,
                  profileGroupId: ii.id,
                  workingGroupId: ii.workingGroupId,
                  releaseStatus: (releaseStatus) ? Number(releaseStatus) : Utils.ReleaseStatus.Released,
                  requiredProfileGroups: ii.requiredProfileGroups
               }, true);
               return true;
            }
            return false;
         });
         if (pageName?.length) {
            Utils.navigateForward(history, `${pageName}`);
         }
      };
   }, [history, pageName, profileGroupName, releaseStatus, profileGroups]);

   return (
      <div>
         <h1>Profile Reporting</h1>
         <p>Welcome to the Profile Reporting Application for the OPC UA Unified Architecture family of a specifications.</p>
         <p>This application allows anyone the browse the currently defined Profiles.</p>
         <p>Editors of companion specifications may also use this application to manage the Profiles defined for their specification.</p>
         <p>Any issues or feature requests should be reported <a href='https://mantis.opcfoundation.org/set_project.php?project_id=81&amp;make_default=no' target='_blank' rel='noopener noreferrer'>here</a>.</p>
      </div>
   );
}
