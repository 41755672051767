import React, { useCallback } from "react";

import * as FormRow from "./FormRow.js";
import * as Utils from "./Utils.js";
import * as Locales from "./locales";
import { MultiSelectList } from "./MultiSelectList";
import { SingleItemPage } from "./SingleItemPage";
import { ConformanceUnitList } from "./ConformanceUnitPage";

const PageCacheName = Utils.PageNames.ConformanceGroups;
const PageApiPath = Utils.PagePaths.ConformanceGroups;
const PageName = PageCacheName;

export const FieldNames = {
   Name: Utils.FieldNames.Name,
   Description: Utils.FieldNames.Description,
   Sort: Utils.FieldNames.Sort,
   ReleaseStatus: Utils.FieldNames.ReleaseStatus,
   Version: Utils.FieldNames.Version,
   LastUpdateTime: Utils.FieldNames.LastUpdateTime,
   LastUpdatingUserId: Utils.FieldNames.LastUpdatingUserId,
   ProfileGroupId: Utils.FieldNames.ProfileGroupId,
   WorkingGroupId: Utils.FieldNames.WorkingGroupId,
   InlineDescription: Utils.FieldNames.InlineDescription,
   Search: Utils.FieldNames.Search,
   SearchDescription: Utils.FieldNames.SearchDescription
};

export const ChildListNames = {
   ConformanceUnits: "conformanceUnits"
};

const FieldDefinitions = [
   {
      name: FieldNames.Name,
      defaultValue: "",
      viewControlType: FormRow.FieldType.Label,
      editControlType: FormRow.FieldType.TextInput,
      maxTextLength: 80
   },
   {
      name: FieldNames.ReleaseStatus,
      defaultValue: Utils.ReleaseStatus.Draft,
      viewControlType: FormRow.FieldType.Label,
      editControlType: FormRow.FieldType.OptionSet
   },
   {
      name: FieldNames.Sort,
      defaultValue: 0,
      viewControlType: FormRow.FieldType.Label,
      editControlType: FormRow.FieldType.NumberInput,
      minValue: 0,
      maxValue: 10000,
      editorOnly: true
   },
   {
      name: FieldNames.Version,
      defaultValue: 1,
      viewControlType: FormRow.FieldType.Label,
      editorOnly: true
   },
   {
      name: FieldNames.LastUpdateTime,
      defaultValue: "",
      viewControlType: FormRow.FieldType.Label,
      editorOnly: true
   },
   {
      name: FieldNames.LastUpdatingUserId,
      defaultValue: "",
      viewControlType: FormRow.FieldType.Label,
      editorOnly: true
   },
   {
      name: FieldNames.ProfileGroupId,
      defaultValue: Utils.DefaultProfileGroup,
      viewControlType: FormRow.FieldType.Label
   },
   {
      name: FieldNames.Description,
      defaultValue: "",
      viewControlType: FormRow.FieldType.BlockLabel,
      editControlType: FormRow.FieldType.TextArea
   }
];

const FilterDefinitions = [
   {
      name: FieldNames.Search,
      defaultValue: "",
      editControlType: FormRow.FieldType.TextInput,
      maxTextLength: 40
   },
   {
      name: FieldNames.SearchDescription,
      defaultValue: false,
      editControlType: FormRow.FieldType.CheckBox
   }
];

const ChildDefinitions = [
   {
      name: ChildListNames.ConformanceUnits,
      url: `${PageApiPath}conformanceunits/`,
      expanded: true,
      renderList: (props) => <ConformanceUnitList {...props} enableEditSort={true} />
   }
];

function renderFieldValue(context, name, item, field) {
   const value = FormRow.renderFieldValue(context, name, item, field, Utils.PagePaths.ConformanceGroups);
   if (value) {
      return value;
   }
   return item[name];
}

function toJson(item) {
   return {
      id: item.id,
      name: item.name,
      description: item.description,
      releaseStatus: item.releaseStatus,
      sort: item.sort,
      profileGroupId: item.profileGroupId,
      workingGroupId: item.workingGroupId,
      lastUpdateTime: Utils.getDate(item.lastUpdateTime)
   }
}

function initializeNewItem(props, newItem) {
   const {
      item,
      user,
      items
   } = props;
   newItem.sort = Utils.getMaxSort(items);
   if (user?.profileGroupId) newItem.profileGroupId = user.profileGroupId;
   if (user?.workingGroupId) newItem.workingGroupId = user.workingGroupId;
   if (item?.name) newItem.name = `${item.name} (Copy)`;
   if (item?.sort) newItem.sort = item.sort + 1;
   if (item?.profileGroupId) newItem.profileGroupId = item.profileGroupId;
   if (item?.workingGroupId) newItem.workingGroupId = item.workingGroupId;
}

function getEditorStates(props, item) {
   const states = {
      [FieldNames.Name]: FormRow.FieldState.Normal,
      [FieldNames.Description]: FormRow.FieldState.Normal,
      [FieldNames.Sort]: FormRow.FieldState.Normal,
      [FieldNames.Version]: FormRow.FieldState.Normal,
      [FieldNames.LastUpdateTime]: FormRow.FieldState.Hidden,
      [FieldNames.LastUpdatingUserId]: FormRow.FieldState.Hidden
   };
   if (item.releaseStatus >= Utils.ReleaseStatus.Released) {
      return {
         ...states,
         [FieldNames.Name]: FormRow.FieldState.Disabled,
         [FieldNames.Description]: FormRow.FieldState.Disabled
      };
   }
   return states;
};

function updateInitialFilter(props, filters) {
   filters[FieldNames.Search] = "";
   filters.exclude = [
      Utils.FieldNames.ProfileGroupId,
      FieldNames.SearchDescription
   ];
};

function updateBeforeApplyFilters(filters, items) {
   items.map(ii => {
      ii.search = (!filters.searchDescription) ? ii.name : ii.description;
      return ii;
   });
};

export const ConformanceGroupList = (props) => {
  
   const {
      parent,
      url,
      containerName,
      storageKey
   } = props;

   const columns = [{ name: FieldNames.InlineDescription, width: "8" }];
   const sortColumn = { name: FieldNames.Sort };

   return (
      <MultiSelectList
         {...props}
         pageName={PageName}
         filterByUserContext={true}
         containerName={containerName}
         storageKey={storageKey}
         parent={parent}
         url={url}
         pathApiUrl={PageApiPath}
         fieldDefinitions={FieldDefinitions}
         toJson={toJson}
         renderFieldValue={renderFieldValue}
         initializeNewItem={initializeNewItem}
         filterDefinitions={FilterDefinitions}
         updateInitialFilter={updateInitialFilter}
         updateBeforeApplyFilters={updateBeforeApplyFilters}
         columns={columns}
         sortColumn={sortColumn}
      />);
}

export const ConformanceGroupPage = (props) => {

   const {
      parent,
      url,
      containerName,
      storageKey
   } = props;

   const getEditorOptions = useCallback((context, item) => {
      const releaseStatuses = Object.keys(Utils.ReleaseStatus).map((ii) => {
         return {
            id: Utils.ReleaseStatus[ii],
            name: Locales.getDisplayText(`releaseStatus_${ii}`)
         };
      });
      return {
         [FieldNames.ReleaseStatus]: releaseStatuses
      };
   }, []);

   return (
      <SingleItemPage
         {...props}
         pageName={PageName}
         pathApiUrl={PageApiPath}
         containerName={containerName}
         storageKey={storageKey}
         parent={parent}
         url={url}
         fieldDefinitions={FieldDefinitions}
         toJson={toJson}
         childDefinitions={ChildDefinitions}
         renderFieldValue={renderFieldValue}
         getEditorOptions={getEditorOptions}
         getEditorStates={getEditorStates}
         initializeNewItem={initializeNewItem}
      />);
}
