import React, { useState, useEffect } from "react";
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Container, Row, Col } from 'reactstrap';
import { MessageBox } from "./MessageBox"
import { ItemHistory } from "./ItemHistory";
import { ItemCommands } from "./ItemCommands";
import { TextInputRow, OptionSetRow } from "./FormRow";

import Box from '@mui/material/Box';

import * as Locales from "./locales";
import * as Utils from "./Utils.js";
import * as Solid from '@fortawesome/free-solid-svg-icons'
import * as RowIcons from "./RowIcons.js";

export function renderChildren(props, parent) {

   const {
      user,
      storageKey,
      pageApiUrl,
      containerName,
      containerUrl,
      childDefinitions
   } = props;

   return (
      <div>
         {childDefinitions.map((ii) => {
            if (parent.id === 0 || (ii.shouldRenderList && !ii.shouldRenderList(props, parent))) {
               return null;
            }
            const renderChildList = (context) => {
               return ii.renderList({
                  ...context,
                  user: user,
                  url: ii.url,
                  parent: parent,
                  updateAfterChange: props.updateAfterChange,
                  nested: true
               });
            }
            return (<ItemChildList
               key={ii.name}
               item={parent}
               storageKey={`${storageKey}/${ii.name}`}
               containerName={containerName}
               containerUrl={containerUrl ?? pageApiUrl}
               childListName={ii.name}
               expanded={ii.expanded}
               noFilters={ii.noFilters}
               renderChildList={renderChildList}
            />);
         })}
      </div>
   );
}

export function renderDefaultFilters(props) {
   let { callbacks, fields, filters, renderFieldName } = props;

   if (!fields) {
      fields = [];
   }

   if (!filters.hide.name) {
      fields.push(
         <TextInputRow
            key="name"
            name="name"
            displayText={renderFieldName(props, "name")}
            value={filters.name}
            onChange={callbacks.onTextChanged}
            width={75}
         />
      );
   }

   if (!props.filters.hide.releaseStatus) {
      fields.push(
         <OptionSetRow
            key="releaseStatus"
            name="releaseStatus"
            displayText={renderFieldName(props, "releaseStatus")}
            value={((filters && filters.releaseStatus) ? filters.releaseStatus.toString() : -1)}
            onChange={callbacks.onSelectChanged}
            items={[Utils.AllValue, ...Utils.ReleaseStatus]}
         />
      );

      return fields;
   }

   if (!filters.hide.profileGroupId) {
      const workingGroups = [];

      fields.push(
         <OptionSetRow
            key="workingGroupId"
            name="workingGroupId"
            displayText={renderFieldName(props, "workingGroupId")}
            value={(filters && filters.workingGroupId) ? filters.workingGroupId.toString() : -1}
            onChange={callbacks.onSelectChanged}
            items={workingGroups}
         />
      );

      const profileGroups = [];

      fields.push(
         <OptionSetRow
            key="profileGroupId"
            name="profileGroupId"
            displayText={renderFieldName(props, "profileGroupId")}
            value={(filters && filters.profileGroupId) ? filters.profileGroupId.toString() : -1}
            onChange={callbacks.onSelectChanged}
            items={profileGroups}
         />
      );
   }
}

const ErrorCode_ReleaseStatusHidden = "ReleaseStatusHidden";

export const ItemPage = (props) => {

   const {
      user,
      storageKey,
      containerName,
      containerUrl,
      commands,
      commandsExpanded,
      onCommandCompleted,
      editingEnabled,
      createNewItem,
      onItemUpdated,
      onItemDeleted,
      renderExpanded,
      renderChildren,
      noToggleEditing,
      updateCount
   } = props;

   const [showDialog, setShowDialog] = useState(false);
   const [dialogCaption, setDialogCaption] = useState(null);
   const [item, setItem] = useState(props.item);
   const isEditor = user?.isEditor;
   const history = useHistory();
   const releaseStatus = item?.releaseStatus;
   const userReleaseStatus = Utils.getCurrentUser().releaseStatus;

   useEffect(() => {
      window.scroll({ top: 0 });
   }, []);

   useEffect(() => {
      setItem(props.item);
      window.scroll({ top: 0 });
   }, [props.item])

   useEffect(() => {
      if (!item.errorCode || item.errorCode === ErrorCode_ReleaseStatusHidden) {
         if (releaseStatus < userReleaseStatus) {
            item.errorCode = ErrorCode_ReleaseStatusHidden;
            item.errorText = Locales.getDisplayText(ErrorCode_ReleaseStatusHidden);
         }
         else {
            item.errorCode = null;
            item.errorText = null;
         }
      }
   }, [item, releaseStatus, userReleaseStatus])

   useEffect(() => {
      let pageTitle = Locales.getDisplayText(`itemPage_${containerName}`);
      if (item?.name) {
         pageTitle = `${pageTitle} ${item.name}`;
      }
      Utils.updatePageTitle(pageTitle);
   }, [containerName, item]);

   const toggleItemEditing = (e, item) => {
      setItem({ ...item, editing: !item.editing });
   };

   const confirmDelete = (e, item) => {
      e.preventDefault();
      setDialogCaption("Confirm delete of '" + item.name + "'?")
      setShowDialog(true);
   };

   const deleteConfirmed = (e, item) => {
      e.preventDefault();
      setShowDialog(false);
      onItemDeleted(item);
   };

   const cancelDialog = (e) => {
      e.preventDefault();
      setShowDialog(false);
   };

   const goBack = (e) => {
      e.preventDefault();
      return Utils.navigateBack(history, containerUrl, (item.toJson) ? item.toJson(item) : item);
   };

   let body = renderExpanded({
      ...props,
      item: item,
      editingEnabled: editingEnabled && item.editing,
      borderStyle: null,
      updateItem: onItemUpdated
   });

   let children = null;

   if (renderChildren) {
      children = renderChildren(props, item);
   }

   let itemCommands = null;

   if (item && item.id && commands) {
      if (isEditor) {
         itemCommands =
            <ItemCommands
               item={item}
               containerName={containerName}
               commands={commands}
               expanded={commandsExpanded}
               onCompleted={onCommandCompleted}
               childListName="commands"
               storageKey={`${storageKey}/commands`}
            />;
      }
   }

   let itemHistory = null;

   if (item && item.version && item.lastUpdateTime) {
      if (isEditor) {
         itemHistory =
            <ItemHistory
               {...props}
               item={item}
               childListName="history"
               storageKey={`${storageKey}/history`}
               updateCount={updateCount}
            />;
      }
   }

   let iconset = [];

   if (editingEnabled) {
      if (!noToggleEditing && onItemUpdated) {
         iconset.push({ name: RowIcons.Icons.Edit, state: item.editing, onClick: toggleItemEditing });
      }
      if (!item.editing) {
         if (onItemDeleted) {
            iconset.push({ name: RowIcons.Icons.Delete, onClick: confirmDelete });
         }
         if (createNewItem) {
            iconset.push({ name: RowIcons.Icons.Create, onClick: createNewItem });
         }
      }
   }

   return (
      <div>
         <Row className="m-0 p-0">
            <Box
               boxShadow={2}
               bgcolor="background.paper"
               className="m-0 p-0 w-100"
            >
               <Row
                  className={`mt-0 mb-0 opc-dark-blue`}
                  style={{ marginLeft: '-1px', marginRight: '-1px' }}
               >
                  <Col
                     xs="auto"
                     className='p-0 m-0 ms-1 me-auto'
                     style={{ cursor: 'default' }}
                  >
                     <Utils.StyledTooltip title={Locales.getHelpText("goBackButton")}>
                        <span>
                           <FontAwesomeIcon
                              icon={Solid.faArrowAltCircleLeft}
                              style={{ color: 'white' }}
                              onClick={(e) => goBack(e)}
                           />
                        </span>
                     </Utils.StyledTooltip>
                  </Col>
                  <RowIcons.RowIcons
                     context={item}
                     icons={iconset}
                     right={true}
                     dark={true}
                  />
               </Row>
               {body}
            </Box>
         </Row>
         {itemCommands}
         {itemHistory}
         {children}
         <MessageBox
            caption={dialogCaption}
            show={showDialog}
            item={item}
            render={renderExpanded}
            onOk={deleteConfirmed}
            onCancel={cancelDialog}
         />
      </div>
   );
}

export const ItemChildList = (props) => {
   const {
      storageKey,
      containerName,
      childListName,
      renderChildList,
      noFilters
   } = props;

   const [expanded, setExpanded] = useState(false);
   const [showFilters, setShowFilters] = useState(false);

   useEffect(() => {
      setExpanded(props.expanded);
   }, [props.expanded]);

   useEffect(() => {
      setShowFilters(props.showFilters);
   }, [props.showFilters]);

   useEffect(() => {
      let newExpanded = Utils.load(storageKey + "/expanded", { expanded: props.expanded });
      setExpanded(newExpanded.expanded);
   }, [storageKey, props.expanded]);

   useEffect(() => {
      Utils.save(storageKey + "/expanded", { expanded, showFilters });
   }, [expanded, showFilters, storageKey]);

   const onToggleList = (e, item) => {
      e.preventDefault();
      setExpanded(x => !x);
   };

   const onToggleFilters = (e, item) => {
      e.preventDefault();
      setShowFilters(x => !x);
   };

   let body = null;
   if (expanded) {
      body = renderChildList({ ...props, noFilters: !showFilters });
   }

   let toggleFilters = null;
   if (!noFilters) {
      toggleFilters =
         <RowIcons.RowIcons
            icons={[{ name: RowIcons.Icons.Filters, state: showFilters, onClick: onToggleFilters }]}
            right={true}
            dark={true}
         />;
   }

   return (
      <Container className="fluid border-0 m-0 p-0 mt-2">
         <Box
            boxShadow={2}
            bgcolor="background.paper"
            className="m-0 p-0 mt-1 mb-2 w-100"
         >
            <Row className="m-0 fw-bold opc-dark-blue" style={{ marginLeft: '-1px', marginRight: '-1px' }}>
               <RowIcons.RowIcons
                  icons={[{ name: RowIcons.Icons.Toggle, state: expanded, onClick: onToggleList }]}
                  right={true}
                  dark={true}
               />
               <Col
                  xs="auto"
                  className='p-0 m-0 ms-1 me-auto'
                  style={{ cursor: 'default' }}
               >
                  <Utils.StyledTooltip title={Locales.getHelpText(`${containerName}_${childListName}`)}>
                     <span>{Locales.getDisplayText(`${containerName}_${childListName}`)}</span>
                  </Utils.StyledTooltip>
               </Col>
               {toggleFilters}
            </Row>
         </Box>
         {body}
      </Container>);
}