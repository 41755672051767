import React, { useState, useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";

import * as FormRow from "./FormRow.js";
import * as Utils from "./Utils.js";
import * as Locales from "./locales";
import { MultiSelectList } from "./MultiSelectList";
import { SingleItemPage } from "./SingleItemPage";
import { ProfileFolderList } from "./ProfileFolderPage";
import { CategoryList } from "./CategoryPage";
import { ConformanceUnitList } from "./ConformanceUnitPage";

const PageCacheName = Utils.PageNames.Profiles;
const PageApiPath = Utils.PagePaths.Profiles;
const PageName = PageCacheName;

export const FieldNames = {
   Name: Utils.FieldNames.Name,
   Description: Utils.FieldNames.Description,
   Sort: Utils.FieldNames.Sort,
   ReleaseStatus: Utils.FieldNames.ReleaseStatus,
   Version: Utils.FieldNames.Version,
   LastUpdateTime: Utils.FieldNames.LastUpdateTime,
   LastUpdatingUserId: Utils.FieldNames.LastUpdatingUserId,
   ProfileGroupId: Utils.FieldNames.ProfileGroupId,
   WorkingGroupId: Utils.FieldNames.WorkingGroupId,
   InlineDescription: Utils.FieldNames.InlineDescription,
   ProfileUri: "profileUri",
   ShowIndirectlyIncluded: "showIndirectlyIncluded",
   Search: Utils.FieldNames.Search,
   SearchDescription: Utils.FieldNames.SearchDescription,
   Category: "category"
};

export const ChildListNames = {
   Profiles: "profiles",
   ConformanceUnits: "conformanceUnits",
   Folders: "folders",
   Categories: "categories",
   IncludingProfiles: "includingProfiles"
};

const FieldDefinitions = [
   {
      name: FieldNames.Name,
      defaultValue: "",
      viewControlType: FormRow.FieldType.Label,
      editControlType: FormRow.FieldType.TextInput,
      maxTextLength: 80
   },
   {
      name: FieldNames.ProfileUri,
      defaultValue: "",
      viewControlType: FormRow.FieldType.Label,
      editControlType: FormRow.FieldType.TextInput,
      maxTextLength: 80,
      placeholder: "http://opcfoundation.org/UA-Profile/<WGShortName>/<Category>/<ProfileShortName>"
   },
   {
      name: FieldNames.ReleaseStatus,
      defaultValue: Utils.ReleaseStatus.Draft,
      viewControlType: FormRow.FieldType.Label,
      editControlType: FormRow.FieldType.OptionSet
   },
   {
      name: FieldNames.Sort,
      defaultValue: 0,
      viewControlType: FormRow.FieldType.Label,
      editControlType: FormRow.FieldType.NumberInput,
      minValue: 0,
      maxValue: 10000,
      editorOnly: true
   },
   {
      name: FieldNames.Version,
      defaultValue: 1,
      viewControlType: FormRow.FieldType.Label,
      editorOnly: true
   },
   {
      name: FieldNames.LastUpdateTime,
      defaultValue: "",
      viewControlType: FormRow.FieldType.Label,
      editorOnly: true
   },
   {
      name: FieldNames.LastUpdatingUserId,
      defaultValue: "",
      viewControlType: FormRow.FieldType.Label,
      editorOnly: true
   },
   {
      name: FieldNames.ProfileGroupId,
      defaultValue: Utils.DefaultProfileGroup,
      viewControlType: FormRow.FieldType.Label
   },
   {
      name: FieldNames.Description,
      defaultValue: "",
      viewControlType: FormRow.FieldType.BlockLabel,
      editControlType: FormRow.FieldType.TextArea
   }
];

const FilterDefinitions = [
   {
      name: FieldNames.Search,
      defaultValue: "",
      editControlType: FormRow.FieldType.TextInput,
      maxTextLength: 40
   },
   {
      name: FieldNames.SearchDescription,
      defaultValue: false,
      editControlType: FormRow.FieldType.CheckBox
   },
   {
      name: FieldNames.ProfileGroupId,
      defaultValue: null,
      viewControlType: FormRow.FieldType.Label,
      editControlType: FormRow.FieldType.OptionSet
   },
   {
      name: FieldNames.ShowIndirectlyIncluded,
      defaultValue: false,
      editControlType: FormRow.FieldType.CheckBox
   }
];

const ChildDefinitions = [
   {
      name: ChildListNames.ConformanceUnits,
      url: `${PageApiPath}includedconformanceunits/`,
      expanded: true,
      renderList: (props) => <ConformanceUnitList {...props} useMultiSelect={true} noCreate={true} />
   },
   {
      name: ChildListNames.Profiles,
      url: `${PageApiPath}includedprofiles/`,
      expanded: false,
      renderList: (props) => <ProfileList {...props} useMultiSelect={true} noCreate={true} />
   },
   {
      name: ChildListNames.Folders,
      url: `${PageApiPath}folders/`,
      expanded: false,
      noFilters: true,
      shouldRenderList: (props, parent) => (props.user?.isEditor),
      renderList: (props) => <ProfileFolderList {...props} useMultiSelect={true} noCreate={true} />
   },
   {
      name: ChildListNames.Categories,
      url: `${PageApiPath}categories/`,
      expanded: false,
      noFilters: true,
      shouldRenderList: (props, parent) => (props.user?.isEditor),
      renderList: (props) => <CategoryList {...props} useMultiSelect={true} filterByUserContext={true} noCreate={true} />
   },
   {
      name: ChildListNames.IncludingProfiles,
      url: `${PageApiPath}includingprofiles/`,
      expanded: false,
      renderList: (props) => <ProfileList {...props} noCreate={true} />
   }
];

function renderFieldValue(context, name, item, field) {
   const value = FormRow.renderFieldValue(context, name, item, field, Utils.PagePaths.Profiles);
   if (value) {
      return value;
   }
   switch (name) {
      case FieldNames.ProfileUri: {
         return <FormRow.SimpleLink url={item[name]} />;
      }
      default: {
         break;
      }
   }
   return item[name];
}

function toJson(item) {
   return {
      id: item.id,
      guid: item.guid,
      name: item.name,
      description: item.description,
      releaseStatus: item.releaseStatus,
      sort: item.sort,
      profileGroupId: item.profileGroupId,
      workingGroupId: item.workingGroupId,
      profileUri: item.profileUri,
      lastUpdateTime: Utils.getDate(item.lastUpdateTime)
   }
}

function initializeNewItem(props, newItem) {
   const {
      item,
      user
   } = props;
   if (user?.profileGroupId) newItem.profileGroupId = user.profileGroupId;
   if (user?.workingGroupId) newItem.workingGroupId = user.workingGroupId;
   if (item?.name) newItem.name = `${item.name} (Copy)`;
   if (item?.sort) newItem.sort = item.sort + 1;
   if (item?.profileGroupId) newItem.profileGroupId = item.profileGroupId;
   if (item?.workingGroupId) newItem.workingGroupId = item.workingGroupId;
}

function getEditorStates(props, item) {
   const states = {
      [FieldNames.Name]: FormRow.FieldState.Normal,
      [FieldNames.Description]: FormRow.FieldState.Normal,
      [FieldNames.ProfileUri]: FormRow.FieldState.Normal,
      [FieldNames.Sort]: FormRow.FieldState.Normal,
      [FieldNames.Version]: FormRow.FieldState.Normal,
      [FieldNames.LastUpdateTime]: FormRow.FieldState.Hidden,
      [FieldNames.LastUpdatingUserId]: FormRow.FieldState.Hidden
   };
   if (item.releaseStatus >= Utils.ReleaseStatus.Released) {
      return {
         ...states,
         [FieldNames.Name]: FormRow.FieldState.Disabled,
         [FieldNames.Description]: FormRow.FieldState.Disabled,
         [FieldNames.ProfileUri]: FormRow.FieldState.Disabled
      };
   }
   return states;
};

function updateInitialFilter(props, filters) {
   filters[FieldNames.Search] = "";
   filters.exclude = [
      FieldNames.SearchDescription,
      FieldNames.ShowIndirectlyIncluded
   ];
   filters.unsetDataFetched = [
      FieldNames.ShowIndirectlyIncluded,
      FieldNames.ProfileGroupId
   ];
};

function getFilterEditorOptions(props, filter) {
   const {
      user,
      profileGroups,
      inListEditMode
   } = props;
   let pgs = [];
   if (inListEditMode) {
      pgs = profileGroups.map(ii => {
         return { id: ii.id, name: ii.fullName, sort: ii.workingGroupId }
      }).sort(Utils.sortBySortThenName);

      if (Utils.isNullId(filter.profileGroupId)) {
         filter.profileGroupId = user.profileGroupId;
      }
   }
   else {
      filter.profileGroupId = null;
   }
   return {
      [FieldNames.ProfileGroupId]: pgs
   };
};

function getFilterEditorStates(props, filters) {
   const {
      containerName,
      inListEditMode
   } = props;
   const states = {
      [FieldNames.ProfileGroupId]: FormRow.FieldState.Hidden,
      [FieldNames.ShowIndirectlyIncluded]: FormRow.FieldState.Hidden
   }
   if (inListEditMode) {
      states[FieldNames.ProfileGroupId] = FormRow.FieldState.Normal;
   }
   if (containerName === Utils.PageNames.Profiles && !inListEditMode) {
      states[FieldNames.ShowIndirectlyIncluded] = FormRow.FieldState.Normal;
   }
   return states;
};

function updateBeforeApplyFilters(filters, items) {
   items.map(ii => {
      ii.search = (!filters.searchDescription) ? ii.name : ii.scope;
      return ii;
   });
};

export const ProfileList = (props) => {
   const {
      user,
      parent,
      url,
      containerName,
      storageKey,
      groupByName
   } = props;

   const [profileGroups, setProfileGroups] = useState([]);
   const isComponent = (parent && containerName === Utils.PageNames.Profiles) ? true : false;
   const parentPgId = (parent) ? parent.profileGroupId : user?.profileGroupId;
   const userProfileGroupId = user?.profileGroupId;

   useEffect(() => {
      if (userProfileGroupId) {
         fetch();
      }
      async function fetch() {
         setProfileGroups(await Utils.fetchProfileGroupDependencies(userProfileGroupId));
      }
   }, [userProfileGroupId]);

   const onSelectModeChanged = useCallback((context) => {
      const { filters, selectModeEnabled } = context;
      if (selectModeEnabled) {
         return { ...filters, profileGroupId: parentPgId };
      }
      return { ...filters, profileGroupId: -1 };
   }, [parentPgId]);

   const columns = [{ name: FieldNames.InlineDescription, width: "8" }];
   const sortColumn = { name: FieldNames.Sort };

   const updateUrlToUse = useCallback((context, urlToUse) => {
      const { filters, inListEditMode } = context;
      if (!inListEditMode) {
         urlToUse += (filters[FieldNames.ShowIndirectlyIncluded]) ? "?all=1" : "";
         return urlToUse;
      }
      const pg = filters[FieldNames.ProfileGroupId];
      if (!Utils.isNullId(pg)) {
         const rs = user.releaseStatus;
         urlToUse += `?pg=${pg ?? Utils.DefaultProfileGroup}&rs=${rs ?? Utils.ReleaseStatus.Released}`;
      }
      return urlToUse;
   }, [user]);

   // select group
   let groupBy = null;

   const groupByProfileGroup = useCallback((item) => {
      return Utils.groupByProfileGroup(profileGroups, item);
   }, [profileGroups]);

   if (groupByName === Utils.PageNames.ProfileGroups) {
      groupBy = groupByProfileGroup;
   }

   return (
      <MultiSelectList
         {...props}
         pageName={PageName}
         filterByUserContext={true}
         containerName={containerName}
         storageKey={storageKey}
         parent={parent}
         url={url}
         pathApiUrl={PageApiPath}
         updateUrlToUse={updateUrlToUse}
         fieldDefinitions={FieldDefinitions}
         toJson={toJson}
         renderFieldValue={renderFieldValue}
         initializeNewItem={initializeNewItem}
         deleteItemConfirmText={(isComponent) ? "confirmDeleteProfile" : undefined}
         filterDefinitions={FilterDefinitions}
         updateInitialFilter={updateInitialFilter}
         getFilterEditorOptions={getFilterEditorOptions}
         getFilterEditorStates={getFilterEditorStates}
         updateBeforeApplyFilters={updateBeforeApplyFilters}
         columns={columns}
         sortColumn={sortColumn}
         groupBy={groupBy}
         profileGroups={profileGroups}
         onSelectModeChanged={onSelectModeChanged}
      />);
}

export const ProfilePage = (props) => {

   const {
      user,
      parent,
      url,
      containerName,
      storageKey
   } = props;

   const [profileGroups, setProfileGroups] = useState([]);
   const userProfileGroupId = user?.profileGroupId;

   useEffect(() => {
      if (userProfileGroupId) {
         fetch();
      }
      async function fetch() {
         setProfileGroups(await Utils.fetchProfileGroupDependencies(userProfileGroupId));
      }
   }, [userProfileGroupId]);

   const getEditorOptions = useCallback((context, item) => {
      const releaseStatuses = Object.keys(Utils.ReleaseStatus).map((ii) => {
         return {
            id: Utils.ReleaseStatus[ii],
            name: Locales.getDisplayText(`releaseStatus_${ii}`)
         };
      });
      return {
         [FieldNames.ReleaseStatus]: releaseStatuses
      };
   }, []);

   return (
      <SingleItemPage
         {...props}
         pageName={PageName}
         pathApiUrl={PageApiPath}
         containerName={containerName}
         storageKey={storageKey}
         parent={parent}
         url={url}
         fieldDefinitions={FieldDefinitions}
         toJson={toJson}
         childDefinitions={ChildDefinitions}
         renderFieldValue={renderFieldValue}
         getEditorOptions={getEditorOptions}
         getEditorStates={getEditorStates}
         initializeNewItem={initializeNewItem}
         profileGroups={profileGroups}
      />);
}

function getLoadingMessage() {
   return <span className='p-1 m-0 ms-1 text-info'>{Locales.getDisplayText("loadingRecords")}</span>;
}

export const ProfileUri = (props) => {
   const [message, setMessage] = useState(getLoadingMessage());
   const [profile, setProfile] = useState(null);
   const pathname = props.location?.pathname;
   const history = useHistory();

   // fetch
   useEffect(() => {
      async function fetch(url) {
         const response = await Utils.httpGet(`api${url}uri2id?uri=${pathname}`);
         if (response?.errorCode) {
            setMessage(<FormRow.ErrorRow item={response} failed={response.failed} className="mb-2 mt-2" />);
         }
         else {
            setProfile(response.result);
         }
      }
      if (pathname) {
         fetch(PageApiPath);
      }
   }, [pathname]);

   if (profile) {
      Utils.navigateForward(history, `${PageApiPath}${profile.id}`, `${PageApiPath}`);
   }

   return message;
}
