import React, { useCallback, useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Collapse, NavItem, NavLink } from 'reactstrap';
import * as Locales from "./locales";
import './SideBar.css';
import * as Utils from "./Utils.js";
import * as Regular from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

/*
 import { escapeSelector } from 'jquery';
 import { push as Menu } from 'react-burger-menu';
 import BurgerMenu from 'react-burger-menu'; 
 */

const PathTypeUrls = {
   [Utils.PathTypes.Catagory]: Utils.PagePaths.Categories,
   [Utils.PathTypes.ProfileFolder]: Utils.PagePaths.ProfileFolders,
   [Utils.PathTypes.Profile]: Utils.PagePaths.Profiles,
   [Utils.PathTypes.ConformanceGroup]: Utils.PagePaths.ConformanceGroups,
   [Utils.PathTypes.ConformanceUnit]: Utils.PagePaths.ConformanceUnits,
   [Utils.PathTypes.WorkingGroup]: Utils.PagePaths.WorkingGroups,
   [Utils.PathTypes.Document]: Utils.PagePaths.Documents,
   [Utils.PathTypes.Tag]: Utils.PagePaths.Tags,
   [Utils.PathTypes.TestSuite]: Utils.PagePaths.TestSuites
};

/*
<Utils.StyledTooltip title={Locales.getHelpText("sidebar_refresh")}>
   <span><FontAwesomeIcon
      icon={Solid.faSyncAlt}
      style={{ witdh: "0.9em", height: "0.9em", marginRight: '4px', color: 'green' }}
      onClick={props.onRefresh}
   />
   </span>
</Utils.StyledTooltip>
*/

function SideBarMenu(props) {
   const [hidden, setHidden] = useState(props.hidden);

   useEffect(() => {
      setHidden(props.hidden);
   }, [props.hidden]);

   Utils.getCurrentUser()
   let style = { minHeight: '100vh' };

   if (hidden) {
      style = { display: 'none' };
   }

   let subtitle = null;

   if (props.subtitle?.length) {
      subtitle =
         <span
            style={{ lineHeight: 1.0, paddingLeft: '1em' }}
         >
            {Locales.getDisplayText(props.subtitle)}
         </span>;
   }

   return (
      <div className="p-0 m-0 d-flex flex-column">
         <div className="opc-page-sidebar-titlebox p-0 m-0 flex-grow-1">
            <span
               className="fw-bold"
               style={{ lineHeight: 1.0, paddingLeft: '1em' }}
            >
               {Locales.getDisplayText(props.title)}
            </span>
            <div className="p-0 m-0 d-flex justify-content-between">
               {subtitle}
            </div>
         </div>
         <nav style={style} className="opc-page-sidebar-content pt-1 flex-grow-1">
            {props.children}
         </nav>
      </div>
   );
}

function SideBarBranch(props) {
   const [parent, setParent] = useState(props.parent);
   const [items, setItems] = useState([]);
   const [expanded, setExpanded] = useState(false);
   const [selected, setSelected] = useState(0);
   const [dataFetched, setDataFetched] = useState(false);
   const [noChildren, setNoChildren] = useState(false);
   const [activePaths, setActivePaths] = useState(false);
   const mounted = useRef(true)
   const { definition, refreshCount } = props;
   const parentId = parent?.id;
   const parentKey = parent?.key;
   const parentName = parent?.name;
   const paths = props.paths;
   const level = props.level;
   const url = `${definition.url}${parent.id}${(definition.suppressPathCheck) ? `/?key=${parentKey}` : ""}`;
   const userId = props.user?.id;
   const profileGroupId = props.user?.profileGroupId;
   const releaseStatus = props.user?.releaseStatus;
   const urlParameters = definition?.urlParameters ?? props.urlParameters;

   useEffect(() => {
      return () => {
         mounted.current = false;
      }
   }, []);

   useEffect(() => {
      setParent(props.parent);
   }, [props.parent]);

   useEffect(() => {
      setSelected(props.selected);
   }, [props.selected]);

   useEffect(() => {
      if (!paths?.length) {
         setActivePaths(paths);
         return;
      }
      var updatedPaths = [];
      var leaf = false;
      var subleaf = false;
      paths.map((ii) => {
         if (level >= ii.elements.length) {
            subleaf = true;
            return null;
         }
         const path = ii.elements[level];
         if (path && path.id === parentId && definition.url === PathTypeUrls[path.type]) {
            updatedPaths.push(ii);
            if (!leaf) {
               leaf = ii.elements.length === level + 1;
            }
         }
         return path;
      });
      if (subleaf) {
         setActivePaths(paths);
         return;
      }
      if (updatedPaths.length) {
         if (leaf) {
            setSelected(parentKey);
         }
         else {
            setExpanded(true);
            setSelected(0);
         }
         setActivePaths(updatedPaths);
      }
      else {
         setExpanded(false);
         setSelected(0);
         setActivePaths(paths);
      }
   }, [paths, level, definition, parentId, parentKey, parentName]);

   useEffect(() => {
      let newParent = Utils.findInCache(definition.url, 0, parentId);
      if (newParent) {
         setParent((parent) => { return { ...parent, name: newParent.name, releaseStatus: newParent.releaseStatus }; });
      }
   }, [props.cacheVersion, definition.url, parentId, refreshCount]);

   const fetchChildren = useCallback(async (parent, id, newItems) => {
      const response = await Utils.httpGet(`api${parent.url}${id ?? ""}?${urlParameters()}`);
      if (response?.errorCode) {
         console.error(`[SideBarBranch:${parent.name}] ${response?.errorCode}: ${response?.errorCode}`);
      }
      else {
         let subset = response.result.sort((a, b) => a.sort - b.sort);
         if (parent.filter) {
            subset = subset.filter(parent.filter);
         }
         subset.map((jj) => {
            jj.key = Utils.nextKey();
            jj.childType = parent.name;
            jj.pathType = parent.pathType;
            newItems.push(jj);
            return jj;
         });
      }
   }, [urlParameters]);

   useEffect(() => {
      async function fetch(children, id) {
         let groups = [];
         await Promise.all(children.map(async (ii) => {
            let groupItems = [];
            groups.push(groupItems);
            await fetchChildren(ii, id, groupItems);
         }));
         if (!mounted.current) {
            return;
         }
         let newItems = [];
         groups.map(ii => ii.map(jj => newItems.push(jj)));
         setItems(newItems);
         setNoChildren(!newItems?.length);
         setDataFetched(true);
      }
      if (definition && definition.children && userId >= 0 && profileGroupId && releaseStatus) {
         fetch(definition.children, parentId);
      }
   }, [definition, parentId, fetchChildren, userId, profileGroupId, releaseStatus, refreshCount]);

   if (!parent || !definition || (definition.hideIfEmpty && dataFetched && !items?.length)) {
      return null;
   }

   let padding = level + 1;
   let submenu = [];

   if (expanded && items) {
      let keys = {};
      submenu =
         <Collapse isOpen={expanded}>
            <ul className="navbar-nav flex-grow-1">
               {items.map(ii => {
                  keys[ii.key] = ii;
                  return <SideBarBranch
                     key={ii.key}
                     user={props.user}
                     parent={ii}
                     definitions={props.definitions}
                     definition={props.definitions?.find((x) => x.nodeType === ii.childType)}
                     urlParameters={props.urlParameters}
                     parentUrl={url}
                     cacheVersion={props.cacheVersion}
                     refreshCount={refreshCount}
                     level={padding}
                     paths={activePaths}
                     selected={selected}
                  />;
               })}
            </ul>
         </Collapse>;
   }

   let icon = (!expanded) ? definition.normalIcon : definition.expandedIcon;
   if (!icon) {
      icon = (!expanded) ? Regular.faPlusSquare : Regular.faMinusSquare;
   }

   if (definition.noChildrenIcon && noChildren) {
      icon = definition.noChildrenIcon;
   }

   let iconColor = definition.iconColor;
   if (!iconColor) {
      iconColor = "black";
   }

   function toggleMenu(e) {
      e.preventDefault();
      setExpanded(expanded => !expanded);
   }

   let name = parent.name.replace(" [deprecated]", "");

   switch (parent.releaseStatus) {
      case Utils.ReleaseStatus.Draft: { name += ` ${Locales.getDisplayText("sidebar_draft")}`; break; }
      case Utils.ReleaseStatus.ReleaseCandidate: { name += ` ${Locales.getDisplayText("sidebar_releaseCandidate")}`; break; }
      case Utils.ReleaseStatus.Deprecated: { name += ` ${Locales.getDisplayText("sidebar_deprecated")}`;; break; }
      default: { break; }
   }

   return (
      <NavItem className="flex-grow-1">
         <NavLink
            key={parent.key}
            className={"mb-1 pt-0 pb-0 pe-3 text-wrap" + ((selected === parent.key) ? " selected" : "")}
            tag={Link}
            to={{
               pathname: url,
               state: { url: props.parentUrl }
            }}
            style={{ paddingLeft: padding + 'em' }}
         >
            <FontAwesomeIcon
               icon={icon}
               color={iconColor}
               onClick={(e) => toggleMenu(e)}
               style={{ marginRight: '4px' }}
            />
            <span>{name}</span>
         </NavLink>
         {submenu}
      </NavItem>
   );
}

export const SideBar = (props) => {
   const [title, setTitle] = useState(null);
   const [subtitle, setSubtitle] = useState(null);
   const [definitions, setDefinitions] = useState(null);
   const [root, setRoot] = useState(null);
   const [items, setItems] = useState([]);
   const [paths, setPaths] = useState([]);
   const [currentRecord, setCurrentRecord] = useState(null);
   const [selected, setSelected] = useState(0);
   const [refreshCount, setRefreshCount] = useState(0);
   const [message, setMessage] = useState(null);
   const mounted = useRef(true);
   const userId = props.user?.id;
   const profileGroupId = props.user?.profileGroupId;
   const releaseStatus = props.user?.releaseStatus;
   const currentRecordId = currentRecord?.id
   const currentRecordUrl = currentRecord?.url;
   const currentRecordKey = currentRecord?.key;

   useEffect(() => {
      return () => {
         mounted.current = false;
      }
   }, []);

   const onRefresh = (e) => {
      e.preventDefault();
      setRefreshCount(count => count + 1);
   }

   useEffect(() => {
      const { title, subtitle, definitions } = (props.definitions) ? props.definitions[props.active] : {};
      setRoot(null);
      setItems([]);
      setTitle(title);
      setSubtitle(subtitle);
      setDefinitions(definitions);
      setRefreshCount(refreshCount => refreshCount + 1);
   }, [props.definitions, props.active]);

   useEffect(() => {
      setCurrentRecord(Utils.getCurrentRecord());
   }, [props.cacheVersion, profileGroupId]);

   useEffect(() => {
      setRoot(props.root);
   }, [props.root]);

   useEffect(() => {
      async function fetch(url, id) {
         const urlToUse = `api${url}paths/${id}?${root.urlParameters()}`;
         const response = await Utils.httpGet(urlToUse);
         if (!mounted.current) {
            return;
         }
         if (response?.errorCode) {
            console.error(`[SideBar] ${response?.errorCode}: ${response?.errorCode}`);
            setMessage(Locales.getDisplayText("sidebar_recordNotFound"));
            setPaths([]);
         }
         else {
            setPaths(oldItems => {
               const newItems = Utils.processGetResponseAndUpdateList(urlToUse, response, oldItems);
               console.info(`[SideBar] Paths Found: ${newItems?.length}`);
               setMessage((!newItems?.length) ? Locales.getDisplayText("sidebar_recordNotFound") : null);
               return newItems;
            });
         }
      }
      if (root && currentRecordId && currentRecordUrl) {
         if (currentRecordKey) {
            setSelected(currentRecordKey);
         }
         else {
            fetch(currentRecordUrl, currentRecordId);
         }
      }
   }, [currentRecordId, currentRecordUrl, currentRecordKey, profileGroupId, releaseStatus, root]);

   useEffect(() => {
      if (definitions) {
         const root = definitions.find((ii) => ii.nodeType === "root");
         setItems([]);
         setRoot(root);
      }
   }, [definitions]);

   useEffect(() => {
      async function fetch(url) {
         const urlToUse = `api${url}?${root.urlParameters()}`;
         const response = await Utils.httpGet(urlToUse);
         if (!mounted.current) {
            return;
         }
         if (response?.errorCode) {
            console.error(`[SideBar] ${response?.errorCode}: ${response?.errorCode}`);
         }
         else {
            setItems(oldItems => {
               const newItems = Utils.processGetResponseAndUpdateList(urlToUse, response, oldItems);
               return newItems.sort((a, b) => a.sort - b.sort)
            });
         }
      }
      if (root && root.url && userId >= 0 && profileGroupId && releaseStatus) {
         fetch(root.url);
      }
   }, [root, refreshCount, userId, profileGroupId, releaseStatus]);

   if (root == null) {
      return (
         <div className="p-0 m-0 d-flex flex-column">
            <div className="opc-page-sidebar-titlebox p-0 m-0 flex-grow-1">
               <span
                  className="fw-bold"
                  style={{ lineHeight: 1.0, paddingLeft: '1em' }}
               >
                  {Locales.getDisplayText(props.title)}
               </span>
               <div className="p-0 m-0 d-flex justify-content-between">
                  {subtitle}
               </div>
            </div>
         </div>);
   }

   return (
      <SideBarMenu title={title} subtitle={subtitle} onRefresh={onRefresh} message={message}>
         <ul className="navbar-nav" style={{ cursor: 'default' }}>
               {items.map(ii => {
                  return <SideBarBranch
                     key={ii.key}
                     user={props.user}
                     parent={ii}
                     definitions={definitions}
                     definition={root}
                     urlParameters={root.urlParameters}
                     parentUrl="/"
                     cacheVersion={props.cacheVersion}
                     refreshCount={refreshCount}
                     level={0}
                     paths={paths}
                     selected={selected}
                  />;
               }
               )}
               <li>&nbsp;</li>
         </ul>
        </SideBarMenu>
    );
}
