import React, { useState, useCallback } from "react";
import { useLocation } from 'react-router-dom';

import * as FormRow from "./FormRow.js";
import * as Utils from "./Utils.js";
import * as Locales from "./locales";
import { SingleItemPage } from "./SingleItemPage";
import { MultiSelectList } from "./MultiSelectList";
import { useEffect } from "react";
import { WorkingGroupList } from "./WorkingGroupPage.js";

const PageCacheName = Utils.PageNames.Users;
const PageApiPath = Utils.PagePaths.Users;
const PageName = PageCacheName;

export const FieldNames = {
   Name: Utils.FieldNames.Name,
   Email: "email",
   CompanyName: "companyName",
   ProfileGroupId: Utils.FieldNames.ProfileGroupId,
   WorkingGroupId: Utils.FieldNames.WorkingGroupId,
   ReleaseStatus: Utils.FieldNames.ReleaseStatus,
   IsAdministrator: "isAdministrator",
   AccessGroups: "accessGroups",
   Instructions: "instructions",
   Search: Utils.FieldNames.Search
};

const ChildListNames = {
   WorkingGroups: "workinggroups"
};

const FieldDefinitions = [
   {
      name: FieldNames.Name,
      defaultValue: "",
      viewControlType: FormRow.FieldType.Label,
      editControlType: FormRow.FieldType.Label,
      maxTextLength: 80
   },
   {
      name: FieldNames.Email,
      defaultValue: "",
      viewControlType: FormRow.FieldType.Label,
      editControlType: FormRow.FieldType.Label,
      maxTextLength: 80
   },
   {
      name: FieldNames.CompanyName,
      defaultValue: "",
      viewControlType: FormRow.FieldType.Label,
      editControlType: FormRow.FieldType.Label,
      maxTextLength: 80
   },
   {
      name: FieldNames.AccessGroups,
      defaultValue: "",
      viewControlType: FormRow.FieldType.Label,
      editControlType: FormRow.FieldType.TextInput,
      maxTextLength: 80
   },
   {
      name: FieldNames.IsAdministrator,
      defaultValue: false,
      viewControlType: FormRow.FieldType.Label,
      editControlType: FormRow.FieldType.CheckBox
   },
   {
      name: FieldNames.WorkingGroupId,
      defaultValue: -1,
      viewControlType: FormRow.FieldType.Label,
      editControlType: FormRow.FieldType.OptionSet
   },
   {
      name: FieldNames.ProfileGroupId,
      defaultValue: -1,
      viewControlType: FormRow.FieldType.Label,
      editControlType: FormRow.FieldType.OptionSet
   },
   {
      name: FieldNames.ReleaseStatus,
      defaultValue: Utils.ReleaseStatus.Draft,
      viewControlType: FormRow.FieldType.Label,
      editControlType: FormRow.FieldType.OptionSet
   },
   {
      name: FieldNames.Instructions,
      defaultValue: Utils.ReleaseStatus.Draft,
      viewControlType: FormRow.FieldType.BlockLabel,
      editControlType: FormRow.FieldType.BlockLabel
   }
];

const ChildDefinitions = [
   {
      name: ChildListNames.WorkingGroups,
      url: `${PageApiPath}workinggroups/`,
      expanded: true,
      noFilters: true,
      shouldRenderList: (props, parent) => {
         return (props.editingUser);
      },
      renderList: (props) => <WorkingGroupList {...props} useMultiSelect={false} noCreate={true} noDelete={true} />
   }
];

const FilterDefinitions = [
   {
      name: FieldNames.Search,
      defaultValue: "",
      editControlType: FormRow.FieldType.TextInput,
      maxTextLength: 40
   }
];

function renderFieldValue(context, name, item, field) {
   const value = FormRow.renderFieldValue({ ...context, noExternalLink: true }, name, item, field, Utils.PagePaths.Users);
   if (value) {
      return value;
   }
   switch (name) {
      case FieldNames.ProfileGroupId: {
         let pg = Utils.findInCache(Utils.PagePaths.ProfileGroups, 0, item.profileGroupId);
         return (pg) ? pg.fullName : item.profileGroupId;
      }
      case FieldNames.WorkingGroupId: {
         let wg = Utils.findInCache(Utils.PagePaths.WorkingGroups, 0, item.workingGroupId);
         return (wg) ? wg.name : item.workingGroupId;
      }
      case FieldNames.ReleaseStatus: {
         const name = Object.keys(Utils.ReleaseStatus).find((ii) => Utils.ReleaseStatus[ii] === item.releaseStatus);
         return Locales.getDisplayText(`releaseStatus_${name}`);
      }
      case FieldNames.IsAdministrator: {
         return <FormRow.CheckBoxField value={item.isAdministrator} />;
      }
      case FieldNames.Instructions: {
         return Locales.getHelpText("chooseProfileGroup");
      }
      default: {
         return item[name];
      }
   }

}

function toJson(item) {
   return {
      id: item.id,
      name: item.name,
      email: item.email,
      companyName: item.companyName,
      membershipType: item.membershipType,
      accessGroups: item.accessGroups,
      isAdministrator: item.isAdministrator,
      profileGroupId: item.profileGroupId,
      workingGroupId: item.workingGroupId,
      releaseStatus: item.releaseStatus
   }
}

async function fetchWorkingGroups() {
   const url = `api${Utils.PagePaths.WorkingGroups}`;
   const response = await Utils.httpGet(url);
   if (response?.errorCode) {
      return [];
   }
   let items = Utils.processGetResponseAndUpdateList(url, response);
   Utils.updateCache(Utils.PagePaths.WorkingGroups, items, true);
   return items;
}

const beforeFetchItem = async (props) => {
   const {
      id
   } = props;
   if (id === 1) {
      const item = Utils.getCurrentUser();
      item.editing = true;
      item.hasWriteAccess = true;
      return item;
   }
   return null;
};

const getFieldStates = (user, editingProfileGroup) => {
   const states = {
      [FieldNames.Name]: FormRow.FieldState.Normal,
      [FieldNames.Email]: FormRow.FieldState.Normal,
      [FieldNames.CompanyName]: FormRow.FieldState.Normal,
      [FieldNames.AccessGroups]: FormRow.FieldState.Normal,
      [FieldNames.IsAdministrator]: FormRow.FieldState.Normal,
      [FieldNames.WorkingGroupId]: FormRow.FieldState.Hidden,
      [FieldNames.ProfileGroupId]: FormRow.FieldState.Hidden,
      [FieldNames.ReleaseStatus]: FormRow.FieldState.Hidden,
      [FieldNames.Instructions]: FormRow.FieldState.Hidden,
   };
   if (!user?.isAdministrator) {
      states[FieldNames.AccessGroups] = FormRow.FieldState.Hidden;
      states[FieldNames.IsAdministrator] = FormRow.FieldState.Hidden;
   }
   if (editingProfileGroup) {
      states[FieldNames.Name] = FormRow.FieldState.Hidden;
      states[FieldNames.Email] = FormRow.FieldState.Hidden;
      states[FieldNames.CompanyName] = FormRow.FieldState.Hidden;
      states[FieldNames.AccessGroups] = FormRow.FieldState.Hidden;
      states[FieldNames.IsAdministrator] = FormRow.FieldState.Hidden;
      states[FieldNames.WorkingGroupId] = FormRow.FieldState.Normal;
      states[FieldNames.ProfileGroupId] = FormRow.FieldState.Normal;
      states[FieldNames.ReleaseStatus] = FormRow.FieldState.Normal;
      states[FieldNames.Instructions] = FormRow.FieldState.Normal;
   }
   return states;
};

export const UserPage = (props) => {

   const {
      user,
      parent,
      url,
      containerName,
      storageKey
   } = props;

   const [workingGroups, setWorkingGroups] = useState([]);
   const [profileGroups, setProfileGroups] = useState([]);

   const query = new URLSearchParams(useLocation().search);
   let editingProfileGroup = query.get("edit");

   useEffect(() => {
      const workingGroups = Utils.findInCache(Utils.PagePaths.WorkingGroups, 60);
      (workingGroups?.length) ? setWorkingGroups(workingGroups) : fetch();
      async function fetch() {
         setWorkingGroups(await fetchWorkingGroups());
      };
   }, []);

   useEffect(() => {
      const profileGroups = Utils.findInCache(Utils.PagePaths.ProfileGroups, 60);
      (profileGroups?.length) ? setProfileGroups(profileGroups) : fetch();
      async function fetch() {
         setProfileGroups(await Utils.fetchRecords(Utils.PagePaths.ProfileGroups));
      };
   }, []);

   const getEditorStates = useCallback((context, item) => {
      return getFieldStates(user, editingProfileGroup);
   }, [user, editingProfileGroup]);

   const getEditorOptions = useCallback((context, item) => {
      const wgs = workingGroups.map((ii) => {
         return {
            id: ii.id,
            name: ii.name,
            sort: ii.sort
         };
      }).sort(Utils.sortBySortThenName);
      const pgs = profileGroups.filter(x => x.workingGroupId === item.workingGroupId).map((ii) => {
         return {
            id: ii.id,
            name: ii.name,
            sort: ii.sort
         };
      }).sort(Utils.sortBySortThenName);
      const rss = Object.keys(Utils.ReleaseStatus)
         .filter(x => Utils.ReleaseStatus[x] <= Utils.ReleaseStatus.Released)
         .map((ii) => {
            return {
               id: Utils.ReleaseStatus[ii],
               name: Locales.getDisplayText(`releaseStatus_${ii}`)
            };
      });
      return {
         [FieldNames.WorkingGroupId]: wgs,
         [FieldNames.ProfileGroupId]: pgs,
         [FieldNames.ReleaseStatus]: rss,
      }
   }, [workingGroups, profileGroups]);

   const beforeUpdateItem = useCallback(async (props) => {
      const {
         item
      } = props;
      if (item.id === 1 && editingProfileGroup) {
         const pgs = profileGroups
            .filter(x => x.workingGroupId === item.workingGroupId)
            .sort(Utils.sortBySortThenName)
            .reverse();
         if (pgs.length && !pgs.find(x => x.id === item.profileGroupId)) {
            item.profileGroupId = pgs[0].id;
         }
         Utils.setCurrentUser(item, true);
         return item;
      }
      return null;
   }, [profileGroups, editingProfileGroup])

   const afterUpdateItem = (item) => {
      if (editingProfileGroup) {
         Utils.setCurrentUser(item, true);
      }
   };

   return (
      <SingleItemPage
         {...props}
         editingUser={!editingProfileGroup}
         pageName={PageName}
         pathApiUrl={PageApiPath}
         containerName={containerName}
         storageKey={storageKey}
         parent={parent}
         url={url}
         fieldDefinitions={FieldDefinitions}
         childDefinitions={ChildDefinitions}
         toJson={toJson}
         defaultItem={user}
         renderFieldValue={renderFieldValue}
         beforeFetchItem={beforeFetchItem}
         beforeUpdateItem={beforeUpdateItem}
         afterUpdateItem={afterUpdateItem}
         getViewerStates={getEditorStates}
         getEditorStates={getEditorStates}
         getEditorOptions={getEditorOptions}
         noButtons={editingProfileGroup}
         noToggleEditing={editingProfileGroup}
         alwaysEdit={editingProfileGroup}
         anyoneCanEdit={editingProfileGroup}
         noCreate={true}
         noDelete={true}
         noSetCurrentRecord={true}
         workingGroups={workingGroups}
         profileGroups={profileGroups}
      />);
}

function updateInitialFilter(props, filters) {
   filters[FieldNames.Search] = "";
   filters.exclude = [
      Utils.FieldNames.ProfileGroupId,
      FieldNames.SearchDescription
   ];
};

export const UserList = (props) => {
   const {
      user,
      parent,
      url,
      containerName,
      storageKey
   } = props;

   const columns = [{ name: FieldNames.Email, width: 4 }, { name: FieldNames.CompanyName, width: 4 }];
   const sortColumn = { name: FieldNames.Name };

   const getViewerStates = useCallback((context, item) => {
      return getFieldStates(user, false);
   }, [user]);

   return (
      <MultiSelectList
         {...props}
         pageName={PageName}
         containerName={containerName}
         storageKey={storageKey}
         parent={parent}
         url={url}
         pathApiUrl={PageApiPath}
         fieldDefinitions={FieldDefinitions} 
         filterDefinitions={FilterDefinitions} 
         updateInitialFilter={updateInitialFilter}
         toJson={toJson}
         getViewerStates={getViewerStates}
         renderFieldValue={renderFieldValue}
         columns={columns}
         sortColumn={sortColumn}
         noCreate={true}
         noDelete={true}
      />);
}
