import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Col } from 'reactstrap';
import { StyledTooltip } from "./Utils.js";

import * as Solid from '@fortawesome/free-solid-svg-icons'
import * as Regular from '@fortawesome/free-regular-svg-icons'
import * as Locales from "./locales";

export const Icons = {
   Sort: "sort",
   MoveDown: "moveDown",
   MoveUp: "moveUp",
   ViewDetail: "viewDetail",
   EditOrder: "editOrder",
   Toggle: "toggle",
   Edit: "edit",
   Create: "create",
   Delete: "delete",
   Select: "select",
   SelectItem: "selectItem",
   Unlink: "unlink",
   Warning: "warning",
   Filters: "filters"
};

function getIcon(icon) {
   if (icon.icon) {
      return icon.icon;
   }

   switch (icon.name) {
      case Icons.Sort: {
         return (!icon.state) ? Solid.faSort : ((icon.state === 1) ? Regular.faCaretSquareUp : Regular.faCaretSquareDown);
      }
      case Icons.ViewDetail: {
         return Regular.faFileAlt;
      }
      case Icons.MoveUp: {
         return Solid.faAngleDoubleUp;
      }
      case Icons.MoveDown: {
         return Solid.faAngleDoubleDown;
      }
      case Icons.EditOrder: {
         return (icon.state) ? Solid.faSave : Solid.faRandom;
      }
      case Icons.Toggle: {
         return (icon.state) ? Regular.faMinusSquare : Regular.faPlusSquare;
      }
      case Icons.Create: {
         return Solid.faPlus;
      }
      case Icons.Delete: {
         return Solid.faTrashAlt;
      }
      case Icons.Unlink: {
         return Solid.faUnlink;
      }
      case Icons.Edit: {
         return (icon.state) ? Solid.faCheckCircle : Regular.faEdit;
      }
      case Icons.Select: {
         return (icon.state) ? Solid.faCheckCircle : Solid.faTasks;
      }
      case Icons.SelectItem: {
         return (icon.state) ? Regular.faCheckSquare : Regular.faSquare;
      }
      case Icons.Warning: {
         return Solid.faExclamationTriangle;
      }
      case Icons.Filters: {
         return Solid.faFilter;
      }
      default: {
         return Solid.faCheckCircle;
      }
   }
}

export const RowIcons = (props) => {
   let { icons, minCount, dark, context } = props;

   if (icons === undefined) {
      icons = [];
   }

   if (minCount === undefined) {
      minCount = 0;
   }

   let style = {
      marginLeft: '1px',
      marginRight: '1px',
      minWidth: '20px'
   };

   let iconCount = Math.max(minCount, icons.length);
   let width = iconCount * 22;

   let count = 1;
   let list = [];

   icons.map((ii) => {
      let styleToUse = { ...style };
      styleToUse.color = (ii.color) ? ii.color : ((dark) ? 'white' : 'black');

      if (ii.placeholder) {
         return true;
      }

      const keyName = `${ii.name}Icon${((ii.state) ? "Active" : "")}`;
      const title = (ii.helpText) ? ii.helpText : Locales.getHelpText(keyName);
  
      if (title?.length && keyName !== title) {
         list.push(
            <StyledTooltip  key={count++} title={title}>
               <span>
                  <FontAwesomeIcon
                     icon={getIcon(ii)}
                     onClick={(e, item) => { return (ii.onClick) ? ii.onClick(e, context, ii.name) : undefined; }}
                     style={styleToUse}
                  />
               </span>
            </StyledTooltip>);
      }
      else {
         list.push(
            <FontAwesomeIcon
               icon={getIcon(ii)}
               onClick={(e, item) => { return (ii.onClick) ? ii.onClick(e, context, ii.name) : undefined; }}
               style={styleToUse}
            />);
      }

      return true;
   })

   const offset = (width - ((count - 1) * 22)) + "px";

   return (
      <Col xs="auto" className='p-0 m-0 d-flex justify-content-end' style={{ minWidth: width + "px", paddingLeft: offset }}>
         {list.map((ii) => { return ii })}
      </Col>
   );
}