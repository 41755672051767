import React, { useCallback, useState, useEffect } from "react";
import { Container, Row, Col } from 'reactstrap';
import { RowIcons } from "./RowIcons";
import Box from '@mui/material/Box';

import * as Locales from "./locales";
import * as Utils from "./Utils.js";

export const FilterForm = (props) => {
   const [newFilters, setNewFilters] = useState(props.filters);
   const { filters, onFiltersChanged } = props;

   useEffect(() => {
      if (newFilters) {
         onFiltersChanged(newFilters);
      }
   }, [newFilters, onFiltersChanged]);

   const toggleExpanded = useCallback((e) => {
      e.preventDefault();
      const newFilters = { ...filters, expanded: !filters.expanded };
      setNewFilters(newFilters);
   }, [filters]);

   const onTextChanged = useCallback((e) => {
      const { name, value } = e.target;
      const newFilters = { ...filters, [name]: value };
      setNewFilters(newFilters);
   }, [filters]);

   const onCheckChanged = useCallback((e) => {
      const { name, checked } = e.target;
      const newFilters = { ...filters, [name]: checked };
      setNewFilters(newFilters);
   }, [filters]);
      
   const onSelectChanged = useCallback((e, value, reason) => {
      let { id } = e.target;
      const index = id.indexOf('-');
      if (index > 0) {
         id = id.substring(0, index);
      }
      if (value) {
         const newFilters = { ...filters, [id]: value.id };
         setNewFilters(newFilters);
      }
   }, [filters]);

   const onRadioChanged = useCallback((e) => {
      const { name, value } = e.target;
      const newFilters = { ...filters, [name]: Number(value) };
      setNewFilters(newFilters);
   }, [filters]);

   const resetForm = useCallback((e) => {
      e.preventDefault();
      const newFilters = { ...filters, expanded: true };
      setNewFilters(newFilters);
   }, [filters]);

   const keyPressOverride = useCallback((e) => {
      if (e.charCode === 13) e.preventDefault();
   }, []);

   const callbacks = {
      onTextChanged: onTextChanged,
      onSelectChanged: onSelectChanged,
      onCheckChanged: onCheckChanged,
      onRadioChanged: onRadioChanged
   }

   let body = null;

   if (filters?.expanded) {
      body =
         <Box
            boxShadow={2}
            bgcolor="background.paper"
            className="m-0 p-2 mb-2 w-100"
         >
            <form onSubmit={props.onFiltersChanged} onReset={resetForm} onKeyPress={keyPressOverride}>
               {props.renderFilters({
                  ...props,
                  callbacks: callbacks,
                  filters: filters
               })}
            </form>
         </Box>;
   }

   return (
      <Container className="fluid border-0 m-0 p-0 mt-2 mb-2">
         <Box
            boxShadow={2}
            bgcolor="background.paper"
            className="m-0 p-0 mt-1 w-100"
         >
            <Row className="m-0 fw-bold opc-green" style={{ marginLeft: '-1px', marginRight: '-1px' }}>
               <RowIcons
                  icons={[{ name: "toggle", state: props.filters.expanded, onClick: toggleExpanded }]}
                  right={true}
               />
               <Col
                  xs="auto"
                  className='p-0 m-0 ms-1 me-auto'
                  style={{ cursor: 'default' }}
               >
                  <Utils.StyledTooltip title={Locales.getHelpText("displayOptions")}>
                     <span>{Locales.getDisplayText("displayOptions")}</span>
                  </Utils.StyledTooltip>
               </Col>
               <Col
                  xs="1"
                  className='p-0 m-0 pe-1 text-right'
                  style={{ cursor: 'default' }}
               >
                  <Utils.StyledTooltip title={Locales.getHelpText("filterCount")}>
                     <span>{`${props.filteredCount}${Locales.getDisplayText("filterCount")}${props.originalCount}`}</span>
                  </Utils.StyledTooltip>
               </Col>
            </Row>
         </Box>
         {body}
      </Container>);
}