import React, { useState, useEffect, useCallback, useMemo, useRef } from "react";
import { Link } from "react-router-dom";

import * as FormRow from "./FormRow.js";
import * as Utils from "./Utils.js";
import * as Locales from "./locales";
import { MultiSelectList } from "./MultiSelectList";
import { SingleItemPage } from "./SingleItemPage";
import { ProfileList } from "./ProfilePage";
import * as Icons from '@fortawesome/free-regular-svg-icons'

const PageCacheName = Utils.PageNames.ConformanceUnits;
const PageApiPath = Utils.PagePaths.ConformanceUnits;
const PageName = PageCacheName;

export const FieldNames = {
   Name: Utils.FieldNames.Name,
   Description: Utils.FieldNames.Description,
   Sort: Utils.FieldNames.Sort,
   ReleaseStatus: Utils.FieldNames.ReleaseStatus,
   Version: Utils.FieldNames.Version,
   LastUpdateTime: Utils.FieldNames.LastUpdateTime,
   LastUpdatingUserId: Utils.FieldNames.LastUpdatingUserId,
   ProfileGroupId: Utils.FieldNames.ProfileGroupId,
   WorkingGroupId: Utils.FieldNames.WorkingGroupId,
   Category: "categoryGuid",
   ConformanceGroup: "conformanceGroupGuid",
   IsOptional: "isOptional",
   ShowIndirectlyIncluded: "showIndirectlyIncluded",
   InlineDescription: Utils.FieldNames.InlineDescription,
   Search: Utils.FieldNames.Search,
   SearchDescription: Utils.FieldNames.SearchDescription,
   TestCases: "testCase"
};

export const ChildListNames = {
   Profiles: "profiles"
};

const FieldDefinitions = [
   {
      name: FieldNames.Name,
      defaultValue: "",
      viewControlType: FormRow.FieldType.Label,
      editControlType: FormRow.FieldType.TextInput,
      maxTextLength: 80
   },
   {
      name: FieldNames.ConformanceGroup,
      defaultValue: null,
      viewControlType: FormRow.FieldType.Label,
      editControlType: FormRow.FieldType.OptionSet
   },
   {
      name: FieldNames.Category,
      defaultValue: null,
      viewControlType: FormRow.FieldType.Label,
      editControlType: FormRow.FieldType.OptionSet
   },
   {
      name: FieldNames.TestCases,
      defaultValue: null,
      viewControlType: FormRow.FieldType.Label
   },
   {
      name: FieldNames.ReleaseStatus,
      defaultValue: Utils.ReleaseStatus.Draft,
      viewControlType: FormRow.FieldType.Label,
      editControlType: FormRow.FieldType.OptionSet
   },
   {
      name: FieldNames.Sort,
      defaultValue: 0,
      viewControlType: FormRow.FieldType.Label,
      editControlType: FormRow.FieldType.NumberInput,
      minValue: 0,
      maxValue: 10000,
      editorOnly: true
   },
   {
      name: FieldNames.Version,
      defaultValue: 1,
      viewControlType: FormRow.FieldType.Label,
      editorOnly: true
   },
   {
      name: FieldNames.LastUpdateTime,
      defaultValue: "",
      viewControlType: FormRow.FieldType.Label,
      editorOnly: true
   },
   {
      name: FieldNames.LastUpdatingUserId,
      defaultValue: "",
      viewControlType: FormRow.FieldType.Label,
      editorOnly: true
   },
   {
      name: FieldNames.ProfileGroupId,
      defaultValue: Utils.DefaultProfileGroup,
      viewControlType: FormRow.FieldType.Label
   },
   {
      name: FieldNames.Description,
      defaultValue: "",
      viewControlType: FormRow.FieldType.BlockLabel,
      editControlType: FormRow.FieldType.TextArea
   }
];

const FilterDefinitions = [
   {
      name: FieldNames.Search,
      defaultValue: "",
      editControlType: FormRow.FieldType.TextInput,
      maxTextLength: 40
   },
   {
      name: FieldNames.SearchDescription,
      defaultValue: false,
      editControlType: FormRow.FieldType.CheckBox
   },
   {
      name: FieldNames.ProfileGroupId,
      defaultValue: null,
      viewControlType: FormRow.FieldType.Label,
      editControlType: FormRow.FieldType.OptionSet
   },
   {
      name: FieldNames.ConformanceGroup,
      defaultValue: null,
      viewControlType: FormRow.FieldType.Label,
      editControlType: FormRow.FieldType.OptionSet
   },
   {
      name: FieldNames.ShowIndirectlyIncluded,
      defaultValue: false,
      editControlType: FormRow.FieldType.CheckBox
   }
];

const ChildDefinitions = [
   {
      name: ChildListNames.Profiles,
      url: `${PageApiPath}profiles/`,
      expanded: false,
      renderList: (props) => <ProfileList {...props} />
   }
];

const TestCaseLink = (props) => {
   const {
      item
   } = props;

   const [tests, setTests] = useState([]);
   const itemId = item?.id;
   const mounted = useRef(true);

   useEffect(() => {
      return () => {
         mounted.current = false;
      }
   }, []);

   // fetch
   useEffect(() => {
      fetch(itemId);
      async function fetch(id) {
         const response = await Utils.httpGet(`api${PageApiPath}tests/${id}`);
         if (!mounted.current) {
            return;
         }
         if (response && !response.failed) {
            setTests(response.result);
         }
         else {
            setTests([]);
         }
      }
   }, [itemId]);

   if (!tests?.length) {
      return <span>{Locales.getDisplayText(`${PageName}_noTestsFound`)}</span>;
   }

   let count = 1;

   return <div className="p-0 m-0 fluid w-100">
      {tests.map(ii => {
         return (<span key={count++}>
            {(count <= 1) ? null : <span className="px-2">|</span>}
            <Link
               to={{
                  pathname: `${Utils.PagePaths.TestSuites}${ii.id}`,
                  state: {
                     url: `${PageApiPath}${itemId}`
                  }
               }}
               className='p-0 m-0'>{ii.rootName}
            </Link>
         </span>);
      })}
   </div>
}

function renderFieldValue(props, name, item, field) {
   const {
      parent,
      categories,
      conformanceGroups,
      onItemUpdated,
      editingEnabled,
      location,
      noLink
   } = props;
   const value = FormRow.renderFieldValue(props, name, item, field, Utils.PagePaths.ConformanceUnits);
   if (value) {
      return value;
   }
   switch (name) {
      case FieldNames.Category: {
         if (categories) {
            let ct = categories.find(ii => ii.guid === item.categoryGuid);
            return <FormRow.LinkField
               id={ct?.id}
               item={ct}
               checkDependencies={true}
               displayText={(ct?.name) ?? item.categoryGuid}
               path={Utils.PagePaths.Categories}
               location={location}
               noLink={noLink}
            />;
         }
         return null;
      }
      case FieldNames.ConformanceGroup: {
         if (conformanceGroups) {
            let cg = conformanceGroups.find(ii => ii.guid === item.conformanceGroupGuid);
            return <FormRow.LinkField
               id={cg?.id}
               item={cg}
               displayText={(cg?.name) ?? item.conformanceGroupGuid}
               path={Utils.PagePaths.ConformanceGroups}
               location={location}
               noLink={noLink}
            />;
         }
         return null;
      }
      case FieldNames.IsOptional: {
         if (parent?.id !== item.includingProfileId) {
            return <FormRow.IconField
               falseState={(item[name]) ? Icons.faCheckSquare : Icons.faSquare}
               helpText={Locales.getHelpText("includedConformanceUnit_isOptional")}
               color={"DarkGray"}
               style={{ marginLeft: '0px' }}
            />;
         }
         let onClick = undefined;
         if (onItemUpdated && editingEnabled) {
            onClick = () => onItemUpdated({ item, field: name });
         }
         return <FormRow.CheckBoxField
            value={item[name]}
            onClick={onClick}
         />;
      }
      case FieldNames.TestCases: {
         return <TestCaseLink item={item} location={location} />;
      }
      default: {
         return item[name];
      }
   }
}

function toJson(item) {
   return {
      id: item.id,
      guid: item.guid,
      name: item.name,
      description: item.description,
      releaseStatus: item.releaseStatus,
      sort: item.sort,
      categoryGuid: item.categoryGuid,
      categoryPgId: item.categoryPgId,
      conformanceGroupGuid: item.conformanceGroupGuid,
      conformanceGroupPgId: item.conformanceGroupPgId,
      profileGroupId: item.profileGroupId,
      workingGroupId: item.workingGroupId,
      lastUpdateTime: Utils.getDate(item.lastUpdateTime)
   }
}

function initializeNewItem(props, newItem) {
   const {
      item,
      user,
      parent
   } = props;
   if (user?.profileGroupId) newItem.profileGroupId = user.profileGroupId;
   if (user?.workingGroupId) newItem.workingGroupId = user.workingGroupId;
   if (parent?.guid) newItem.conformanceGroupGuid = parent.guid;
   if (parent?.profileGroupId) newItem.conformanceGroupPgId = parent.profileGroupId;
   if (item?.name) newItem.name = `${item.name} (Copy)`;
   if (item?.sort) newItem.sort = item.sort + 1;
   if (item?.categoryGuid) newItem.categoryGuid = item.categoryGuid;
   if (item?.categoryPgId) newItem.categoryPgId = item.categoryPgId;
   if (item?.conformanceGroupGuid) newItem.conformanceGroupGuid = item.conformanceGroupGuid;
   if (item?.conformanceGroupPgId) newItem.conformanceGroupPgId = item.conformanceGroupPgId;
   if (item?.profileGroupId) newItem.profileGroupId = item.profileGroupId;
   if (item?.workingGroupId) newItem.workingGroupId = item.workingGroupId;
}

function updateInitialFilter(props, filters) {
   filters[FieldNames.Search] = "";
   filters.exclude = [
      FieldNames.SearchDescription,
      FieldNames.ShowIndirectlyIncluded
   ];
   filters.unsetDataFetched = [
      FieldNames.ShowIndirectlyIncluded,
      FieldNames.ProfileGroupId
   ];
};

function getFilterEditorOptions(props, filters) {
   const {
      user,
      parent,
      profileGroups,
      conformanceGroups,
      inListEditMode,
      items
   } = props;
   let pgs = [];
   if (inListEditMode) {
      pgs = profileGroups.map(ii => {
         return { id: ii.id, name: ii.fullName, sort: ii.workingGroupId }
      }).sort(Utils.sortBySortThenName);
      if (Utils.isNullId(filters.profileGroupId)) {
         filters.profileGroupId = user.profileGroupId;
      }
   }
   else {
      filters.profileGroupId = null;
   }
   var cgs = conformanceGroups;
   if (filters) {
      if (!parent && Utils.isNullId(filters.profileGroupId)) {
         filters.profileGroupId = user.profileGroupId;
      }
      if (inListEditMode) {
         cgs = cgs.filter(x => x.profileGroupId === filters.profileGroupId);
      }
      else {
         cgs = cgs.map(ii => {
            if (items.find(x => x.conformanceGroupGuid === ii.guid)) {
               return ii;
            }
            return null;
         }).filter(x => x !== null);
         if (!cgs.find(x => x.guid === filters[FieldNames.ConformanceGroup])) {
            const cg = conformanceGroups.find(x => x.guid === filters[FieldNames.ConformanceGroup]);
            if (cg) {
               cgs.push(cg);
            }
         }
      }
      if (!filters[FieldNames.ConformanceGroup]) {
         filters[FieldNames.ConformanceGroup] = -1;
      }
   }
   cgs = cgs.sort(Utils.sortBySortThenName);
   return {
      [FieldNames.ProfileGroupId]: pgs,
      [FieldNames.ConformanceGroup]: [
         Utils.AllValue,
         ...cgs.map(ii => {return { id: ii.guid, name: ii.name, sort: ii.sort }})
      ]
   };
};

function getFilterEditorStates(props, filters) {
   const {
      containerName,
      inListEditMode
   } = props;
   const states = {
      [FieldNames.ProfileGroupId]: FormRow.FieldState.Hidden,
      [FieldNames.ConformanceGroup]: FormRow.FieldState.Normal,
      [FieldNames.ShowIndirectlyIncluded]: FormRow.FieldState.Hidden
   }
   if (inListEditMode) {
      states[FieldNames.ProfileGroupId] = FormRow.FieldState.Normal;
   }
   if (containerName === Utils.PageNames.ConformanceGroups) {
      states[FieldNames.ConformanceGroup] = FormRow.FieldState.Hidden;
   }
   if (containerName === Utils.PageNames.Profiles && !inListEditMode) {
      states[FieldNames.ShowIndirectlyIncluded] = FormRow.FieldState.Normal;
   }
   return states;
};

function getEditorStates(props, item) {
   const states = {
      [FieldNames.Name]: FormRow.FieldState.Normal,
      [FieldNames.Description]: FormRow.FieldState.Normal,
      [FieldNames.ConformanceGroup]: FormRow.FieldState.Normal,
      [FieldNames.Category]: FormRow.FieldState.Normal,
      [FieldNames.Sort]: FormRow.FieldState.Normal,
      [FieldNames.Version]: FormRow.FieldState.Normal,
      [FieldNames.LastUpdateTime]: FormRow.FieldState.Hidden,
      [FieldNames.LastUpdatingUserId]: FormRow.FieldState.Hidden
   };
   if (item.releaseStatus >= Utils.ReleaseStatus.Released) {
      return {
         ...states,
         [FieldNames.Name]: FormRow.FieldState.Disabled,
         [FieldNames.Description]: FormRow.FieldState.Disabled,
         [FieldNames.ConformanceGroup]: FormRow.FieldState.Disabled,
         [FieldNames.Category]: FormRow.FieldState.Disabled
      };
   }
   return states;
};

export const ConformanceUnitList = (props) => {
  
   const {
      user,
      parent,
      url,
      containerName,
      storageKey,
      groupByName
   } = props;

   const [lastUsedFilters] = useState({});
   const [profileGroups, setProfileGroups] = useState([]);
   const [categories, setCategories] = useState([]);
   const [conformanceGroups, setConformanceGroups] = useState([]);
   const [hasTestCases, setHasTestCases] = useState(false);
   const isComponent = (parent && containerName === Utils.PageNames.Profiles) ? true : false;
   const query = Utils.getProfileGroupQuery();
   const parentPgId = (parent) ? parent.profileGroupId : user?.profileGroupId;
   const userProfileGroupId = user?.profileGroupId;

   useEffect(() => {
      if (userProfileGroupId) {
         fetch();
      }
      async function fetch() {
         setProfileGroups(await Utils.fetchProfileGroupDependencies(userProfileGroupId));
         setCategories(await Utils.fetchRecords(Utils.PagePaths.Categories, query, true));
         setConformanceGroups(await Utils.fetchRecords(Utils.PagePaths.ConformanceGroups, query, true));
      }
   }, [query, userProfileGroupId]);

   useEffect(() => {
      const pg = Utils.findInCache(Utils.PagePaths.ProfileGroups, 0, parentPgId);
      if (pg) {
         setHasTestCases((pg.hasTestCases) ? true: false);
      }
   }, [parentPgId]);

   // columns
   const columns = useMemo(() => {
      return (isComponent) ?
         [{ name: FieldNames.IsOptional, width: "1" }, { name: FieldNames.InlineDescription, width: "8" }] :
         [{ name: FieldNames.InlineDescription, width: "8" }];
   }, [isComponent]);

   const sortColumn = useMemo(() => {
      return (isComponent) ?
         { name: FieldNames.IsOptional } :
         { name: FieldNames.Sort };
   }, [isComponent]);

   const updateUrlToUse = useCallback((context, urlToUse) => {
      const { filters, inListEditMode, doViewDetails, item } = context;
      if (doViewDetails) {
         return `${urlToUse}${item?.id}`;
      }
      if (!inListEditMode) {
         urlToUse += (filters[FieldNames.ShowIndirectlyIncluded]) ? "?all=1" : "";
         return urlToUse;
      }
      const pg = filters[FieldNames.ProfileGroupId];
      if (!Utils.isNullId(pg)) {
         const rs = user.releaseStatus;
         urlToUse += `?pg=${pg ?? Utils.DefaultProfileGroup}&rs=${rs ?? Utils.ReleaseStatus.Released}`;
      }
      return urlToUse;
   }, [user]);

   const updateBeforeApplyFilters = useCallback((filters, items) => {
       //setLastUsedFilters(filters);
       //setAllowRowEdit(!filters[FieldNames.ShowIndirectlyIncluded]);
       items.map(ii => {
         try {
            ii.search = (!filters.searchDescription) ? ii.name : ii.description;
            return ii;
         }
         catch (e) {
            console.error(e);
            return ii;
         }
      });
   }, []);

   const onSelectModeChanged = useCallback((context) => {
      const { filters, selectModeEnabled } = context;
      if (selectModeEnabled) {
         return { ...filters, profileGroupId: parentPgId, showIndirectlyIncluded: false };
      }
      return { ...filters, profileGroupId: -1 };
   }, [parentPgId]);

   // select group
   const groupByCategory = useCallback((item) => {
      return Utils.selectGroupByGuid(
         item,
         categories,
         parentPgId,
         "categoryGuid",
         "categoryPgId");
   }, [categories, parentPgId]);

   const groupByConformanceGroup = useCallback((item) => {
      return Utils.selectGroupByGuid(
         item,
         conformanceGroups,
         parentPgId,
         "conformanceGroupGuid",
         "conformanceGroupPgId");
   }, [conformanceGroups, parentPgId]);

   const groupByProfileGroup = useCallback((item) => {
      return Utils.groupByProfileGroup(profileGroups, item);
   }, [profileGroups]);

   const selectGroupBy = useCallback(() => {
      if (containerName === Utils.PageNames.ConformanceGroups) {
         return null;
      }
      if (!Utils.isNullId(lastUsedFilters[FieldNames.ConformanceGroup])) {
         return groupByCategory;
      }
      if (groupByName) {
         switch (groupByName) {
            case Utils.PageNames.Categories: return groupByCategory;
            case Utils.PageNames.ConformanceGroups: return groupByConformanceGroup;
            case Utils.PageNames.ProfileGroups: return groupByProfileGroup;
            default: break;
         }
      }
      return groupByConformanceGroup;
   }, [groupByName, lastUsedFilters, containerName, groupByCategory, groupByConformanceGroup, groupByProfileGroup]);

   return (
      <MultiSelectList
         {...props}
         pageName={PageName}
         filterByUserContext={true}
         containerName={containerName}
         storageKey={storageKey}
         parent={parent}
         url={url}
         rowUpdateUrl={`${Utils.PagePaths.Profiles}includedconformanceunits/update/`}
         viewDetailsUrl={(hasTestCases) ? `${Utils.PagePaths.TestSuites}?cu=` : undefined}
         pathApiUrl={PageApiPath}
         updateUrlToUse={updateUrlToUse}
         fieldDefinitions={FieldDefinitions}
         toJson={toJson}
         renderFieldValue={renderFieldValue}
         initializeNewItem={initializeNewItem}
         deleteItemConfirmText={(isComponent) ? "confirmDeleteConformanceUnit" : undefined}
         filterDefinitions={FilterDefinitions}
         updateInitialFilter={updateInitialFilter}
         getFilterEditorOptions={getFilterEditorOptions}
         getFilterEditorStates={getFilterEditorStates}
         updateBeforeApplyFilters={updateBeforeApplyFilters}
         columns={columns}
         sortColumn={sortColumn}
         groupBy={selectGroupBy()}
         profileGroups={profileGroups}
         categories={categories}
         conformanceGroups={conformanceGroups}
         allowItemUpdate={isComponent}
         onSelectModeChanged={onSelectModeChanged}
         hasTestCases={hasTestCases}
      />);
}

export const ConformanceUnitPage = (props) => {

   const {
      parent,
      url,
      containerName,
      storageKey
   } = props;

   const [categories, setCategories] = useState([]);
   const [conformanceGroups, setConformanceGroups] = useState([]);
   const query = Utils.getProfileGroupQuery();

   useEffect(() => {
      fetch();
      async function fetch() {
         setCategories(await Utils.fetchRecords(Utils.PagePaths.Categories, query, true));
         setConformanceGroups(await Utils.fetchRecords(Utils.PagePaths.ConformanceGroups, query));
      }
   }, [query]);

   const getEditorOptions = useCallback((context, item) => {
      const cts = categories.map((ii) => {
         return { id: ii.guid, name: ii.name, sort: ii.sort };
      });
      const cgs = conformanceGroups.filter(x => x.profileGroupId === item.profileGroupId).map((ii) => {
         return { id: ii.guid, name: ii.name, sort: ii.sort };
      });
      const releaseStatuses = Object.keys(Utils.ReleaseStatus).map((ii) => {
         return {
            id: Utils.ReleaseStatus[ii],
            name: Locales.getDisplayText(`releaseStatus_${ii}`)
         };
      });
      return {
         [FieldNames.Category]: cts,
         [FieldNames.ConformanceGroup]: cgs,
         [FieldNames.ReleaseStatus]: releaseStatuses
      };
   }, [categories, conformanceGroups]);

   const beforeUpdateItem = useCallback((context) => {
      const { item } = context;
      if (Utils.isNullId(item.conformanceGroupGuid)) {
         item.conformanceGroupGuid = null;
         item.conformanceGroupPgId = 0;
      }
      if (item.conformanceGroupGuid) {
         if (conformanceGroups) {
            item.conformanceGroupPgId =
               conformanceGroups.find(ii => ii.guid === item.conformanceGroupGuid)
                  ?.profileGroupId;
         }
      }
      if (Utils.isNullId(item.categoryGuid)) {
         item.categoryGuid = null;
         item.categoryPgId = 0;
      }
      if (item.categoryGuid) {
         if (categories) {
            item.categoryPgId =
               categories.find(ii => ii.guid === item.categoryGuid)
                  ?.profileGroupId;
         }
      }
      return null;
   }, [categories, conformanceGroups]);
   
   return (
      <SingleItemPage
         {...props}
         pageName={PageName}
         pathApiUrl={PageApiPath}
         containerName={containerName}
         storageKey={storageKey}
         parent={parent}
         url={url}
         fieldDefinitions={FieldDefinitions}
         toJson={toJson}
         childDefinitions={ChildDefinitions}
         renderFieldValue={renderFieldValue}
         getEditorOptions={getEditorOptions}
         getEditorStates={getEditorStates}
         initializeNewItem={initializeNewItem}
         beforeUpdateItem={beforeUpdateItem}
         categories={categories}
         conformanceGroups={conformanceGroups}
      />);
}
