import * as React from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import * as Utils from "./Utils.js";
import { ErrorPage } from './ErrorPage.js';

export const LoginPage = () => {
   const [error, setError] = React.useState({});
   const query = new URLSearchParams(useLocation().search);
   const url = query.get("url");
   const errorCode = query.get("error");
   const errorText = query.get("error_text");
   const history = useHistory();

   React.useEffect(() => {
      setError({ errorCode, errorText });
   }, [errorCode, errorText]);

   React.useEffect(() => {
      let controller = new AbortController();
      if (!errorCode && url) {
         fetch();
      }
      async function fetch() {
         const response = await Utils.httpGet(`api/login/current`, controller);
         if (response?.errorCode) {
            if (!response.silent) {
               console.error(`Error logging in: ${response.errorCode}: ${response.errorText}`);
            }
            setError({ errorCode: response.errorCode, errorText: response.errorText });
         }
         else {
            const user = response?.result;
            user.editing = true;
            Utils.setCurrentUser(user, true);
            history.push(url);
         }
      }
      return function cleanup() {
         controller.abort();
      };
   }, [errorCode, url, history]);

   return (
      <ErrorPage item={error} />
   );
}
