import React, { useState, useEffect, useCallback } from "react";
import { useHistory, useLocation } from "react-router-dom";

import * as Utils from "./Utils.js";
import * as ItemPage from "./ItemPage";
import * as ItemForm from "./ItemForm";

const defaultItem = { id: 0, name: "" };

export const RequestType = {
   Get: 1,
   Put: 2,
   Delete: 3
}

function getUrlForItem(props) {
   const {
      url,
      pathApiUrl,
      updateUrlToUse,
      type
   } = props;
   let urlToUse = url;
   if (!urlToUse) {
      urlToUse = pathApiUrl;
      switch (type) {
         default:
         case RequestType.Get: { urlToUse += "get/"; break; }
         case RequestType.Put: { urlToUse += "put/"; break; }
         case RequestType.Delete: { urlToUse += "delete/"; break; }
      }
   }
   urlToUse = (updateUrlToUse) ? updateUrlToUse({ ...props, urlToUse }) : urlToUse;
   return urlToUse;
}

export const SingleItemPage = (props) => {
   const {
      user,
      anyoneCanEdit,
      pageName,
      containerName,
      storageKey,
      url,
      editUrl,
      deleteUrl,
      pathApiUrl,
      updateUrlToUse,
      fieldDefinitions,
      commandDefinitions,
      toJson,
      getViewerStates,
      getEditorStates,
      getEditorOptions,
      renderFieldValue,
      childDefinitions,
      initializeNewItem,
      beforeFetchItem,
      beforeUpdateItem,
      afterUpdateItem,
      noDelete,
      noCreate,
      alwaysEdit,
      noSetCurrentRecord
   } = props;

   const [item, setItem] = useState(defaultItem);
   const [updateCount, setUpdateCount] = useState(1);
   const [editingAllowed, setEditingAllowed] = useState(false);
   const userProfileGroupId = user?.profileGroupId;
   const userReleaseStatus = user?.releaseStatus;
   const isEditor = Utils.isEditor(props.item);
   const history = useHistory();
   const location = useLocation();
   const source = history.location.state?.item;
   const id = Utils.getId(props.id, location, pathApiUrl);
   const effectiveStorageKey = storageKey ?? (containerName ?? pageName);
   const returnUrl = location?.state?.url;

   // re-fetch after user change.
   useEffect(() => {
      setUpdateCount(x => x + 1);
   }, [userProfileGroupId, userReleaseStatus]);

   // update editor access.
   useEffect(() => {
      setEditingAllowed((isEditor || anyoneCanEdit) ? true : false);
   }, [anyoneCanEdit, isEditor]);

   // fetch
   useEffect(() => {
      fetch(id);
      async function fetch(id) {
         if (id <= 0) {
            const newItem = Utils.createNewItem(fieldDefinitions);
            if (initializeNewItem) {
               initializeNewItem({ user: user, item: source }, newItem);
            }
            setItem(newItem);
            if (!noSetCurrentRecord) {
               Utils.setCurrentRecord(pathApiUrl, newItem, null, true);
            }
         }
         else {
            const urlToUse = getUrlForItem({
               user,
               url,
               pathApiUrl,
               updateUrlToUse,
               type: RequestType.Get
            });
            if (urlToUse) {
               let newItem = null;
               if (beforeFetchItem) {
                  newItem = await beforeFetchItem({ id, urlToUse })
                  if (newItem) {
                     setItem(newItem);
                     return;
                  }
               }
               newItem = await Utils.simpleFetchItem(urlToUse, id, true);
               if (alwaysEdit) {
                  newItem.editing = true;
               }
               if (!noSetCurrentRecord) {
                  Utils.setCurrentRecord(pathApiUrl, newItem);
               }
               setItem(newItem);
               setEditingAllowed((Utils.isEditor(newItem) || anyoneCanEdit) ? true : false);
            }
         }
      }
   },
   [
      id,
      fieldDefinitions,
      pathApiUrl,
      initializeNewItem,
      source,
      user,
      url,
      alwaysEdit,
      anyoneCanEdit,
      noSetCurrentRecord,
      beforeFetchItem,
      updateUrlToUse,
      userProfileGroupId
   ]);

   // create
   const createNewItem = () => {
      return Utils.navigateForward(history, `${pathApiUrl}0`, `${pathApiUrl}${item.id}`, (toJson) ? toJson(item) : item);
   };

   // update
   const updateItem = useCallback(async (item) => {
      const urlToUse = getUrlForItem({
         user,
         url: editUrl,
         pathApiUrl,
         updateUrlToUse,
         type: RequestType.Put
      });
      if (urlToUse) {
         let newItem = null;
         if (beforeUpdateItem) {
            newItem = await beforeUpdateItem({ item, urlToUse })
            if (newItem) {
               setItem(newItem);
               setUpdateCount(x => x + 1);
               return;
            }
         }
         newItem = await Utils.simpleUpdateItem(urlToUse, item, true);
         if (afterUpdateItem) {
            afterUpdateItem(newItem);
         }
         if (item.id <= 0 && newItem.id !== 0) {
            Utils.navigateForward(history, `${pathApiUrl}${newItem.id}`, returnUrl ?? pathApiUrl);
         }
         setItem(newItem);
         setUpdateCount(x => x + 1);
      }
   }, [user, editUrl, pathApiUrl, history, updateUrlToUse, returnUrl, beforeUpdateItem, afterUpdateItem]);

   // delete
   const deleteItem = useCallback(async (item) => {
      const urlToUse = getUrlForItem({
         user,
         url: deleteUrl,
         pathApiUrl,
         updateUrlToUse,
         type: RequestType.Delete
      });
      if (urlToUse) {
         const newItem = await Utils.simpleDeleteItem(urlToUse, item, true);
         if (newItem?.errorCode) {
            setItem(newItem);
            return;
         }
         Utils.navigateBack(history, `${pathApiUrl}`);
      }
   }, [user, deleteUrl, pathApiUrl, history, updateUrlToUse]);

   const renderExpanded = useCallback((context) => {
      return (<ItemForm.ItemForm
         {...context}
         pageName={pageName}
         containerName={pageName}
         location={location}
         getViewerStates={getViewerStates}
         getEditorStates={getEditorStates}
         getEditorOptions={getEditorOptions}
         renderFieldValue={renderFieldValue}
         definitions={fieldDefinitions}
         toJson={toJson}
      />);
   }, [pageName, location, renderFieldValue, fieldDefinitions, toJson, getEditorOptions, getEditorStates, getViewerStates]);

   const renderChildren = useCallback((context, parent) => {
      if (childDefinitions && parent) {
         return ItemPage.renderChildren({
            ...context,
            containerName: containerName ?? pageName,
            pageApiPath: pathApiUrl,
            childDefinitions: childDefinitions,
            hidden: {}
         }, parent);
      }
      return null;
   }, [pageName, containerName, pathApiUrl, childDefinitions]);

   const onCommandCompleted = useCallback((updatedItem) => {
      if (updatedItem) {
         setItem((oldItem) => { return { ...oldItem, ...updatedItem }; });
      }
   }, []);

   return (
      <ItemPage.ItemPage
         {...props}
         location={location}
         item={item}
         editingEnabled={editingAllowed}
         containerName={containerName ?? pageName}
         containerUrl={`${pathApiUrl}`}
         storageKey={effectiveStorageKey}
         fields={fieldDefinitions}
         commands={commandDefinitions}
         onCommandCompleted={(commandDefinitions) ? onCommandCompleted : undefined}
         createNewItem={(item?.id && !noCreate) ? createNewItem : undefined}
         onItemUpdated={updateItem}
         onItemDeleted={(!noDelete) ? deleteItem : undefined}
         renderFieldValue={renderFieldValue}
         renderExpanded={renderExpanded}
         renderChildren={renderChildren}
         updateCount={updateCount}
      />);
}