import React, { useState, useEffect, useCallback } from "react";

import * as FormRow from "./FormRow.js";
import * as Utils from "./Utils.js";
import * as Locales from "./locales";
import { MultiSelectList } from "./MultiSelectList";
import { SingleItemPage } from "./SingleItemPage";
import { ProfileList } from "./ProfilePage";

const PageCacheName = Utils.PageNames.ProfileFolders;
const PageApiPath = Utils.PagePaths.ProfileFolders;
const PageName = PageCacheName;

export const FieldNames = {
   Name: Utils.FieldNames.Name,
   Description: Utils.FieldNames.Description,
   Sort: Utils.FieldNames.Sort,
   ReleaseStatus: Utils.FieldNames.ReleaseStatus,
   Version: Utils.FieldNames.Version,
   LastUpdateTime: Utils.FieldNames.LastUpdateTime,
   LastUpdatingUserId: Utils.FieldNames.LastUpdatingUserId,
   ProfileGroupId: Utils.FieldNames.ProfileGroupId,
   WorkingGroupId: Utils.FieldNames.WorkingGroupId,
   Category: "categoryGuid",
   ParentFolder: "parentFolderGuid",
   Search: Utils.FieldNames.Search,
   SearchDescription: Utils.FieldNames.SearchDescription,
   InlineDescription: Utils.FieldNames.InlineDescription
};

export const ChildListNames = {
   Profiles: "profiles",
   ChildFolders: "children"
};

const FieldDefinitions = [
   {
      name: FieldNames.Name,
      defaultValue: "",
      viewControlType: FormRow.FieldType.Label,
      editControlType: FormRow.FieldType.TextInput,
      maxTextLength: 80
   },
   {
      name: FieldNames.Category,
      defaultValue: null,
      viewControlType: FormRow.FieldType.Label,
      editControlType: FormRow.FieldType.OptionSet
   },
   {
      name: FieldNames.ParentFolder,
      defaultValue: null,
      viewControlType: FormRow.FieldType.Label,
      editControlType: FormRow.FieldType.OptionSet
   },
   {
      name: FieldNames.ReleaseStatus,
      defaultValue: Utils.ReleaseStatus.Draft,
      viewControlType: FormRow.FieldType.Label,
      editControlType: FormRow.FieldType.OptionSet
   },
   {
      name: FieldNames.Sort,
      defaultValue: 0,
      viewControlType: FormRow.FieldType.Label,
      editControlType: FormRow.FieldType.NumberInput,
      minValue: 0,
      maxValue: 10000,
      editorOnly: true
   },
   {
      name: FieldNames.Version,
      defaultValue: 1,
      viewControlType: FormRow.FieldType.Label,
      editorOnly: true
   },
   {
      name: FieldNames.LastUpdateTime,
      defaultValue: "",
      viewControlType: FormRow.FieldType.Label,
      editorOnly: true
   },
   {
      name: FieldNames.LastUpdatingUserId,
      defaultValue: "",
      viewControlType: FormRow.FieldType.Label,
      editorOnly: true
   },
   {
      name: FieldNames.ProfileGroupId,
      defaultValue: Utils.DefaultProfileGroup,
      viewControlType: FormRow.FieldType.Label
   },
   {
      name: FieldNames.Description,
      defaultValue: "",
      viewControlType: FormRow.FieldType.BlockLabel,
      editControlType: FormRow.FieldType.TextArea
   }
];

const FilterDefinitions = [
   {
      name: FieldNames.Search,
      defaultValue: "",
      editControlType: FormRow.FieldType.TextInput,
      maxTextLength: 40
   },
   {
      name: FieldNames.SearchDescription,
      defaultValue: false,
      editControlType: FormRow.FieldType.CheckBox
   },
   {
      name: FieldNames.Category,
      defaultValue: null,
      viewControlType: FormRow.FieldType.Label,
      editControlType: FormRow.FieldType.OptionSet
   },
];

const ChildDefinitions = [
   {
      name: ChildListNames.Profiles,
      url: `${PageApiPath}profiles/`,
      expanded: false,
      noFilters: true,
      renderList: (props) => <ProfileList {...props} enableEditSort={true} />
   },
   {
      name: ChildListNames.ChildFolders,
      url: `${PageApiPath}children/`,
      expanded: false,
      noFilters: true,
      renderList: (props) => <ProfileFolderList {...props} enableEditSort={true} />
   }
];

function renderFieldValue(props, name, item, field) {
   const {
      categories,
      parentFolders,
      location,
      noLink
   } = props;
   const value = FormRow.renderFieldValue(props, name, item, field, Utils.PagePaths.ProfileFolders);
   if (value) {
      return value;
   }
   switch (name) {
      case FieldNames.Category: {
         if (categories) {
            let target = categories.find(ii => ii.guid === item.categoryGuid && ii.profileGroupId === item.categoryPgId);
            if (target) {
               return <FormRow.LinkField
                  id={target?.id}
                  item={target}
                  checkDependencies={true}
                  displayText={(target?.name) ?? item.categoryGuid}
                  path={Utils.PagePaths.Categories}
                  location={location}
                  noLink={noLink}
               />;
            }
         }
         return null;
      }
      case FieldNames.ParentFolder: {
         if (parentFolders) {
            let target = parentFolders.find(ii => ii.guid === item.parentFolderGuid && ii.profileGroupId === item.parentFolderPgId);
            if (target) {
               return <FormRow.LinkField
                  id={target?.id}
                  item={target}
                  displayText={(target?.name) ?? item.parentFolderGuid}
                  path={Utils.PagePaths.ProfileFolders}
                  location={location}
                  noLink={noLink}
               />;
            }
         }
         return null;
      }
      default: {
         return item[name];
      }
   }
}

function toJson(item) {
   return {
      id: item.id,
      guid: item.guid,
      name: item.name,
      description: item.description,
      releaseStatus: item.releaseStatus,
      sort: item.sort,
      categoryGuid: item.categoryGuid,
      categoryPgId: item.categoryPgId,
      parentFolderGuid: item.parentFolderGuid,
      parentFolderPgId: item.parentFolderPgId,
      profileGroupId: item.profileGroupId,
      workingGroupId: item.workingGroupId,
      lastUpdateTime: Utils.getDate(item.lastUpdateTime)
   }
}

function initializeNewItem(props, newItem) {
   const {
      item,
      parent,
      user
   } = props;
   if (user?.profileGroupId) newItem.profileGroupId = user.profileGroupId;
   if (user?.workingGroupId) newItem.workingGroupId = user.workingGroupId;
   if (parent?.guid) newItem.parentFolderGuid = parent.guid;
   if (parent?.profileGroupId) newItem.parentFolderPgId = parent.profileGroupId;
   if (parent?.categoryGuid) newItem.categoryGuid = parent.categoryGuid;
   if (parent?.categoryPgId) newItem.categoryPgId = parent.categoryPgId;
   if (item?.name) newItem.name = `${item.name} (Copy)`;
   if (item?.sort) newItem.sort = item.sort + 1;
   if (item?.parentFolderGuid) newItem.parentFolderGuid = item.parentFolderGuid;
   if (item?.parentFolderPgId) newItem.parentFolderPgId = item.parentFolderPgId;
   if (item?.categoryGuid) newItem.categoryGuid = item.categoryGuid;
   if (item?.categoryPgId) newItem.categoryPgId = item.categoryPgId;
   if (item?.profileGroupId) newItem.profileGroupId = item.profileGroupId;
   if (item?.workingGroupId) newItem.workingGroupId = item.workingGroupId;
}

function updateInitialFilter(props, filters) {
   filters[FieldNames.Search] = "";
   filters.exclude = [
      Utils.FieldNames.ProfileGroupId,
      FieldNames.SearchDescription
   ];
   filters.unsetDataFetched = [
      FieldNames.ProfileGroupId
   ];
};

function getFilterEditorOptions(props, filter) {
   const {
      user,
      profileGroups,
      categories,
      inListEditMode,
      items
   } = props;
   let pgs = [];
   if (inListEditMode) {
      pgs = profileGroups.map(ii => {
         return { id: ii.id, name: ii.fullName, sort: ii.workingGroupId }
      }).sort(Utils.sortBySortThenName);
      if (Utils.isNullId(filter.profileGroupId)) {
         filter.profileGroupId = user.profileGroupId;
      }
   }
   var cgs = categories;
   if (filter) {
      if (Utils.isNullId(filter.profileGroupId)) {
         filter.profileGroupId = user.profileGroupId;
      }
      if (inListEditMode) {
         cgs = cgs.filter(x => x.profileGroupId === filter.profileGroupId);
      }
      else {
         cgs = cgs.map(ii => {
            if (items.find(x => x.categoryGuid === ii.guid)) {
               return ii;
            }
            return null;
         }).filter(x => x !== null);
         if (!cgs.find(x => x.guid === filter[FieldNames.Category])) {
            const cg = categories.find(x => x.guid === filter[FieldNames.Category]);
            if (cg) {
               cgs.push(cg);
            }
         }
      }
      if (!filter[FieldNames.Category]) {
         filter[FieldNames.Category] = -1;
      }
   }
   cgs = cgs.sort(Utils.sortBySortThenName);
   return {
      [FieldNames.ProfileGroupId]: pgs,
      [FieldNames.Category]: [
         Utils.AllValue,
         ...cgs.map(ii => { return { id: ii.guid, name: ii.name, sort: ii.sort } })
      ]
   };
};

function getFilterEditorStates(props, filters) {
   const {
      containerName,
      inListEditMode
   } = props;
   const states = {
      [FieldNames.ProfileGroupId]: FormRow.FieldState.Hidden,
      [FieldNames.Category]: FormRow.FieldState.Normal
   }
   if (inListEditMode) {
      states[FieldNames.ProfileGroupId] = FormRow.FieldState.Normal;
   }
   if (containerName === Utils.PageNames.Categories) {
      states[FieldNames.Category] = FormRow.FieldState.Hidden;
   }
   return states;
};

function getEditorStates(props, item) {
   const {
      parentFolders
   } = props;
   const states = {
      [FieldNames.Name]: FormRow.FieldState.Normal,
      [FieldNames.Description]: FormRow.FieldState.Normal,
      [FieldNames.Category]: FormRow.FieldState.Normal,
      [FieldNames.ParentFolder]: FormRow.FieldState.Normal,
      [FieldNames.Sort]: FormRow.FieldState.Normal,
      [FieldNames.Version]: FormRow.FieldState.Normal,
      [FieldNames.LastUpdateTime]: FormRow.FieldState.Hidden,
      [FieldNames.LastUpdatingUserId]: FormRow.FieldState.Hidden
   };
   if (item.releaseStatus >= Utils.ReleaseStatus.Released) {
      return {
         ...states,
         [FieldNames.Name]: FormRow.FieldState.Disabled,
         [FieldNames.Description]: FormRow.FieldState.Disabled,
         [FieldNames.ParentFolder]: FormRow.FieldState.Disabled,
         [FieldNames.Category]: FormRow.FieldState.Disabled
      };
   }
   if (!Utils.isNullId(item.parentFolderGuid)) {
      states[FieldNames.Category] = FormRow.FieldState.Disabled;
      var parentFolder = parentFolders.find(x => x.guid === item.parentFolderGuid);
      if (parentFolder) {
         item.categoryGuid = parentFolder.categoryGuid;
         item.categoryPgId = parentFolder.categoryPgId;
      }
   }
   return states;
};

function updateBeforeApplyFilters(filters, items) {
   items.map(ii => {
      ii.search = (!filters.searchDescription) ? ii.name : ii.description;
      return ii;
   });
};

export const ProfileFolderList = (props) => {
  
   const {
      user,
      parent,
      url,
      containerName,
      storageKey,
      groupByName
   } = props;

   const [categories, setCategories] = useState([]);
   const [parentFolders, setParentFolders] = useState([]);
   const isComponent = (parent && containerName === Utils.PageNames.Profiles) ? true : false;
   const query = Utils.getProfileGroupQuery();
   const parentPgId = (parent) ? parent.profileGroupId : user.profileGroupId;
   const userProfileGroupId = user?.profileGroupId;

   // fetch linked records.
   useEffect(() => {
      fetch();
      async function fetch() {
         setParentFolders(await Utils.fetchRecords(Utils.PagePaths.ProfileFolders, query));
         setCategories(await Utils.fetchRecords(Utils.PagePaths.Categories, query, true));
      }
   }, [query, userProfileGroupId]);

   const updateUrlToUse = useCallback((context, urlToUse) => {
      const { inListEditMode, filterByUserContext } = context;
      if (inListEditMode && filterByUserContext) {
         urlToUse += `?all=0`;
      }
      return urlToUse;
   }, []);

   const columns = [{ name: FieldNames.InlineDescription, width: "8" }];
   const sortColumn = { name: FieldNames.Sort };

   // select group
   const groupByCategory = useCallback((item) => {
      return Utils.selectGroupByGuid(
         item,
         categories,
         parentPgId,
         "categoryGuid",
         "categoryPgId");
   }, [categories, parentPgId]);

   const groupByParentFolder = useCallback((item) => {
      return Utils.selectGroupByGuid(
         item,
         parentFolders,
         parentPgId,
         "parentFolderGuid",
         "parentFolderPgId");
   }, [parentFolders, parentPgId]);

   const selectGroupBy = useCallback(() => {
      if (containerName === Utils.PageNames.ProfileFolders) {
         return null;
      }
      if (groupByName) {
         switch (groupByName) {
            case Utils.PageNames.Categories: return groupByCategory;
            case Utils.PageNames.ProfileFolders: return groupByParentFolder;
            default: break;
         }
      }
      return groupByCategory;
   }, [groupByName, containerName, groupByCategory, groupByParentFolder]);

   return (
      <MultiSelectList
         {...props}
         pageName={PageName}
         filterByUserContext={true}
         containerName={containerName}
         storageKey={storageKey}
         parent={parent}
         url={url}
         pathApiUrl={PageApiPath}
         updateUrlToUse={updateUrlToUse}
         fieldDefinitions={FieldDefinitions}
         toJson={toJson}
         renderFieldValue={renderFieldValue}
         initializeNewItem={initializeNewItem}
         deleteItemConfirmText={(isComponent) ? "confirmDeleteProfileFolder" : undefined}
         filterDefinitions={FilterDefinitions}
         updateInitialFilter={updateInitialFilter}
         getFilterEditorOptions={getFilterEditorOptions}
         getFilterEditorStates={getFilterEditorStates}
         updateBeforeApplyFilters={updateBeforeApplyFilters}
         columns={columns}
         sortColumn={sortColumn}
         groupBy={selectGroupBy()}
         categories={categories}
         parentFolders={parentFolders}
      />);
}

export const ProfileFolderPage = (props) => {

   const {
      user,
      parent,
      url,
      containerName,
      storageKey
   } = props;

   const [categories, setCategories] = useState([]);
   const [parentFolders, setParentFolders] = useState([]);
   const query = Utils.getProfileGroupQuery();
   const userProfileGroupId = user?.profileGroupId;

   // fetch linked records.
   useEffect(() => {
      fetch();
      async function fetch() {
         setParentFolders(await Utils.fetchRecords(Utils.PagePaths.ProfileFolders, query));
         setCategories(await Utils.fetchRecords(Utils.PagePaths.Categories, query, true));
      }
   }, [query, userProfileGroupId]);

   const getEditorOptions = useCallback((context, item) => {
      const cts = categories.map((ii) => {
         return { id: ii.guid, name: ii.name, sort: ii.sort };
      });
      const pfs = parentFolders.filter(x => x.profileGroupId === item.profileGroupId).map((ii) => {
         const category = cts.find(x => x.id === ii.categoryGuid);
         return {
            id: ii.guid,
            name: (category) ? `[${category.name}] ${ii.name}` : ii.name,
            sort: ii.sort
         };
      });
      const releaseStatuses = Object.keys(Utils.ReleaseStatus).map((ii) => {
         return {
            id: Utils.ReleaseStatus[ii],
            name: Locales.getDisplayText(`releaseStatus_${ii}`)
         };
      });
      return {
         [FieldNames.Category]: cts,
         [FieldNames.ParentFolder]: [Utils.NoneValue, ...pfs],
         [FieldNames.ReleaseStatus]: releaseStatuses
      };
   }, [categories, parentFolders]);

   const beforeUpdateItem = useCallback((context) => {
      const { item } = context;
      if (Utils.isNullId(item.parentFolderGuid)) {
         item.parentFolderGuid = null;
         item.parentFolderPgId = null;
      }
      if (item.parentFolderGuid) {
         if (parentFolders) {
            const parentFolderPgId = parentFolders.find(ii => ii.guid === item.parentFolderGuid)?.profileGroupId;
            item.parentFolderPgId = parentFolderPgId;
         }
      }
      if (Utils.isNullId(item.categoryGuid)) {
         item.categoryGuid = null;
         item.categoryPgId = 0;
      }
      if (item.categoryGuid) {
         if (categories) {
            const categoryPgId = categories.find(ii => ii.guid === item.categoryGuid)?.profileGroupId;
            item.categoryPgId = categoryPgId ?? 0;
         }
      }
      return null;
   }, [categories, parentFolders]);

   const afterUpdateItem = useCallback((item) => {
      if (item?.id) {
         setParentFolders(folders => {
            if (folders.find(x => x.id === item.id)) {
               return folders.map(x => (x.id !== item?.id) ? x : item);
            }
            return [...folders, item];
         });
      }
   }, []);

   return (
      <SingleItemPage
         {...props}
         pageName={PageName}
         pathApiUrl={PageApiPath}
         containerName={containerName}
         storageKey={storageKey}
         parent={parent}
         url={url}
         fieldDefinitions={FieldDefinitions}
         toJson={toJson}
         childDefinitions={ChildDefinitions}
         renderFieldValue={renderFieldValue}
         getEditorOptions={getEditorOptions}
         getEditorStates={getEditorStates}
         initializeNewItem={initializeNewItem}
         beforeUpdateItem={beforeUpdateItem}
         afterUpdateItem={afterUpdateItem}
         categories={categories}
         parentFolders={parentFolders}
      />);
}
