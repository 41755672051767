export const enUS = {
   "name": { label: "Name", help: "A unique name for the record." },
   "description": { label: "Description", help: "A more detailed description of the record." },
   "inlineDescription": { label: "Description", help: "A more detailed description of the record." },
   "shortName": { label: "Short Name", help: "A unique short name for the record." },
   "sort": { label: "Sort Position", help: "The default position of the record in a list." },
   "email": { label: "Email", help: "An email address." },
   "companyName": { label: "Company Name", help: "The company name." },
   "isAdministrator": { label: "Is Administrator", help: "The user is an administrator." },
   "history": { label: "History", help: "The version history for the record." },
   "loadingRecords": { label: "Loading..." },
   "noRecordsFound": { label: "No Records Found." },
   "notAssigned": { label: "Not Assigned" },
   "none": { label: "None" },
   "displayOptions": { label: "Display Options", help: "Options to change what is displayed in the list." },
   "filterCount": { label: " of ", help: "The number of records displayed and the total number of records." },
   "ProfileGroupHidden": { label: "The current Profile Group filters may hide this record when you leave this page." },
   "ReleaseStatusHidden": { label: "The current ReleaseStatus filters may hide this record when you leave this page." },
   "userName": { label: "User Name", help: "The name of the user." },
   "signIn": { label: "Sign In" },
   "signOut": { label: "Sign Out" },
   "autoLogout": { label: "Logged Out" },
   "changeGroup": { label: "Change Group" },
   "confirmDelete": { label: "Confirm delete of" },
   "confirmDeleteProfile": { label: "Confirm unlinking of included profile " },
   "confirmDeleteConformanceUnit": { label: "Confirm unlinking of included conformance unit " },
   "confirmDeleteProfileFolder": { label: "Confirm unlinking of profile folder " },
   "sidebar_refresh": { help: "Checks for changes that affect the contents of the sidebar." },
   "sidebar_profilesTitle": { label: "OPC UA Profiles" },
   "sidebar_profilesSubtitle": { label: "Organized by Category" },
   "sidebar_workingGroupsTitle": { label: "OPC UA Working Groups" },
   "sidebar_documentsTitle": { label: "OPC UA Documents" },
   "sidebar_documentsSubtitle": { label: "Organized by Working Group" },
   "sidebar_conformanceUnitsTitle": { label: "OPC UA Conformance Units" },
   "sidebar_conformanceUnitsSubtitle": { label: "Organized by Conformance Group" },
   "sidebar_personsTitle": { label: "People" },
   "sidebar_personsSubtitle": { label: " " },
   "sidebar_profileGroupsTitle": { label: "OPC UA Profile Groups" },
   "sidebar_tagsTitle": { label: "Tags" },
   "sidebar_testSuitesTitle": { label: "Test Suites" },
   "sidebar_draft": { label: "[Draft]" },
   "sidebar_releaseCandidate": { label: "[RC]" },
   "sidebar_deprecated": { label: "[Deprecated]" },
   "chooseProfileGroup": {
      label: "Choose Profile Group", help: "Profiles are defined for OPC UA basic features and for OPC UA industry-specific models. For scalability and manageability they are separated in Profile Groups. Selecting a Profile Group is required to limit the results that appear in the application. Working groups create Profile Groups for their specifications. For example, the OPC UA working group defines the 'UACore 1.05' Profile Group, the working group for automatic identification technologies defines the 'AutoID 1.01' Profile Group. The profiles of a Profile Group will often represent multiple specifications or specification parts as chosen by the working group. They usually will refer to a specific version of a specification or set of specifications, however, that is not requrired."
   },
   "commands": { label: "Tasks", help: "Tasks that may executed for the current item." },
   "confirmCommand": { label: "Are you sure you want to start the task?" },
   "errorCode": { label: "Result Code", help: "The code assigned to the result." },
   "errorText": { label: "Result Text", help: "Additional text that describes the result." },
   "errorDetailsLink": { label: "Details Link", help: "A link to additional information on the error." },
   "createButton": { label: "Create", help: "Creates a new record." },
   "updateButton": { label: "Update", help: "Update the record." },
   "resetButton": { label: "Reset", help: "Resets the form to the last saved state." },
   "goBackButton": { label: "Back", help: "Goes back to the previous form." },
   "sortIcon": { label: "Sort", help: "Sorts the list in ascending order." },
   "sortIconActive": { label: "Sort", help: "Sorts the list in descending order." },
   "moveDownIcon": { label: "Move Down" },
   "moveUpIcon": { label: "Move Up" },
   "viewDetailIcon": { label: "View Detail", help: "Views detailed information associated with the record." },
   "editOrderIcon": { label: "Edit Sort Order", help: "Edit the position of items in the list." },
   "editOrderIconActive": { label: "Edit Sort Order", help: "Finishes editing the position of items in the list." },
   "toggleIcon": { label: "Toggle", help: "Opens the detail view." },
   "toggleIconActive": { label: "Toggle", help: "Closes the detail view." },
   "editIcon": { label: "Edit", help: "Edits the record." },
   "editIconActive": { label: "Edit", help: "Finishes editing the record." },
   "filtersIcon": { label: "Show Display Options", help: "Shows the display options for the list." },
   "filtersIconActive": { label: "Hide Display Options", help: "Hides the display options for the list." },
   "createIcon": { label: "Create", help: "Creates a new record." },
   "deleteIcon": { label: "Delete", help: "Deletes the new record." },
   "unlinkIcon": { label: "Unlink", help: "Removes a link between two records." },
   "selectIcon": { label: "Select", help: "Selects multiple records." },
   "selectIconActive": { label: "Select", help: "Finishes selecting records." },
   "selectItemIcon": { label: "SelectItem", help: "Click to select the record." },
   "selectItemIconActive": { label: "SelectItem", help: "Click to deselect the record." },
   "workingGroupId": { label: "Working Group", help: "The working group which owns the record." },
   "profileGroupId": { label: "Profile Group", help: "The group of profiles for a working group which include the record." },
   "releaseStatus": { label: "Release Status", help: "The release status for the record." },
   "releaseStatusFilter_Released": { label: "Released" },
   "releaseStatusFilter_ReleaseCandidate": { label: "Release Candidate and above" },
   "releaseStatusFilter_Draft": { label: "Draft and above" },
   "version": { label: "Version", help: "The version of the record." },
   "lastUpdateTime": { label: "Last Updated", help: "When the record was last changed." },
   "lastUpdatingUserId": { label: "Last Changed By", help: "The last user who altered the record." },
   "search": { label: "Search", help: "Search the name or description for the specified text." },
   "searchDescription": { label: "Search Description", help: "Search the description instead of the name." },
   "releaseStatus_Draft": { label: "Draft" },
   "releaseStatus_ReleaseCandidate": { label: "Release Candidate" },
   "releaseStatus_Released": { label: "Released" },
   "releaseStatus_Deprecated": { label: "Deprecated" },
   "releaseStatus_Archived": { label: "Archived" },
   "itemList_workingGroups": { label: "Working Groups" },
   "itemPage_workingGroups": { label: "Working Group" },
   "itemList_documents": { label: "Documents" },
   "itemPage_documents": { label: "Document" },
   "itemList_documentVersions": { label: "Published Documents" },
   "itemPage_documentVersions": { label: "Published Document" },
   "itemList_profileGroups": { label: "Profile Groups" },
   "itemPage_profileGroups": { label: "Profile Group" },
   "itemList_persons": { label: "Persons" },
   "itemPage_persons": { label: "Person" },
   "itemList_users": { label: "Users" },
   "itemPage_users": { label: "User" },
   "itemList_categories": { label: "Categories" },
   "itemPage_categories": { label: "Category" },
   "itemList_profiles": { label: "Profiles" },
   "itemPage_profiles": { label: "Profile" },
   "itemList_profileFolders": { label: "Profile Folders" },
   "itemPage_profileFolders": { label: "Profile Folder" },
   "itemList_conformanceGroups": { label: "Conformance Groups" },
   "itemPage_conformanceGroups": { label: "Conformance Group" },
   "itemList_conformanceUnits": { label: "Conformance Units" },
   "itemPage_conformanceUnits": { label: "Conformance Unit" },
   "itemList_tags": { label: "Tags" },
   "itemPage_tags": { label: "Tag" },
   "itemList_testSuites": { label: "Test Suites" },
   "itemPage_testSuites": { label: "Test Suites" },
   "itemList_testCases": { label: "Test Cases" },
   "itemPage_testCases": { label: "Test Cases" },
   "tags_name": { label: "Name", help: "A unique name for the tag." },
   "tags_type": { label: "Type", help: "The type of tag." },
   "tags_url": { label: "Url", help: "A url to more information about the tag." },
   "workingGroups_name": { help: "A unique name for the working group." },
   "workingGroups_description": { help: "The purpose of the working group." },
   "workingGroups_accessGroupName": { label: "Access Group", help: "The name of the group used to grant edit permissions." },
   "workingGroups_baseProfileUri": { label: "Base Profile URI", help: "The base URI for all profiles defined by the working group." },
   "workingGroups_organization": { label: "Organization", help: "The organization that manages the working group." },
   "workingGroups_collaborationUrl": { label: "Collaboration Website", help: "The website used for collaboration." },
   "workingGroups_marketingUrl": { label: "Marketing Website", help: "The website used for marketing." },
   "workingGroups_logoUrl": { label: "Logo Url", help: "The URL of a logo image that should be displayed on this website." },
   "workingGroups_classification": { label: "Classification", help: "Semicolon separated list of classifications for the working group." },
   "workingGroups_markets": { label: "Markets", help: "Semicolon separated list of markets targeted by the working group." },
   "workingGroups_modelSets": { label: "Model Sets", help: "The model sets defined by the working group." },
   "workingGroups_chairs": { label: "Chairs", help: "The current chair people for the working group." },
   "workingGroups_documents": { label: "Documents", help: "The documents produced by the working group." },
   "workingGroups_profileGroups": { label: "Profile Groups", help: "The collections of profiles and conformance units creates by the working group." },
   "profileGroups_name": { label: "Version", help: "Only needs to be unique within the context of the Working Group." },
   "profileGroups_CreateCopy": { label: "Create Copy", help: "Creates a new group and copies of the records linked to this group." },
   "profileGroups_CreateCopyConfirmText": { help: "Are you sure you want to copy all associated records and make a new version? This operation cannot be undone easily." },
   "profileGroups_replacedById": { label: "Replaced By", help: "The profile group that replaces the current group." },
   "profileGroups_notImplementable": { label: "Not Implementable", help: "The profile group provides a base information model that is referenced by other profile groups." },
   "profileGroups_coreDependencyMismatch": { label: "CoreDependencyMismatch", help: "The profile group dependency on a newer version of OPC UA." },
   "profileGroups_UploadTestCases": { label: "Upload Test Cases", help: "Choose a test case file to upload to the server." },
   "profileGroups_Upload": { label: "Upload", help: "Uploads the selected file to the server." },
   "profileGroups_ExportProfilesAsWord": { label: "Export to Word", help: "Export the profiles in the profile group to a Word document." },
   "profileGroups_ExportProfilesAsXml": { label: "Export to XML", help: "Export the profiles in the profile group to an XML document." },
   "profileGroups_file": { label: "Selected File", help: "The XML file to upload." },
   "profileGroups_root": {
      label: "Root Name", help: "The root folder name to be used for the test suites being uploaded.This name will be shown to the user to select when displaying a conformance unit.Usually those values are 'Server' for the server test cases or 'Client' for the client test cases." },
   "documents_name": { help: "A unique name for the document." },
   "documents_shortName": { help: "A unique short name for the document." },
   "documents_documentType": { label: "Document Type", help: "The type of document." },
   "documents_documentNumber": { label: "Document Number", help: "The number for the document." },
   "documents_minPartNumber": { label: "Part Number", help: "The number of the document part or the first document part in the series." },
   "documents_maxPartNumber": { label: "Last Part Number", help: "The number of the last document part in the series." },
   "documents_scope": { label: "Scope", help: "A brief overview of the purpose of the document." },
   "documents_shortNameAndSeries": { label: "Document Number", help: "The number assigned to the document or document series." },
   "documents_workingGroupId": { help: "The working group which manages the document or document series." },
   "documents_editors": { label: "Editors", help: "The group of editors for the document or document series." },
   "documents_childDocuments": { label: "Documents", help: "Individual documents in the document series." },
   "documents_documentVersions": { label: "Published Versions", help: "Published versions of the document." },
   "documents_licenseId": { label: "License", help: "The license that applies to the document." },
   "documents_accessLevel": { label: "Access Level", help: "Who has access to the document." },
   "documents_issueReportingUrl": { label: "Issue Reporting", help: "A link to the project used to report issues with the document." },
   "documents_namespaces": { label: "Namespaces", help: "The namespaces defined by the document." },
   "persons_name": { help: "The name of a person." },
   "persons_email": { help: "The e-mail address for a person." },
   "documentType_Specification": { label: "Specification" },
   "documentType_Guideline": { label: "Guideline" },
   "documentType_Template": { label: "Template" },
   "documentType_Series": { label: "Series" },
   "documentType_Whitepaper": { label: "Whitepaper" },
   "documentType_Other": { label: "Other" },
   "documentType_Procedures": { label: "Policies/Procedures" },
   "accessLevel_Everyone": { label: "Everyone" },
   "accessLevel_Registered": { label: "Registered" },
   "accessLevel_PaidMember": { label: "Paid Member" },
   "accessLevel_CorporateMember": { label: "Corporate Member" },
   "tagType_Classification": { label: "Classification" },
   "tagType_Markets": { label: "Markets" },
   "tagType_License": { label: "License" },
   "tagType_Keyword": { label: "Keyword" },
   "tagType_Namespace": { label: "Namespace" },
   "documentVersions_version": { label: "Version", help: "The version number of the document." },
   "documentVersions_releaseStatus": { label: "Release Status", help: "The release status for the document." },
   "documentVersions_publicationDate": { label: "Publication Date", help: "The date the document was published." },
   "documentVersions_hasTestSpecification": { label: "Tests Defined", help: "True if a test specification for the document exists." },
   "documentVersions_schemaUrl": { label: "Schemas", help: "The URL for the schemas defined by the document." },
   "documentVersions_storageFileName": { label: "Download Info", help: "The metadata for the published download if one exists." },
   "documentVersions_topics": { label: "Topics", help: "Notes indicating how the document differs from previous versions." },
   "documentVersions_documentId": { label: "Document", help: "More information about the document." },
   "documentVersions_reviewSummary": { label: "Review Summary", help: "A one line summary for the announcement for review." },
   "documentVersions_reviewDetails": { label: "Review Details", help: "The complete announcement for review." },
   "documentVersions_permanentUrl": { label: "Permanent URL", help: "The permanent URL for the document in the OnlineReference." },
   "documentVersions_UploadDocument": { label: "Publish Download", help: "Publish the document." },
   "documentVersions_DeleteDocument": { label: "Delete Download", help: "Delete the published document." },
   "documentVersions_Upload": { label: "Upload", help: "Uploads the selected document to the server." },
   "documentVersions_DeleteDocumentConfirmText": { help: "This operation will permanently delete the current download. Is this OK?" },
   "documentVersions_file": { label: "Selected File", help: "The document to upload." },
   "profileGroups_coreDependencyId": { label: "Core Dependency", help: "The version of the OPC UA Core specification that the profile group depends on." },
   "profileGroups_dependencies": { label: "Dependencies", help: "The profile group which the current group depends on." },
   "profileGroups_specifications": { label: "Specifications", help: "The specifications associated withe the profile group." },
   "profileGroups_hasTestCases": { label: "Has Test Cases", help: "The profile group has test cases defined." },
   "categories_profiles": { label: "Profiles", help: "The profiles associated with the category." },
   "categories_profileFolders": { label: "Profile Folders", help: "The profile folders associated with the category." },
   "categories_conformanceUnits": { label: "Conformance Units", help: "The conformance units in with the category." },
   "conformanceUnits_categoryGuid": { label: "Category", help: "The category assigned to the conformance unit." },
   "conformanceUnits_conformanceGroupGuid": { label: "Conformance Group", help: "The conformance group assigned to the conformance unit." },
   "conformanceUnits_profiles": { label: "Including Profiles", help: "The profiles that include the conformance unit." },
   "conformanceGroups_conformanceUnits": { label: "Conformance Units", help: "The conformance units in the group." },
   "conformanceUnits_showIndirectlyIncluded": { label: "Show Indirectly Included", help: "Show all conformance units that part of the profile or any included profile." },
   "conformanceUnits_outsideDependency": { help: "The profile depends on a conformance unit that has an invalid profile group." },
   "conformanceUnits_testCase": { label: "Test Cases" },
   "conformanceUnits_noTestsFound": { label: "No tests found." },
   "profileFolders_categoryGuid": { label: "Category", help: "The category tree that the folder belongs to." },
   "profileFolders_parentFolderGuid": { label: "Parent Folder", help: "The parent of the folder." },
   "profileFolders_profiles": { label: "Profiles", help: "The profiles in the folder." },
   "profileFolders_children": { label: "Child Folders", help: "The child folders." },
   "profiles_profiles": { label: "Included Profiles", help: "The profiles included in the profile." },
   "profiles_conformanceUnits": { label: "Included Conformance Units", help: "The conformance unit included in the profile." },
   "profiles_folders": { label: "Folders", help: "The folders that the profile appears in." },
   "profiles_categories": { label: "Categories", help: "The categories that the profile belongs to." },
   "profiles_name": { help: "A unique name for the profile." },
   "profiles_description": { help: "The description for the profile." },
   "profiles_profileUri": { label: "Profile URI", help: "A unique identifier for the profile." },
   "profiles_isFacet": { label: "Is Facet", help: "TRUE if the profile is a facet." },
   "profiles_showIndirectlyIncluded": { label: "Show Indirectly Included", help: "Show all profiles that part of the profile or any included profile." },
   "profiles_category": { label: "Category", help: "The category use to filter the profiles." },
   "profiles_directlyIncluded": { label: "Directed Included", help: "Profiles which are directly included." },
   "profiles_outsideDependency": { help: "The profile depends on a profile that has an invalid profile group." },
   "conformanceUnits_isOptional": { label: "Is Optional", help: "The conformance unit is indirectly included via one or more profiles. The optional state depends on the source profile(s)." },
   "profiles_includingProfiles": { label: "Including Profiles", help: "The profiles that directly include current profile." },
   "tags_workingGroups": { label: "Working Groups", help: "The working groups assigned to the tag." },
   "testSuites_children": { label: "Children", help: "The test suites which belong to the test suite." },
   "testSuites_testCases": { label: "Test Cases", help: "The test cases in the test suite." },
   "testSuites_hasTestCases": { label: "Has Test Case", help: "True if the test suite has test cases defined." },
   "testSuites_hasConformanceUnit": { label: "Has Conformance Unit", help: "True if the test suite has a linked conformance unit." },
   "testSuites_conformanceUnitGuid": { label: "Conformance Unit", help: "The conformance unit associated with the test suite." },
   "testSuites_description": { label: "Details", help: "The details of the test suite." },
   "testSuites_keywords": { label: "Keywords", help: "The keywords associated with the test suite." },
   "testCases_version": { label: "Version", help: "The version of the test case." },
   "testCases_executionType": { label: "Execution Type", help: "How the test case is executed." },
   "testCases_executionDuration": { label: "Execution Duration", help: "How long the test case is expected to take." },
   "testCases_importance": { label: "Importance", help: "How important the test case is." },
   "testCases_status": { label: "Status", help: "The current status of the test case." },
   "testCases_description": { label: "Summary", help: "The summary of the test case." },
   "testCases_preconditions": { label: "Pre-conditions", help: "The pre-conditions for the test case." },
   "testCases_keywords": { label: "Keywords", help: "The keywords associated with the test case." },
   "testCases_noStepsFound": { label: "No steps found." },
   "testSteps_description": { label: "Expected Results", help: "The expected results after executing the test step." },
   "testSteps_executionType": { label: "Execution Type", help: "How the test step is executed." },
   "testCases_steps": { label: "Steps", help: "The steps in the test case." },
   "testSteps_actions": { label: "Action", help: "The actions to take when executing the test step." },
   "testSteps_results": { label: "Expected Result", help: "The results after executing the test step." },
   "dialog_caption": { label: "" },
   "dialog_ok": { label: "OK" },
   "dialog_done": { label: "Done" },
   "dialog_cancel": { label: "Cancel", help: "Uploads the selected file to the server." },
   "workingGroups_status": { label: "Status", help: "The current status of the working group." },
   "workingGroups_kickoffDate": { label: "Kickoff Date", help: "When the initial kickoff meeting date." },
   "workingGroups_kickoffSummary": { label: "Kickoff Subject", help: "A summary of kickoff meeting announcement." },
   "workingGroups_kickoffDetails": { label: "Kickoff Invitation", help: "The complete kickoff meeting invitation." },
   "workingGroupStatus_Proposed": { label: "Proposed" },
   "workingGroupStatus_Approved": { label: "Approved" },
   "workingGroupStatus_Active": { label: "Active" },
   "workingGroupStatus_Completed": { label: "Completed" },
   "workingGroupStatus_Inactive": { label: "Inactive" },
   "users_accessGroups": { label: "Access Groups", help: "The Access Groups which the user the belongs to." },
   "users_workinggroups": { label: "Assigned Working Groups", help: "Working Groups which the user may edit." }
};