import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import { Row, Col } from 'reactstrap';
import { TextField, Radio, RadioGroup, FormControlLabel, Switch, Box, Chip, Autocomplete } from '@mui/material';
import { StyledTooltip } from "./Utils.js";

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as Regular from '@fortawesome/free-regular-svg-icons'
import * as Solid from '@fortawesome/free-solid-svg-icons'
import * as Locales from "./locales";
import * as Utils from "./Utils.js";

const DisabledTextColor = "DarkGray";

export const FieldType = {
   Label: 0,
   TextInput: 1,
   CheckBox: 2,
   NumberInput: 3,
   BlockLabel: 4,
   TextArea: 5,
   OptionSet: 6,
   RadioGroup: 7,
   Button: 8,
   ChipArray: 9,
   FileUpload: 10,
   BlockCustom: 11,
   DateTimePicker: 12
}

export const FieldState = {
   Normal: 0,
   Disabled: 1,
   Hidden: -1
}

export const SimpleLink = (props) => {
   const { url, name } = props;
   if (!url?.length) {
      return null;
   }
   return <a
      className='m-0 p-0 nav-link'
      target='_blank'
      rel='noopener noreferrer'
      href={url}>
      {(name?.length) ? name : url.trim()}
   </a>;
}

export const UserName = (props) => {
   const {
      id
   } = props;

   const [user, setUser] = useState(null);
   const mounted = useRef(true);

   useEffect(() => {
      return () => {
         mounted.current = false;
      }
   }, []);

   // fetch
   useEffect(() => {
      fetch(id);
      async function fetch(id) {
         const response = await Utils.httpGet(`api${Utils.PagePaths.Users}get/${id}`);
         if (!mounted.current) {
            return;
         }
         if (response && !response.failed) {
            setUser(response.result);
         }
         else {
            setUser({});
         }
      }
   }, [id]);

   if (!user) {
      return <span>{Locales.getDisplayText('Unknown')}</span>;
   }

   return <span>{user.name}</span>;
}

export const LinkField = (props) => {

   const {
      noLinks,
      path,
      returnUrl,
      id,
      displayText,
      item,
      location,
      noExternalLink
   } = props;

   if (noLinks || !id || id <= 0) {
      return (displayText) ? displayText : null;
   }

   if (item?.profileGroupId && !noExternalLink) {
      if (Utils.getCurrentUser()?.profileGroupId !== item?.profileGroupId) {
         let pg = Utils.findInCache(Utils.PagePaths.ProfileGroups, 0, item.profileGroupId);
         const linkText = (pg) ? `${displayText} [${pg.fullName}]` : displayText;
         return(<span>
            <a
               href={(path ?? "") + id}
               target="_blank"
               rel="noreferrer noopener"
            >
               {linkText}
            </a>
            <FontAwesomeIcon
               icon={Solid.faExternalLinkAlt}
               style={{ marginLeft: '2px', marginBottom: '2px', height: '0.8em' }}
               onClick={(e) => e.preventDefault()}
            />
         </span>);
      }
   }

   const pathname = returnUrl ?? location?.pathname;

   return <Link
      to={{
         pathname: (path ?? "") + id,
         state: {
            url: pathname
         }
      }}
      className='p-0 m-0'>{displayText}
   </Link>;
}

export const IconField = (props) => {
   const { value, onClick, trueState, falseState, helpText, color } = props;
   if (helpText) {
      return <StyledTooltip  title={helpText}>
         <span>
            <FontAwesomeIcon
               icon={(value) ? (trueState ?? Regular.faCheckSquare) : (falseState ?? Regular.faSquare)}
               style={{ marginLeft: '0px' }}
               color={color}
               onClick={onClick}
            />
         </span>
      </StyledTooltip>;
   }
   return <FontAwesomeIcon
      icon={(value) ? (trueState ?? Regular.faCheckSquare) : (falseState ?? Regular.faSquare)}
      style={{ marginLeft: '0px' }}
      color={color}
      onClick={onClick}
   />;
}

export const CheckBoxField = (props) => {
   const { value, onClick } = props;
   return <FontAwesomeIcon
      icon={(value) ? Regular.faCheckSquare : Regular.faSquare}
      style={{ marginLeft: '0px' }}
      onClick={onClick}
   />;
}

export function renderFieldValue(context, name, item, field, path) {
   switch (name) {
      case Utils.FieldNames.Name: {
         return <LinkField
            id={item.id}
            item={item}
            displayText={item.name}
            path={path}
            location={context.location}
            noLink={context.noLink}
            noExternalLink={context.noExternalLink}
         />;
      }
      case Utils.FieldNames.LastUpdatingUserId: {
         return <UserName id={item.lastUpdatingUserId} />;
      }

      case Utils.FieldNames.LastUpdateTime: {
         if (!item.lastUpdateTime) {
            return null;
         }
         return Utils.getDateAsString(new Date(item.lastUpdateTime));
      }
      case Utils.FieldNames.ReleaseStatus: {
         return Utils.toString(Utils.ReleaseStatus, item.releaseStatus, "releaseStatus");
      }
      case Utils.FieldNames.ProfileGroupId: {
         let pg = Utils.findInCache(Utils.PagePaths.ProfileGroups, 0, item.profileGroupId);
         return (pg) ? pg.fullName : item.profileGroupId;
      }
      case Utils.FieldNames.InlineDescription: {
         let html = Utils.sanitizeString(item.description);
         if (item.releaseStatus && item.releaseStatus !== Utils.ReleaseStatus.Released) {
            const status = Utils.toString(Utils.ReleaseStatus, item.releaseStatus, "releaseStatus");
            html = `<span class='text-primary fw-bold'>[${status}]</span>&nbsp;${html}`;
         }
         return <label
            style={{ lineHeight: 1.2 }}
            className="pt-1 pb-0 pe-1 fluid w-100"
            dangerouslySetInnerHTML={{ __html: html }}>
         </label>
      }
      default: {
         return null;
      }
   }
}

function getFormFields(context, definitions, item, isViewer) {
   let count = 0;
   let fields = [];

   definitions.map((ii) => {
      const user = Utils.getCurrentUser();
      if (ii.editorOnly && (!user.name || !user?.isEditor)) {
         return false;
      }

      let disabled = ((context.isDisabled) ? context.isDisabled(ii, item) : !context.editingAllowed);

      if (context.fieldStates) {
         const state = context.fieldStates[ii.name];
         if (state) {
            if (state === FieldState.Hidden) {
               return false;
            }
            disabled = true;
         }
      }

      switch (((isViewer) ? ii.viewControlType : ii.editControlType)) {
         default:
         case FieldType.Label: {
            fields.push(<LabelRow
               key={count++}
               name={ii.name}
               displayText={Locales.getDisplayText(context.pageName + "_" + ii.name)}
               helpText={Locales.getHelpText(context.pageName + "_" + ii.name)}
               value={((context.renderFieldValue) ? context.renderFieldValue(ii, item) : ((item) ? item[ii.name] : ""))}
               isOddRow={(isViewer) ? (count % 2 === 0) : undefined}
            />);
            break;
         }
         case FieldType.ChipArray: {
            fields.push(<ChipLabelRow
               key={count++}
               name={ii.name}
               displayText={Locales.getDisplayText(context.pageName + "_" + ii.name)}
               helpText={Locales.getHelpText(context.pageName + "_" + ii.name)}
               value={((context.renderFieldValue) ? context.renderFieldValue(ii, item) : ((item) ? item[ii.name] : ""))}
               isOddRow={(isViewer) ? (count % 2 === 0) : undefined}
            />);
            break;
         }
         case FieldType.BlockLabel: {
            fields.push(<BlockLabelRow
               key={count++}
               name={ii.name}
               showLabel={ii.showLabel}
               displayText={(ii.showLabel) ? Locales.getDisplayText(context.pageName + "_" + ii.name) : ""}
               helpText={(ii.showLabel) ? Locales.getHelpText(context.pageName + "_" + ii.name) : ""}
               value={((context.renderFieldValue) ? context.renderFieldValue(ii, item) : ((item) ? item[ii.name] : ""))}
               isOddRow={(isViewer) ? (count % 2 === 0) : undefined}
            />);
            break;
         }
         case FieldType.BlockCustom: {
            fields.push(<BlockLabelRow
               key={count++}
               valueIsRaw={true}
               name={ii.name}
               showLabel={ii.showLabel}
               displayText={(ii.showLabel) ? Locales.getDisplayText(context.pageName + "_" + ii.name) : ""}
               helpText={(ii.showLabel) ? Locales.getHelpText(context.pageName + "_" + ii.name) : ""}
               value={((context.renderFieldValue) ? context.renderFieldValue(ii, item) : ((item) ? item[ii.name] : ""))}
               isOddRow={(isViewer) ? (count % 2 === 0) : undefined}
            />);
            break;
         }
         case FieldType.TextInput: {
            fields.push(<TextInputRow
               key={count++}
               name={ii.name}
               displayText={Locales.getDisplayText(context.pageName + "_" + ii.name)}
               helpText={Locales.getHelpText(context.pageName + "_" + ii.name)}
               value={item[ii.name]}
               onChange={context.onTextChanged}
               width={ii.maxTextLength ?? 60}
               disabled={disabled}
               placeholder={ii.placeholder}
            />);
            break;
         }
         case FieldType.NumberInput: {
            fields.push(<NumberInputRow
               key={count++}
               name={ii.name}
               displayText={Locales.getDisplayText(context.pageName + "_" + ii.name)}
               helpText={Locales.getHelpText(context.pageName + "_" + ii.name)}
               value={item[ii.name]}
               onChange={context.onNumberChanged}
               disabled={disabled}
               min={ii.minValue}
               max={ii.maxValue}
            />);
            break;
         }
         case FieldType.CheckBox: {
            fields.push(<CheckBoxRow
               key={count++}
               name={ii.name}
               displayText={Locales.getDisplayText(context.pageName + "_" + ii.name)}
               helpText={Locales.getHelpText(context.pageName + "_" + ii.name)}
               value={item[ii.name]}
               onChange={context.onCheckChanged}
               disabled={disabled}
            />);
            break;
         }
         case FieldType.TextArea: {
            fields.push(<TextAreaRow
               key={count++}
               name={ii.name}
               displayText={Locales.getDisplayText(context.pageName + "_" + ii.name)}
               helpText={Locales.getHelpText(context.pageName + "_" + ii.name)}
               value={item[ii.name]}
               onChange={context.onTextChanged}
               disabled={disabled}
               rows={context.rows}
            />);
            break;
         }
         case FieldType.OptionSet: {
            fields.push(<OptionSetRow
               key={count++}
               name={ii.name}
               displayText={Locales.getDisplayText(context.pageName + "_" + ii.name)}
               helpText={Locales.getHelpText(context.pageName + "_" + ii.name)}
               value={item[ii.name]}
               onChange={context.onSelectChanged}
               disabled={disabled}
               items={((context.fieldOptions) ? context.fieldOptions[ii.name] : [])}
            />);
            break;
         }
         case FieldType.RadioGroup: {
            fields.push(<RadioGroupRow
               key={count++}
               name={ii.name}
               displayText={Locales.getDisplayText(context.pageName + "_" + ii.name)}
               helpText={Locales.getHelpText(context.pageName + "_" + ii.name)}
               value={item[ii.name]}
               onChange={context.onRadioChanged}
               disabled={disabled}
               items={((context.fieldOptions) ? context.fieldOptions[ii.name] : [])}
            />);
            break;
         }
         case FieldType.FileUpload : {
            fields.push(<FileUploadRow
               key={count++}
               name={ii.name}
               displayText={Locales.getDisplayText(context.pageName + "_" + ii.name)}
               helpText={Locales.getHelpText(context.pageName + "_" + ii.name)}
               value={item[ii.name]}
               onChange={context.onFileChanged}
               disabled={disabled}
               items={((context.fieldOptions) ? context.fieldOptions[ii.name] : [])}
            />);
            break;
         }
         case FieldType.DateTimePicker: {
            fields.push(<DateTimePickerRow
               key={count++}
               name={ii.name}
               displayText={Locales.getDisplayText(context.pageName + "_" + ii.name)}
               helpText={Locales.getHelpText(context.pageName + "_" + ii.name)}
               value={item[ii.name]}
               onChange={context.onDateTimeChanged}
               disabled={disabled}
            />);
            break;
         }
      }
      return true;
   }); 

   return fields;
}

export function getViewerFields(context, definitions, item) {
   return getFormFields(context, definitions, item, true);
}

export function getEditorFields(context, definitions, item) {
   return getFormFields(context, definitions, item, false);
}

export function ErrorRow(props) {
   const color = (props.failed !== undefined && !props.failed) ? "opc-green" : "opc-red";
   const errorCode = props.item?.errorCode;
   const errorText = props.item?.errorText;
   const errorUri = props.item?.errorUri;

   let text = null;
   if (errorText?.length) {
      text = <LabelRow
         name="errorText"
         displayText={Locales.getDisplayText("errorText")}
         helpText={Locales.getHelpText("errorText")}
         value={errorText}
      />;
   }

   let details = null;
   if (errorUri?.length) {
      details = <LabelRow
         name="errorDetailsLink"
         displayText={Locales.getDisplayText("errorDetailsLink")}
         helpText={Locales.getHelpText("errorDetailsLink")}
         value={<a className='m-0 p-0 nav-link' target='_blank' rel='noopener noreferrer' href={props.item.errorUri}>{props.item.errorUri}</a>}
      />
   }

   return (
      <Row className="m-0 p-0">
         <Box
            boxShadow={2}
            bgcolor="background.paper"
            className={`${color} p-1 w-100`}
         >
            <LabelRow
               name="errorCode"
               displayText={Locales.getDisplayText("errorCode")}
               helpText={Locales.getHelpText("errorCode")}
               value={errorCode}
            />
            {text}
            {details}
         </Box>
      </Row>);
}

export function LabelRow(props) {
   let className = ((props.isOddRow !== undefined) ? ((props.isOddRow) ? 'opc-blue' : 'opc-grey') : "");

   if (props.rowClassName) {
      className = props.rowClassName;
   }

   return (
      <Row className={"p-0 m-0 pt-1 " + className}>
         <Col xs="12" lg="2" className="p-0 ps-1 m-0">
            <StyledTooltip  title={props.helpText??"No help available"}>
               <label className="fw-bold p-0">{props.displayText}</label>
            </StyledTooltip>
         </Col>
         <Col xs="12" lg="8" className="p-0 ps-1 m-0">
            <label className="p-0">
               {props.value}
            </label>
         </Col>
      </Row>);
}

export function ChipLabelRow(props) {
   const {
      value,
      isOddRow,
      rowClassName,
      displayText,
      helpText
   } = props;

   let className = ((isOddRow !== undefined) ? ((isOddRow) ? 'opc-blue' : 'opc-grey') : "");

   if (rowClassName) {
      className = rowClassName;
   }

   var fields = (value) ? value.split(';') : [];

   return (
      <Row className={"p-0 m-0 pt-1 " + className}>
         <Col xs="12" lg="2" className="p-0 ps-1 m-0">
            <StyledTooltip title={helpText ?? "No help available"}>
               <label className="fw-bold p-0">{displayText}</label>
            </StyledTooltip>
         </Col>
         <Col xs="auto" className="p-0 ps-1 m-0">
               {fields.map(ii => {
                  if (ii?.length) {
                     return <Chip key={ii} label={ii} color="primary" size="small" className="me-2" />
                  }
                  return null;
               })}
         </Col>
      </Row>);
}

export function DateTimePickerRow(props) {
   const {
      name,
      value,
      isOddRow,
      rowClassName,
      displayText,
      helpText
   } = props;

   let className = ((isOddRow !== undefined) ? ((isOddRow) ? 'opc-blue' : 'opc-grey') : "");

   if (rowClassName) {
      className = rowClassName;
   }

   return (
      <Row className={"p-0 m-0 pt-1 " + className}>
         <Col xs="12" lg="2" className="p-0 ps-1 m-0">
            <StyledTooltip  title={helpText ?? "No help available"}>
               <label className="fw-bold p-0">{displayText}</label>
            </StyledTooltip>
         </Col>
         <Col xs="auto" className="p-0 ps-1 m-0">
            <LocalizationProvider dateAdapter={AdapterDateFns}>
               <DesktopDatePicker
                  label=""
                  value={value}
                  renderInput={(params) =>
                     <TextField
                        variant="standard"
                        autoComplete="off"
                        {...params}
                     />}
                  onChange={(value, directInputValue) => props.onChange({ name: name, value, directInputValue })}
                  disabled={props.disabled ?? undefined}
                  shouldDisableTime={() => true}
                  showTodayButton={true}
                  inputFormat={"yyyy-MM-dd"}
                  mask={"____-__-__"}
                />
            </LocalizationProvider>
         </Col>
      </Row>);
}

export function BlockLabelRow(props) {

   const {
      value,
      isOddRow,
      rowClassName,
      displayText,
      helpText,
      showLabel,
      valueIsRaw
   } = props;

   if (!valueIsRaw && !value?.length) {
      return null;
   }

   let className = ((isOddRow !== undefined) ? ((isOddRow) ? 'opc-blue' : 'opc-grey') : "");

   if (rowClassName) {
      className = rowClassName;
   }

   const label = (!showLabel) ? null : <Col xs="12" className="p-0 ps-1 m-0">
      <StyledTooltip  title={helpText}>
         <label className="fw-bold p-0">{displayText}</label>
      </StyledTooltip>
   </Col>;

   const adjustment1 = (label) ? "pt-1" : "";
   const adjustment2 = (label) ? "pt-0" : "mt-1";

   const body = (valueIsRaw) ? value :
      <label
         style={{ lineHeight: 1.3 }}
         className="p-2 m-0 opc-box-rounded opc-grey fluid w-100"
         dangerouslySetInnerHTML={{ __html: Utils.sanitizeString(props.value) }}>
      </label>

   return (
      <Row className={`p-0 ${adjustment1} m-0 ${className}`}>
         {label}
         <Col xs="12" className={`p-0 ps-1 pe-1 ${adjustment2} m-0 mb-1`}>
            {body}
         </Col>
      </Row>);
}

const ToggleSelectExisting = (props) => {
   if (props.onToggleChange) {
      return <Col xs="1" className="p-0 ps-1 m-0">
         <StyledTooltip  title={props.helpText}>
            <Switch
               checked={props.toggleValue}
               onChange={props.onToggleChange}
               name={props.toggleName}
               color="primary"
            />
         </StyledTooltip>
      </Col>
   }
   return (null);
}

function getClassName(props) {
   let className = ((props.isOddRow) ? 'opc-blue' : '');
   if (props.rowClassName) {
      className = " " + props.rowClassName;
   }
   return className;
}

export function TextInputRow(props) {
   const value = props.value ?? "";
   const disabled = (props.disabled) ? props.disabled : undefined;
   const className = getClassName(props);

   if (value === undefined || value === null) {
      console.error(props.name + " has undefined value");
   }

   const textColor = (disabled) ? DisabledTextColor : "initial";

   return (
      <Row className={"p-0 m-0 mt-1" + className}>
         <Col xs="12" lg="2" className="p-0 ps-1 m-0">
            <StyledTooltip  title={props.helpText}>
               <label style={{ color: textColor }} className="fw-bold p-0">{props.displayText}</label>
            </StyledTooltip>
         </Col>
         <Col xs="auto" className="p-0 ps-1 m-0">
            <TextField
               label=""
               variant="standard"
               name={props.name}
               type="text"
               value={value}
               onChange={props.onChange}
               style={{ width: ((props.width) ? props.width : 40) + 'ch' }}
               disabled={disabled}
               autoComplete="off"
               placeholder={props.placeholder}
            />
         </Col>
         <ToggleSelectExisting
            {...props}
            helpText={Locales.getHelpText(props.id + "_toggleSelectExisting")}
         />
      </Row>);
}

export function NumberInputRow(props) {
   const className = getClassName(props);

   if (props.value === undefined) {
      console.error(props.name + " has undefined value");
   }

   const disabled = (props.disabled) ? props.disabled : undefined;
   const textColor = (disabled) ? DisabledTextColor : "initial";

   return (
      <Row className={"p-0 m-0 mt-1" + className}>
         <Col xs="12" lg="2" className="p-0 ps-1 m-0">
            <StyledTooltip  title={props.helpText}>
               <label style={{ color: textColor }} className="fw-bold p-0">{props.displayText}</label>
            </StyledTooltip>
         </Col>
         <Col xs="auto" className="p-0 ps-1 m-0">
            <TextField
               label=""
               variant="standard"
               name={props.name}
               type="number"
               value={props.value}
               onChange={(e, param) => props.onChange(e, props)}
               style={{ width: ((props.width) ? props.width : 20) + 'ch' }}
               disabled={disabled}
               autoComplete="off"
            />
         </Col>
      </Row>);
}

export function TextAreaRow(props) {
   const value = (props.value?.length) ? props.value : " ";
   const textColor = (props.disabled) ? DisabledTextColor : "initial";
   const className = getClassName(props);

   return (
      <Row className={"p-0 m-0 mt-3" + className}>
         <Col xs="12" className="p-1 m-0">
            <TextField
               variant="outlined"
               fullWidth
               multiline
               helperText={props.helpText}
               label={
                  <span className="fw-bold lh-1" style={{ color: textColor }}>{props.displayText}</span>
               }
               name={props.name}
               minRows={(props.rows) ? props.rows : 6}
               type="text"
               value={value}
               onChange={props.onChange}
               disabled={props.disabled ?? undefined}
               autoComplete="off"
            />
         </Col>
      </Row>);
}

export function OptionSetRow(props) {
   const className = getClassName(props);

   if (props.value === undefined) {
      console.error(props.name + " has undefined value");
   }

   let options = [];
   const notAvailable = { id: -1, name: "" };

   if (props.items) {
      options = props.items;
   }

   if (!options || options.length === 0) {
      options = [notAvailable];
   }

   let width = 1;

   options.map((ii) => {
      if (ii.name.length > width) width = ii.name.length;
      return true;
   });

   width = Math.max(40, (Math.round(width / 10) * 10));

   let value = null;

   if (props.value) {
      value = options.find((ii) => ii.id.toString() === props.value.toString());
   }

   if (!value) {
      value = options.find((ii) => ii.id === -1);
      if (value === undefined) {
         options = [notAvailable, ...options];
      }
      value = notAvailable;
   }

   function isOptionEqualToValue(a, b) {
      if (!b) {
         if (a.id === -1) {
            return true;
         }
      }
      if (a.id.toString() === b.id.toString()) {
         return true;
      }
      return false;
   }

   const disabled = (props.disabled) ? props.disabled : undefined;
   const textColor = (disabled) ? DisabledTextColor : "initial";

   return (
      <Row className={"p-0 m-0 mt-1" + className}>
         <Col xs="12" lg="2" className="p-0 ps-1 m-0">
            <StyledTooltip  title={props.helpText}>
               <label style={{ color: textColor }} className="fw-bold p-0">{props.displayText}</label>
            </StyledTooltip>
         </Col>
         <Col xs="auto" className="p-0 ps-1 m-0">
            <Autocomplete
               id={props.name}
               value={value}
               options={options}
               autoComplete
               includeInputInList
               autoSelect
               getOptionLabel={(option) => option.name.toString()}
               isOptionEqualToValue={isOptionEqualToValue}
               style={{ width: width + 'ch' }}
               onChange={props.onChange}
               disabled={disabled}
               renderInput={(params) => <TextField {...params} label="" variant="standard" />}
            />
         </Col>
         <ToggleSelectExisting {...props} />
      </Row>);
}

export function FileUploadRow(props) {

   const className = getClassName(props);
   const disabled = (props.disabled) ? props.disabled : undefined;
   const textColor = (disabled) ? DisabledTextColor : "initial";
   const onChange = props.onChange ?? ((e) => e.preventDefault());

   return (
      <Row className={"p-0 m-0 mt-1" + className}>
         <Col xs="12" lg="2" className="p-0 ps-1 m-0">
            <StyledTooltip  title={props.helpText}>
               <label style={{ color: textColor }} className="fw-bold mt-2 p-0">{props.displayText}</label>
            </StyledTooltip>
         </Col>
         <Col xs="auto" className="p-0 ps-1 m-0">
            <div className="opc-upload">
               <label htmlFor="upload">Select...</label>
               <input type="file" id="upload" name={props.name} onChange={onChange} />
               <span className="ms-1">{(props.value?.name) ?? ""}</span>
            </div>
         </Col>
      </Row>);
}

export function RadioGroupRow(props) {
   const className = getClassName(props);

   let options = [];

   if (props.items) {
      options = props.items;
   }

   if (props.value === undefined) {
      console.error(props.name + " has undefined value");
   }

   const disabled = (props.disabled) ? props.disabled : undefined;
   const textColor = (disabled) ? DisabledTextColor : "initial";

   return (
      <Row className={"p-0 m-0 mt-1" + className}>
         <Col xs="12" lg="2" className="p-0 ps-1 m-0">
            <StyledTooltip  title={props.helpText}>
               <label style={{ color: textColor }} className="fw-bold p-0">{props.displayText}</label>
            </StyledTooltip>
         </Col>
         <Col xs="auto" className="p-0 ps-1 m-0">
            <RadioGroup name={props.name} value={props.value.toString()} style={{ flexFlow: 'row nowrap' }} onChange={props.onChange}>
               {options.map((ii) => {
                  return <FormControlLabel
                     className="p-0 m-0 pt-1 pb-1 me-1"
                     key={ii.id}
                     value={ii.id.toString()}
                     control={<Radio className="p-0 m-0" disabled={disabled} />}
                     label={ii.name}
                  />
               })}
            </RadioGroup>
         </Col>
      </Row>);
}

export function CheckBoxRow(props) {
   const className = getClassName(props);

   if (props.value === undefined) {
      console.error(props.name + " has undefined value");
   }

   const disabled = (props.disabled) ? props.disabled : undefined;
   const textColor = (disabled) ? DisabledTextColor : "initial";

   return (
      <Row className={"p-0 m-0 mt-1" + className}>
         <Col xs="12" lg="2" className="p-0 ps-1 m-0">
            <StyledTooltip  title={props.helpText}>
               <label style={{ color: textColor }} className="fw-bold p-0">{props.displayText}</label>
            </StyledTooltip>
         </Col>
         <Col xs="auto" className="p-0 ps-1 pt-1 pb-1 m-0">
            <input
               type="checkbox"
               name={props.name}
               checked={props.value}
               onChange={props.onChange}
               disabled={disabled}
            />
         </Col>
      </Row>);
}

export function ButtonRow(props) {
   const [submitText, setSubmitText] = useState("updateButton");

   useEffect(() => {
      setSubmitText((props.newRecord) ? "createButton" : "updateButton");
   }, [props.newRecord]);

   return (
      <Row className="p-0 m-0 mt-1">
         <Col xs="12" className="p-0 ps-1 m-0 opc-button-row">
            <StyledTooltip  title={Locales.getHelpText(`${props.containerName}_${submitText}`)}>
               <input
                  className="btn btn-sm opc-dark-blue"
                  style={{ minWidth: '100px', marginRight: '4px' }}
                  type="submit"
                  value={Locales.getDisplayText(`${props.containerName}_${submitText}`)}
                  disabled={props.disabled}
               />
            </StyledTooltip>
            <StyledTooltip  title={Locales.getHelpText(`${props.containerName}_resetButton`)}>
               <input
                  className="btn btn-sm opc-grey"
                  style={{ minWidth: '100px' }}
                  type="reset"
                  value={Locales.getDisplayText(`${props.containerName}_resetButton`)}
                  disabled={props.disabled}
               />
            </StyledTooltip>
         </Col>
      </Row>);
}
