import React, { useCallback } from "react";
import { useLocation } from "react-router-dom";

import * as FormRow from "./FormRow.js";
import * as Utils from "./Utils.js";
import * as Locales from "./locales";
import { MultiSelectList } from "./MultiSelectList";
import { SingleItemPage } from "./SingleItemPage";
import { WorkingGroupList } from "./WorkingGroupPage";
import { DocumentList } from "./DocumentPage";

const PageCacheName = Utils.PageNames.Tags;
const PageApiPath = Utils.PagePaths.Tags;
const PageName = PageCacheName;

export const FieldNames = {
   Name: Utils.FieldNames.Name,
   Type: "type",
   Url: "url",
   Description: Utils.FieldNames.Description,
   InlineDescription: Utils.FieldNames.InlineDescription
};

export const ChildListNames = {
   WorkingGroups: "workingGroups"
};

const FieldDefinitions = [
   {
      name: FieldNames.Name,
      defaultValue: "",
      viewControlType: FormRow.FieldType.Label,
      editControlType: FormRow.FieldType.TextInput,
      maxTextLength: 40
   },
   {
      name: FieldNames.Type,
      defaultValue: Utils.TagType.Classification,
      viewControlType: FormRow.FieldType.Label,
      editControlType: FormRow.FieldType.OptionSet
   },
   {
      name: FieldNames.Url,
      defaultValue: null,
      viewControlType: FormRow.FieldType.Label,
      editControlType: FormRow.FieldType.TextInput,
      maxTextLength: 60
   },
   {
      name: FieldNames.Description,
      defaultValue: "",
      viewControlType: FormRow.FieldType.BlockLabel,
      editControlType: FormRow.FieldType.TextArea
   }
];

const FilterDefinitions = [
   {
      name: FieldNames.Name,
      defaultValue: "",
      editControlType: FormRow.FieldType.TextInput,
      maxTextLength: 40
   },
   {
      name: FieldNames.Type,
      defaultValue: Utils.TagType.Classification,
      editControlType: FormRow.FieldType.OptionSet
   }
];

const ChildDefinitions = [
   {
      name: ChildListNames.WorkingGroups,
      url: "/tag/workinggroups/",
      shouldRenderList: (props, parent) => (parent.Type === Utils.TagType.Classification),
      renderList: (props) => <WorkingGroupList {...props} />
   },
   {
      name: ChildListNames.d,
      url: "/tag/documents/",
      shouldRenderList: (props, parent) => (parent.Type === Utils.TagType.Namespace),
      renderList: (props) => <DocumentList {...props} />
   },
];

function renderFieldValue(context, name, item, field) {
   switch (name) {
      case FieldNames.Type: {
         const name = Object.keys(Utils.TagType).find((ii) => Utils.TagType[ii] === item.type);
         return Locales.getDisplayText(`tagType_${name}`);
      }
      case FieldNames.Url: {
         return <FormRow.SimpleLink url={item?.url} />;
      }
      case FieldNames.InlineDescription: {
         if (!item?.description?.length) {
            return <FormRow.SimpleLink url={item?.url} />;
         }
         return FormRow.renderFieldValue(context, name, item, field, Utils.PagePaths.Tags);
      }
      default: {
         const value = FormRow.renderFieldValue(context, name, item, field, Utils.PagePaths.Tags);
         if (value) {
            return value;
         }
         return item[name];
      }
   }
}

function toJson(item) {
   return {
      id: item.id,
      name: item.name,
      type: item.type,
      url: item.url,
      description: item.description
   }
}

function initializeNewItem(props, newItem) {
   const {
      item,
      filters
   } = props;
   if (filters?.type) newItem.type = filters.type;
   if (item?.name) newItem.name = `${item.name} (Copy)`;
   if (item?.type) newItem.type = item.type;
   if (item?.url) newItem.url = item.url;
}

function getEditorOptions(props, item) {
   const tagTypes = Object.keys(Utils.TagType).map((ii) => {
      return {
         id: Utils.TagType[ii],
         name: Locales.getDisplayText(`tagType_${ii}`)
      };
   });
   return {
      [FieldNames.Type]: tagTypes
   };
};

function getEditorStates(props, item) {
   return {
      [FieldNames.Type]: (item?.new) ? FormRow.FieldState.Normal : FormRow.FieldState.Disabled
   };
};

export const TagList = (props) => {
   const {
      parent,
      url,
      type,
      containerName,
      storageKey
   } = props;

   const query = new URLSearchParams(useLocation().search);
   const typeInQuery = query.get("type");

   const updateInitialFilter = useCallback((context, filters) => {
      filters.name = "";
      filters.type = (type) ? type : ((typeInQuery) ?? Utils.TagType.Classification);
      if (context) {
         filters.name = "";
         filters.type = context.type;
      }
      filters.exclude = [
         Utils.FieldNames.ProfileGroupId
      ];
      filters.unsetDataFetched = [
         FieldNames.Type
      ];
   }, [typeInQuery, type]);

   const updateUrlToUse = useCallback((props, urlToUse) => {
      const {
         filters,
         inListEditMode
      } = props;
      const typeToUse = (type) ? type : ((typeInQuery) ? typeInQuery: filters.type);
      if (inListEditMode) {
         return `${urlToUse}?type=${typeToUse}`;
      }
      return `${urlToUse}?type=${typeToUse}`;
   }, [type, typeInQuery]);

   const columns = [{ name: FieldNames.InlineDescription, width: "8" }];
   const sortColumn = { name: FieldNames.Sort };

   return (
      <MultiSelectList
         {...props}
         pageName={PageName}
         containerName={containerName}
         storageKey={storageKey}
         parent={parent}
         url={url}
         pathApiUrl={PageApiPath}
         updateUrlToUse={updateUrlToUse}
         fieldDefinitions={FieldDefinitions}
         toJson={toJson}
         renderFieldValue={renderFieldValue}
         initializeNewItem={initializeNewItem}
         getEditorOptions={getEditorOptions}
         filterDefinitions={FilterDefinitions}
         updateInitialFilter={updateInitialFilter}
         getFilterEditorOptions={getEditorOptions}
         columns={columns}
         sortColumn={sortColumn}
         enableEditSort={true}
      />);
}

export const TagPage = (props) => {

   const {
      parent,
      url,
      containerName,
      storageKey
   } = props;

   return (
      <SingleItemPage
         {...props}
         pageName={PageName}
         pathApiUrl={PageApiPath}
         containerName={containerName}
         storageKey={storageKey}
         parent={parent}
         url={url}
         fieldDefinitions={FieldDefinitions}
         toJson={toJson}
         childDefinitions={ChildDefinitions}
         renderFieldValue={renderFieldValue}
         getEditorStates={getEditorStates}
         getEditorOptions={getEditorOptions}
         initializeNewItem={initializeNewItem}
      />);
}
