import { Link } from "react-router-dom";
import Button from '@mui/material/Button';

import * as Locales from "./locales";
import * as Utils from "./Utils.js";

export const ProfileExport = (props) => {
   const { item, caption, fileType } = props;
   const user = Utils.getCurrentUser();
   const releaseStatus = ((user?.releaseStatus) ? `?rs=${user?.releaseStatus}` : "");

   return (
      <div className="d-inline border-0 m-0 p-0">
         <Utils.StyledTooltip title={(caption) ? Locales.getHelpText(caption) : ""}>
            <Link
               to={`/api/profilegroup/export/${fileType}/${item?.id}${releaseStatus}`}
               target="_blank"
               download
            >
               <Button color="info">
                  {(caption) ? Locales.getDisplayText(caption) : "Download"}
               </Button>
            </Link>
         </Utils.StyledTooltip>
      </div>);
}
