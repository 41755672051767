import React, { useState,  useCallback, useEffect } from "react";
import { MessageBox } from "./MessageBox";
import Button from '@mui/material/Button';

import * as Locales from "./locales";
import * as Utils from "./Utils.js";
import * as FormRow from "./FormRow.js";
import * as ItemForm from "./ItemForm";

const FieldDefinitions = [
   {
      name: "file",
      defaultValue: null,
      viewControlType: FormRow.FieldType.Label,
      editControlType: FormRow.FieldType.FileUpload
   }
];

function renderFieldValue(props, name, item, field) {
   switch (name) {
      default: {
         return item[name];
      }
   }
}

export const FileUpload = (props) => {
   const {
      caption,
      dialogYesText,
      dialogNoText,
      buttonText,
      pageName,
      containerName,
      toJson,
      uploadUrl,
      onCompleted,
      definitions
   } = props;

   const [showDialog, setShowDialog] = useState(false);
   const [item, setItem] = useState({});
   const [uploadStarted, setUploadStarted] = useState(false);
   const [uploadSucceeded, setUploadSucceeded] = useState(null);
   const file = item?.file;

   useEffect(() => {
      let defaultValue = {};
      if (definitions) {
         definitions.forEach(ii => defaultValue[ii.name] = ii.defaultValue);
      }
      setItem(oldItem => {
         return { ...oldItem, ...defaultValue }
      });
   }, [definitions]);

   useEffect(() => {
      if (!showDialog) {
         setUploadStarted(false);
         setUploadSucceeded(null);
         setItem(oldItem => {
            return { ...oldItem, file: null }
         });
      }
   }, [showDialog]);

   useEffect(() => {
      if (uploadSucceeded) {
         setShowDialog(false);
         if (onCompleted) {
            onCompleted(uploadSucceeded);
         }
      }
   }, [uploadSucceeded, onCompleted]);

   const updateItem = useCallback(async (item) => {
      setItem(item);
   }, []);

   const renderExpanded = useCallback((context) => {
      return (<ItemForm.ItemForm
         {...context}
         pageName={containerName ?? pageName}
         definitions={(definitions) ? [...definitions, ...FieldDefinitions] : FieldDefinitions}
         renderFieldValue={renderFieldValue}
         toJson={toJson}
         onItemUpdated={updateItem}
         anyoneCanEdit={true}
         editingEnabled={true}
         noButtons={true}
      />);
   }, [containerName, pageName, updateItem, toJson, definitions]);

   const onCancel = useCallback((e) => {
      e.preventDefault();
      setShowDialog(false);
   }, []);

   const onClick = useCallback(async (e) => {
      e.preventDefault();
      setItem({
         ...item,
         errorCode: null,
         errorText: null
      });
      setShowDialog(true);
   }, [item]);

   const onOk = useCallback(async (e) => {
      e.preventDefault();
      if (file && !uploadStarted) {
         setUploadStarted(true);
         const data = new FormData();
         data.append('File', file);
         let params = "";
         let first = true;
         if (definitions) {
            definitions.forEach((ii) => {
               if (item[ii.name]) {
                  params += (first) ? '?' : '&';
                  params += `${ii.name}=${item[ii.name]}`;
                  first = false;
               }
            });
         }
         setItem(oldItem => {
            return {
               ...oldItem,
               errorCode: "Processing...",
               errorText: null,
               failed: false
            }
         });
         await Utils.httpUpload(
            uploadUrl,
            params,
            file,
            (result) => {
               if (result) {
                  setItem(oldItem => {
                     if (!result.errorCode && !result?.incomplete) {
                        setUploadSucceeded(result.result);
                     }
                     return {
                        ...oldItem,
                        errorCode: result.errorCode,
                        errorText: result.errorText,
                        failed: (result?.failed && !result?.incomplete) ? true : false
                     }
                  });
               }
            });
      }
   }, [file, uploadStarted, uploadUrl, item, definitions]);

   return (
      <div className="d-inline border-0 m-0 p-0">
         <Utils.StyledTooltip title={(caption) ? Locales.getHelpText(caption) : ""}>
            <Button onClick={(e) => onClick(e)} color="info">
               {buttonText ?? Locales.getDisplayText(caption)}
            </Button>
         </Utils.StyledTooltip>
         <MessageBox
            caption={(caption) ? Locales.getDisplayText(caption) : undefined}
            okText={(dialogYesText) ? Locales.getDisplayText(dialogYesText) : undefined}
            cancelText={(dialogNoText) ? Locales.getDisplayText(dialogNoText) : undefined}
            okDisabled={uploadStarted}
            show={showDialog}
            item={item}
            render={renderExpanded}
            onOk={onOk}
            onCancel={onCancel}
         />
      </div>);
}

/*
<StyledDialog
   open={showDialog}
   onClose={onCancel}
   aria-labelledby="alert-dialog-title"
   aria-describedby="alert-dialog-description"
>
   <DialogTitle id="alert-dialog-title" className="fw-bold">{caption}</DialogTitle>
   <DialogContent id="alert-dialog-description">
      <Container className="fluid border-0 m-0 p-0">
         <Box
            boxShadow={2}
            bgcolor="background.paper"
            className="m-0 p-0 mt-1 w-100"
         >
            <Row className="m-0 fw-bold opc-green" style={{ marginLeft: '-1px', marginRight: '-1px' }}>
               <Col
                  xs="auto"
                  className='p-0 m-0 ms-1 me-auto'
                  style={{ cursor: 'default' }}
               >
                  <Utils.StyledTooltip title={Locales.getHelpText("displayOptions")}>
                     <span>{Locales.getDisplayText("displayOptions")}</span>
                  </Utils.StyledTooltip>
               </Col>
            </Row>
         </Box>
         <Box
            boxShadow={2}
            bgcolor="background.paper"
            className="m-0 p-2 mb-2 w-100"
         >
            <form>
               <input type="file" name="file" onChange={onFileSelected} />
            </form>
         </Box>;
      </Container>
   </DialogContent>
   <DialogActions>
      <Button onClick={(e) => onOk(e)} color="primary" autoFocus>
         Yes
      </Button>
      <Button onClick={(e) => onCancel(e)} color="primary">
         No
      </Button>
   </DialogActions>
</StyledDialog>
*/

export const FileUpload2 = (props) => {
	const [selectedFile, setSelectedFile] = useState();
	const [isFilePicked, setIsFilePicked] = useState(false);
	
	const onFileSelected = (event) => {
		setSelectedFile(event.target.files[0]);
		setIsFilePicked(true);
	};

	const handleSubmission = () => {
		const formData = new FormData();
		formData.append('File', selectedFile);
		fetch(
			'/api/profilegroup/upload/3',
			{
				method: 'POST',
				body: formData,
			}
		)
			.then((response) => response.json())
			.then((result) => {
				console.log('Success:', result);
			})
			.catch((error) => {
				console.error('Error:', error);
			});
	};

	return (
		<div>
			<input type="file" name="file" onChange={onFileSelected} />
			{isFilePicked ? (
				<div>
					<p>Filename: {selectedFile.name}</p>
					<p>Filetype: {selectedFile.type}</p>
					<p>Size in bytes: {selectedFile.size}</p>
					<p>
						lastModifiedDate:{' '}
						{selectedFile.lastModifiedDate.toLocaleDateString()}
					</p>
				</div>
			) : (
				<p>Select a file to show details</p>
			)}
			<div>
				<button onClick={handleSubmission}>Submit</button>
			</div>
		</div>);
}
