import React from "react";
import { Container, Row, Col } from 'reactstrap';
import { Button, Dialog, DialogContent, DialogActions, Box } from '@mui/material';

import * as Locales from "./locales";

export const MessageBox = (props) => {
   const {
      caption,
      show,
      item,
      render,
      onOk,
      onCancel,
      okText,
      cancelText,
      okDisabled
   } = props;

   if (!show) {
      return <div />
   }

   let body = null;

   if (render) {
      body = render({
         ...props,
         item: item,
         borderStyle: null,
         noLinks: true
      });
   }

   return (
      <div>
         <Dialog
            open={show}
            onClose={onCancel}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth='xs'
            PaperProps={{ className: "opc-box-rounded opc-dark-blue opc-dialog" }}
         >
            <DialogContent id="alert-dialog-description" className='p-0 m-0'>
               <Container className="fluid border-0 m-0 p-0">
                  <Box
                     boxShadow={2}
                     bgcolor="background.paper"
                     className="m-0 p-0 w-100"
                  >
                     <Row className="m-0 fw-bold opc-dark-blue" style={{ marginLeft: '-1px', marginRight: '-1px' }}>
                        <Col
                           xs="auto"
                           className='p-0 m-0 pt-1 pb-1 ms-1 me-auto'
                           style={{ cursor: 'default' }}
                        >
                           {(caption?.length) ? caption : Locales.getDisplayText("dialog_caption")}
                        </Col>
                     </Row>
                  </Box>
                  {body}
               </Container>
            </DialogContent>
            <DialogActions className="p-1 m-0 opc-blue">
               <Button onClick={(e) => onOk(e, item)} disabled={(okDisabled) ? true : undefined}>
                  {(okText?.length) ? okText:Locales.getDisplayText("dialog_ok")}
               </Button>
               <Button onClick={(e) => onCancel(e, item)} autoFocus>
                  {(cancelText?.length) ? cancelText : Locales.getDisplayText("dialog_cancel")}
               </Button>
            </DialogActions>
         </Dialog>
      </div>
   );
}
