import React, { useState, useEffect, useCallback, useMemo } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Container, Row, Col } from 'reactstrap';
import { MessageBox } from "./MessageBox";
import Box from '@mui/material/Box';

import * as Locales from "./locales";
import * as Utils from "./Utils.js";
import * as RowIcons from "./RowIcons.js";
import * as FormRow from "./FormRow.js";
import * as Solid from '@fortawesome/free-solid-svg-icons';

function getSortColumnState(column) {
   if (!column || column.name === Utils.FieldNames.Sort) {
      return (column && column.descending) ? 1 : 2;
   }
   return 0;
}

function ItemRow(props) {
   const item = props.item;
   const editingEnabled = props.editingEnabled;

   let header = null;
   let subheader = null;
   let body = null;
   let color = "";

   if (!props.selectModeEnabled && !props.sortModeEnabled && item.expanded) {
      body = props.renderExpanded({
         ...props,
         item: item,
         editingEnabled: editingEnabled && item.editing,
         updateItem: props.onUpdate
      });

      color = " mt-0 mb-0 opc-dark-grey";
      header = <Col xs="auto" className='p-0 m-0 ms-1 me-auto' style={{ wordWrap: 'break-word' }} />;
   }
   else {
      color = (props.dark) ? "opc-grey" : "";
      color = (props.highlighted) ? "opc-highlight" : color;

      let leftoverSpace = 11;

      if (props.columns) {
         let count = props.columns.length;
         subheader = props.columns.map((ii) => {
            leftoverSpace -= (leftoverSpace > ii.width) ? ii.width : 0;
            count--;
            return (
               <Col key={ii.name} xs={(count > 0) ? 12 : 11} lg={ii.width} className='p-0 m-0' style={{ fontSize: (ii.fontSize) ?? "initial" }}>
                  {props.renderFieldValue(props, ii.name, item)}
               </Col>
            );
         });
      }

      let size = {};

      if (leftoverSpace > 0) {
         size.lg = leftoverSpace;
      }

      header =
         <Col xs="auto" className='p-0 m-0 ms-1 me-auto' {...size} style={{ wordWrap: 'break-word' }}>
            {props.renderFieldValue(props, Utils.FieldNames.Name, item)}
         </Col>
   }

   let leftIcons = [];

   if (props.selectModeEnabled) {
      leftIcons.push({ name: RowIcons.Icons.SelectItem, state: item.selected, onClick: props.onSelectItem });
   }
   else if (!props.sortModelEnabled){
      leftIcons.push({ name: RowIcons.Icons.Toggle, state: item.expanded, onClick: props.onToggle });
   }

   if (item.errorText || item.errorCode) {
      const errorText = `${item.errorCode}${(item.errorCode) ? ' ' : ''}${item.errorText}`;
      leftIcons.push({ name: RowIcons.Icons.Warning, helpText: errorText, color: 'red' });
   }

   let rightIcons = [];

   if (!props.selectModeEnabled && !props.sortModeEnabled && !props.disableRowEditing) {
      if (editingEnabled) {
         if (props.onToggleEdit) {
            rightIcons.push({ name: RowIcons.Icons.Edit, state: item.editing, onClick: props.onToggleEdit });
         }
         if (props.onDelete) {
            rightIcons.push({ name: RowIcons.Icons.Delete, onClick: props.onDelete });
         }
         if (props.onUnlink) {
            rightIcons.push({ name: RowIcons.Icons.Unlink, onClick: props.onUnlink });
         }
      }
      if (props.toggleItemSelection) {
         rightIcons.push({ name: RowIcons.Icons.Select, onClick: props.toggleItemSelection });
      }
      if (props.onViewDetail) {
         rightIcons.push({ name: RowIcons.Icons.ViewDetail, onClick: props.onViewDetail });
      }
   }

   if (props.sortModeEnabled) {
      rightIcons.push({ name: RowIcons.Icons.MoveDown,   onClick: (e, item) => props.onUpdateSort(e, item, false)});
      rightIcons.push({ name: RowIcons.Icons.MoveUp, onClick: (e, item) => props.onUpdateSort(e, item, true) });
   }

   if (props.headerIconCount) {
      while (rightIcons.length < props.headerIconCount) {
         rightIcons.push({ placeholder: true });
      }
   }

   if (item.expanded) {
      return (
         <Row className="m-0 p-0">
            <Box
               boxShadow={2}
               bgcolor="background.paper"
               className="m-0 p-0 mt-1 mb-2 w-100"
            >
               <Row
                  className={`mt-0 mb-0 p-0 ${color}`}
                  style={{ marginLeft: '-1px', marginRight: '-1px' }}
               >
                  <RowIcons.RowIcons
                     context={item}
                     icons={leftIcons}
                     dark={(item.expanded) ? true : false}
                  />
                  {header}
                  {subheader}
                  <RowIcons.RowIcons
                     context={item}
                     icons={rightIcons}
                     right={true}
                     dark={(item.expanded) ? true : false}
                  />
               </Row>
               {body}
            </Box>
         </Row>);
   }

   return (
      <Row className={`m-0 border-bottom-0 ${color}`}>
         <RowIcons.RowIcons
            context={item}
            icons={leftIcons}
            dark={(item.expanded) ? true : false}
         />
         {header}
         {subheader}
         <RowIcons.RowIcons
            context={item}
            icons={rightIcons}
            right={true}
            dark={(item.expanded) ? true : false}
         />
         {body}
      </Row>);
}

const getHeaderIcons = (props) => {
   const {
      onViewDetail,
      itemEditModeEnabled,
      editingEnabled,
      toggleItemEditMode,
      toggleSelectMode,
      selectModeEnabled,
      sortModeEnabled,
      onSortModeEnabled,
      onItemCreated,
      createItem,
      enableEditSort
   } = props;

   let icons = [];

   if (onViewDetail) {
      icons.push({ placeholder: true });
   }

   if (editingEnabled) {
      if (!toggleSelectMode || !selectModeEnabled) {
         if (toggleItemEditMode) {
            icons.push({ name: RowIcons.Icons.Edit, state: itemEditModeEnabled, onClick: toggleItemEditMode });
         } else {
            icons.push({ placeholder: true });
         }
      }
      if (!toggleItemEditMode || !itemEditModeEnabled) {
         if (toggleSelectMode) {
            icons.push({ name: RowIcons.Icons.Select, state: selectModeEnabled, onClick: toggleSelectMode });
         }
         else if (onSortModeEnabled && enableEditSort) {
            icons.push({ name: RowIcons.Icons.EditOrder, state: sortModeEnabled, onClick: onSortModeEnabled });
         } else {
            icons.push({ placeholder: true });
         }
      }
      if (!((toggleItemEditMode && itemEditModeEnabled) || (toggleSelectMode && selectModeEnabled))) {
         if (onItemCreated) {
            icons.push({ name: RowIcons.Icons.Create, onClick: createItem });
         } else {
            icons.push({ placeholder: true });
         }
      }
   }

   return icons;
}

const ListHeader = (props) => {
   const {
      columns,
      pageName,
      sortColumn,
      sortChanged,
      icons
   } = props;

   let subheader = null;

   if (columns) {
      subheader = columns.map((ii) => {
         return (
            <Col
               key={ii.name}
               xs={ii.width + 2} lg={ii.width}
               className='p-0 m-0'
               onClick={(e, item) => sortChanged(e, ii.name)}
               style={{ cursor: 'default' }}
            >
               <Utils.StyledTooltip title={Locales.getHelpText(`${pageName}_${ii.name}`)}>
                  <span>
                     {Locales.getDisplayText(`${pageName}_${ii.name}`)}
                     <FontAwesomeIcon
                        icon={(sortColumn && sortColumn.descending) ? Solid.faCaretUp : Solid.faCaretDown}
                        style={{ marginLeft: '4px', display: ((sortColumn && sortColumn.name === ii.name) ? "inline" : "none") }}
                     />
                  </span>
               </Utils.StyledTooltip>
            </Col>
         );
      });
   }

   return (
      <Box
         boxShadow={2}
         bgcolor="background.paper"
         className="m-0 p-0 mt-1 mb-2 w-100"
      >
         <Row className="m-0 fw-bold opc-blue">
            <RowIcons.RowIcons icons={[{
               name: RowIcons.Icons.Sort,
               state: getSortColumnState(sortColumn),
               onClick: (e, item) => sortChanged(e, Utils.FieldNames.Sort)
            }]} />
            <Col
               xs="auto"
               className='p-0 m-0 ms-1 me-auto'
               onClick={(e) => sortChanged(e, Utils.FieldNames.Name)}
               style={{ cursor: 'default' }}
            >
               <Utils.StyledTooltip title={Locales.getHelpText(`${pageName}_${Utils.FieldNames.Name}`)}>
                  <span>
                     {Locales.getDisplayText(`${pageName}_${Utils.FieldNames.Name}`)}
                     <FontAwesomeIcon
                        icon={(sortColumn && sortColumn.descending) ? Solid.faCaretUp : Solid.faCaretDown}
                        style={{ marginLeft: '4px', display: ((sortColumn && sortColumn.name === Utils.FieldNames.Name) ? "inline" : "none") }}
                     />
                  </span>
               </Utils.StyledTooltip>
            </Col>
            {subheader}
            <RowIcons.RowIcons icons={icons} context={props.group} />
         </Row>
      </Box >);
}

export const ItemGroup = (props) =>
{
   const [expanded, setExpanded] = useState(props.expanded);
   const { items, icons } = props;

   useEffect(() => {
      setExpanded(props.expanded);
   }, [props.expanded]);

   /*
   useEffect(() => {
      let newExpanded = Utils.load(props.storageKey + props.group + "/expanded", { expanded: props.expanded });
      setExpanded(props.expanded);
   }, [props.storageKey, props.expanded, props.group]);

   useEffect(() => {
      Utils.save(props.storageKey + props.group + "/expanded", { expanded: expanded });
   }, [expanded, props.storageKey, props.group]);
   */

   const toggleList = (e, item) => {
      e.preventDefault();
      setExpanded(!expanded);
   };

   let body = null;

   if (expanded) {
      let count = 0;

      body = items.map((ii) => {
         count++;
         return (
            <ItemRow
               {...props}
               key={ii.key}
               item={ii}
               editingEnabled={props.editingEnabled}
               borderStyle="opc-box-light"
               onToggle={(props.renderExpanded) ? props.toggleRow : null}
               onDelete={(props.editingEnabled && props.onItemDeleted) ? props.confirmDelete : null}
               onUnlink={(props.editingEnabled && props.onItemUnlinked) ? props.confirmDelete : null}
               onUpdate={props.onItemUpdated}
               onViewDetail={props.onViewDetail}
               columns={props.columns}
               dark={(count % 2 === 0) ? true : false}
               highlighted={(ii.selected || ii.id === props.selected) ? true : false}
               headerIconCount={icons.length}
            />
         )
      });
   }

   return (
      <Box
         boxShadow={2}
         bgcolor="background.paper"
         className="m-0 p-0 mt-1 mb-2 w-100"
      >
         <Container className="fluid border-0 m-0 p-0 mt-2">
            <Box
               boxShadow={2}
               bgcolor="background.paper"
               className="m-0 p-0 mt-1 mb-2 w-100"
            >
               <Row className="m-0 gx-1 fw-bold opc-orange" style={{ marginLeft: '-1px', marginRight: '-1px' }}>
                  <RowIcons.RowIcons
                     icons={[{ name: "toggle", state: expanded, onClick: toggleList }]}
                     right={true}
                  />
                  <Col
                     xs="auto"
                     className='p-0 m-0 ms-1 me-auto'
                     style={{ cursor: 'default' }}
                  >
                     <span>{props.group?.name}</span>
                  </Col>
               </Row>
            </Box>
            {body}
         </Container>
      </Box>);
};

function doSort(sortColumn, a, b) {

   if ((a.expanded && a.editing) || (b.expanded && b.editing)) {
      return (a.expanded && a.editing) ? ((b.expanded && b.editing) ? 0 : -1) : +1;
   }

   const column = (!sortColumn) ? Utils.FieldNames.Name : sortColumn?.name;

   const x = a[column];
   const y = b[column];
   const descending = sortColumn?.descending;

   if (a === null || !x) {
      return (b === null || !y) ? 0 : ((descending) ? +1 : -1);
   }

   if (typeof (x) === "string") {
      const result = x.localeCompare(y, 'en');
      return (descending) ? -result : result;
   }

   if (x < y) {
      return (descending) ? +1 : -1;
   }

   if (x > y) {
      return (descending) ? -1 : +1;
   }

   return 0;
}

export const ItemList = (props) => {

   const {
      groupBy
   } = props;

   const [showDialog, setShowDialog] = useState(false);
   const [dialogCaption, setDialogCaption] = useState(null);
   const [itemChanged, setItemChanged] = useState(1);
   const [dialogItem, setDialogItem] = useState(props.activeItem);
   const [sortColumn, setSortColumn] = useState(props.sortColumn ?? { name: Utils.FieldNames.Name });
   const [selectModeEnabled, setSelectModeEnabled] = useState(props.selectModeEnabled);
   const [lastSortModeEnabled, setLastSortModeEnabled] = useState(!props.sortModeEnabled);
   const [sortModeSwapCount, setSortModeSwapCount] = useState(0);
   const [disableRowEditing, setDisableRowEditing] = useState(props.disableRowEditing);
   const [defaultText, setDefaultText] = useState(null);
   const [storageKey, setStorageKey] = useState(props.storageKey);
   const [error, setError] = useState(props.error);
   const items = props.items;
   const itemCount = props.items?.length;
   const filters = props.filters;
   const filtersChanged = props.filtersChanged;
   const applyFilters = props.applyFilters;
   const onItemCreated = props.onItemCreated;
   const onItemDeleted = props.onItemDeleted;
   const deleteItemConfirmText = props.deleteItemConfirmText;
   const onItemUnlinked = props.onItemUnlinked; 
   const sortModeEnabled = props.sortModeEnabled;
   const onSortOrderChanged = props.onSortOrderChanged;

   // Utils.useWhyDidYouUpdate("ItemList", props);

   useEffect(() => {
      setStorageKey(props.storageKey);
   }, [props.storageKey]);

   useEffect(() => {
      setSortColumn(props.sortColumn);
   }, [props.sortColumn, storageKey]);

   useEffect(() => {
      setError(props.error);
   }, [props.error]);

   useEffect(() => {
      Utils.save(storageKey + "/sort", sortColumn);
   }, [sortColumn, storageKey]);

   useEffect(() => {
      setError(props.error);
   }, [props.error]);

   useEffect(() => {
      if (!props.nested) {
         let pageTitle = Locales.getDisplayText(`itemList_${props.containerName}`);
         Utils.updatePageTitle(pageTitle);
      }
   }, [props.containerName, props.nested]);

   useEffect(() => {
      setSelectModeEnabled(props.selectModeEnabled);
   }, [props.selectModeEnabled]);

   useEffect(() => {
      setDisableRowEditing(props.disableRowEditing);
   }, [props.disableRowEditing]);

   const cancelDialog = useCallback((e) => {
      e.preventDefault();
      setShowDialog(false);
   }, []);

   const createItem = useCallback(async (e) => {
      e.preventDefault();
      if (onItemCreated) {
         onItemCreated();
      }
   }, [onItemCreated]);

   const confirmDelete = useCallback(async (e, item) => {
      e.preventDefault();
      const textId = (deleteItemConfirmText) ? deleteItemConfirmText : "confirmDelete";
      const text = `${Locales.getDisplayText(textId)} '${item.name}'?`
      setDialogItem(item);
      setDialogCaption(text);
      setShowDialog(true);
   }, [deleteItemConfirmText]);

   const deleteConfirmed = useCallback(async (e, item) => {
      e.preventDefault();
      setShowDialog(false);
      const onNotify = (onItemDeleted) ? onItemDeleted : (onItemUnlinked) ? onItemUnlinked : null;
      if (onNotify) {
         await onNotify(item);
      }
      setItemChanged(e => e + 1);
   }, [onItemDeleted, onItemUnlinked]);

   const toggleRow = useCallback(async (e, item) => {
      e.preventDefault();
      item.expanded = !item.expanded;
      setItemChanged(e => e + 1);
      setDialogItem(item);
   }, []);

   const sort = useCallback((a, b) => {
      return doSort(sortColumn, a, b);
   }, [sortColumn]);

   const filteredItems = useMemo(() => {
      if (items && items.length > 0) {
         return Utils.applyFilters(filters, items, applyFilters, sortModeSwapCount).sort(sort);
      }
      return [];
   }, [filters, items, applyFilters, sort, sortModeSwapCount]);

   const sortChanged = useCallback(async (e, column) => {
      e.preventDefault();
      if (!sortModeEnabled) {
         let newSortColumn = null;

         if (sortColumn?.name === column) {
            newSortColumn = { name: column, descending: !sortColumn?.descending };
         }
         else {
            newSortColumn = { name: column, descending: sortColumn?.descending };
         }

         Utils.save(storageKey + "/sort", newSortColumn);
         setSortColumn(newSortColumn);
      }
   }, [storageKey, sortColumn, sortModeEnabled]);

   const changeSort = useCallback(async (e, item, up) => {
      e.preventDefault();
      if (sortModeEnabled) {
         const index = filteredItems.findIndex(ii => ii.id === item.id);
         let swap;
         if (index > 0 && up) {
            swap = filteredItems[index - 1];
         }
         else if (index < filteredItems.length - 1 && !up) {
            swap = filteredItems[index + 1];
         }
         if (swap) {
            const sort = swap.sort;
            swap.sort = item.sort;
            item.sort = sort;
            if (swap.sort === item.sort) {
               if (up) swap.sort++; else swap.sort--;
            }
            setSortModeSwapCount(e => e + 1);
         }
      }
   }, [sortModeEnabled, filteredItems]);

   useEffect(() => {
      if (sortModeEnabled === lastSortModeEnabled) {
         return;
      }
      if (sortModeEnabled) {
         setSortColumn({ name: Utils.FieldNames.Sort });
      }
      else if (!sortModeEnabled && sortModeSwapCount > 0) {
         let count = 0;
         let list = {};
         list.records = filteredItems.map(ii => {
            count += 10;
            return { id: ii.id, sort: count };
         });
         if (onSortOrderChanged) {
            onSortOrderChanged(list);
         }
         setSortModeSwapCount(0); 
      }
      setLastSortModeEnabled(sortModeEnabled);
   }, [sortModeEnabled, lastSortModeEnabled, sortModeSwapCount, onSortOrderChanged, filteredItems]);

   useEffect(() => {
      if (props.dataFetched) {
         if (filteredItems.length > 0) {
            setDefaultText(null);
         }
         else {
            setDefaultText(Locales.getDisplayText("noRecordsFound"));
         }
      } else {
         setDefaultText(Locales.getDisplayText("loadingRecords"));
      }
   }, [filteredItems.length, props.dataFetched]);

    const icons = getHeaderIcons({ ...props, createItem: createItem });

   let filterbox = null;

   if (props.renderFilters && !props.noFilters) {
      filterbox = props.renderFilters({
         ...props,
         filters: filters,
         onFiltersChanged: filtersChanged,
         originalCount: itemCount,
         filteredCount: filteredItems.length
      });
   }

   const groups = useMemo(() => {
      if (!groupBy || sortModeEnabled) {
         return null;
      }
      let newGroups = [];
      filteredItems.map((ii) => {
         const target = groupBy(ii);
         let group = newGroups.find((jj) => jj.id === target?.id);
         if (!group) {
            group = {
               id: target.id,
               name: target.name,
               sort: target?.sort ?? 1,
               expanded: target.expanded,
               items: []
            };
            newGroups.push(group);
         }
         group.items.push(ii);
         return ii;
      });
      let count = 0;
      return newGroups.sort(Utils.sortBySortThenName).map((ii) => {
         count += ii.items.length;
         if (count > 100) {
            ii.expanded = false;
         }
         return ii;
      })
   }, [filteredItems, groupBy, sortModeEnabled]);

   if (groups?.length) {
      let first = 0;
      return (
         <div>
            {filterbox}
            <Container className={"fluid border-0 m-0 p-0"}>
               <ListHeader {...props} sortColumn={sortColumn} icons={icons} sortChanged={sortChanged} />
               {groups.map((ii) => {
                  first++;
                  return (
                     <ItemGroup
                        {...props}
                        key={ii.id}
                        group={ii}
                        itemChanged={itemChanged}
                        items={ii.items}
                        first={first < 2}
                        expanded={ii.expanded}
                        sortChanged={sortChanged}
                        sortColumn={sortColumn}
                        toggleRow={toggleRow}
                        confirmDelete={confirmDelete}
                        createItem={(props.onItemCreated) ? createItem : undefined}
                        onViewDetail={props.onViewDetail}
                        icons={icons}
                     />
                  )
               })}
            </Container>
            <MessageBox
               caption={dialogCaption}
               show={showDialog}
               action="OK"
               item={dialogItem}
               render={props.renderExpanded}
               onOk={deleteConfirmed}
               onCancel={cancelDialog} />
         </div>);
   }

   let defaultRow = null;

   if (defaultText) {
      defaultRow =
         <div className="opc-highlight p-2 m-0 d-flex">
            <span style={{ lineHeight: 1.0 }}>
               {defaultText}
            </span>
         </div>;
   }

   let errorRow = null;

   if (error?.errorCode?.length) {
      errorRow = <FormRow.ErrorRow item={error} failed={error.failed} className="mb-2 mt-2" />
   }

   let count = 0;

   return (
      <div>
         {filterbox}
         <Box
            boxShadow={2}
            bgcolor="background.paper"
            className="m-0 p-0 mb-2"
         >
            <Container className="m-0 p-0">
               <ListHeader {...props} sortColumn={sortColumn} icons={icons} sortChanged={sortChanged} />
               {errorRow}
               {defaultRow}
               {filteredItems.map((ii) => {
                  count++;
                  return (
                     <ItemRow
                        {...props}
                        key={ii.key}
                        item={ii}
                        editingEnabled={props.editingEnabled}
                        onToggle={(props.renderExpanded) ? toggleRow : null}
                        onDelete={(props.editingEnabled && props.onItemDeleted) ? confirmDelete : null}
                        onUnlink={(props.editingEnabled && props.onItemUnlinked) ? confirmDelete : null}
                        onUpdate={props.onItemUpdated}
                        onViewDetail={props.onViewDetail}
                        selectModeEnabled={selectModeEnabled}
                        sortModeEnabled={sortModeEnabled}
                        onUpdateSort={changeSort}
                        disableRowEditing={disableRowEditing}
                        onSelectItem={props.onSelectItem}
                        columns={props.columns}
                        dark={(count % 2 === 0) ? true : false}
                        highlighted={(ii.selected || ii.id === props.selected) ? true : false}
                        headerIconCount={icons.length}
                     />
                  )
               })}
            </Container>
         </Box>
         <MessageBox
            caption={dialogCaption}
            show={showDialog}
            item={dialogItem}
            render={props.renderExpanded}
            onOk={deleteConfirmed}
            onCancel={cancelDialog} />
      </div>
   );
}
