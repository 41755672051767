import { useState, useEffect, useCallback, useRef } from "react";
import { Link } from 'react-router-dom';
import IdleTimer from "react-idle-timer";

import {
   NavItem,
   NavLink,
   UncontrolledDropdown,
   DropdownToggle,
   DropdownItem,
   DropdownMenu
}
from 'reactstrap';

import * as Locales from "./locales";
import * as Utils from "./Utils.js";

export function LoginMenu(props) {
   const [user, setUser] = useState(props.user);
   const [countdown, setCountdown] = useState(0);
   const [autoLogout, setAutoLogout] = useState(false);
   const mounted = useRef(true);
   const idleTimer = useRef(null);
   const isLoggedIn = user?.id && user?.id > 1;
   const idleTimeout = 60000;

   useEffect(() => {
      return () => {
         mounted.current = false;
      }
   }, []);

   useEffect(() => {
      setUser(props.user);
   }, [props.user]);

   const signIn = useCallback(async () => {
      window.location.href = `api/login?context=${window.location.pathname}`;
   }, []);

   const signOut = useCallback(async () => {
      window.location.href = `api/login/logout?context=${window.location.pathname}`;
   }, []);

   useEffect(() => {
      let timeout = null;
      if (countdown > 0) {
         timeout = setTimeout(() => {
            setCountdown(count => {
               if (count === 1) {
                  setAutoLogout(true);
                  signOut();
               }
               return count - 1;
            });
         }, idleTimeout);
      }
      return () => {
         if (timeout) clearTimeout(timeout);
         return timeout;
      }
   }, [countdown, signOut]);

   if (!isLoggedIn) {
      const text = autoLogout ? "autoLogout" : "signIn";
      return (
         <NavItem>
            <NavLink style={{ cursor: 'default' }} onClick={signIn}>{Locales.getDisplayText(text)}</NavLink>
         </NavItem>
      ); 
   }

   return (
      <UncontrolledDropdown nav inNavbar>
         <DropdownToggle nav caret>{user.name}</DropdownToggle>
         <DropdownMenu className="border-0" right>
            <DropdownItem>
               <NavLink style={{ cursor: 'default' }} tag={Link} to={`${Utils.PagePaths.Users}${(user?.id) ?? 1}`}>
                  Account
               </NavLink>
            </DropdownItem>
            <DropdownItem >
               <NavLink style={{ cursor: 'default' }} onClick={signOut}>{Locales.getDisplayText("signOut")}</NavLink>
            </DropdownItem>
         </DropdownMenu>
         <IdleTimer
            ref={idleTimer}
            onActive={() => setCountdown(-1)}
            onIdle={() => setCountdown(30)}
            debounce={250}
            timeout={idleTimeout}
         />
      </UncontrolledDropdown>
   ); 
}
