import * as React from 'react';
import * as Utils from "./Utils.js";

const DefaultUser = {
   name: "Anonymous",
   id: 1,
   profileGroupId: 171,
   releaseStatus: 3 
};

export interface IUser {
   id?: number
   name?: string
   companyName?: string
   workingGroupId?: number
   profileGroupId?: number
   releaseStatus?: number
};

export enum UserLoginStatus {
   Unknown = -1,
   LoggedOut = 0,
   InProgress = 1,
   LoggedIn = 2
}

export type UserContextType = {
   user: IUser,
   setUser: (user: IUser) => void
   loginStatus: UserLoginStatus,
   setLoginStatus: (status: UserLoginStatus) => void
};

export const UserContext = React.createContext<UserContextType>({
   user: {},
   setUser: (user: IUser) => { },
   loginStatus: UserLoginStatus.Unknown,
   setLoginStatus: (status: UserLoginStatus) => { }
});

interface UserProviderProps {
   children?: any
}

export const UserProvider = ({ children }: UserProviderProps) => {
   const [user, setUser] = React.useState<IUser | null>(DefaultUser);
   const [loginStatus, setLoginStatus] = React.useState<UserLoginStatus>(UserLoginStatus.Unknown);

   const userContext = {
      user,
      setUser,
      loginStatus,
      setLoginStatus
   } as UserContextType;

   React.useEffect(() => {
      let controller = new AbortController();
      if (UserLoginStatus.LoggedIn === loginStatus) {
         fetch();
      }
      async function fetch() {
         const response = await Utils.httpGet(`api/login/current`, controller);
         if (response?.errorCode) {
            if (!response.silent) {
               setUser(null);
               setLoginStatus(UserLoginStatus.LoggedOut);
               console.error(`Error logging in: ${response.errorCode}: ${response.errorText}`);
            }
         }
         else {
            setUser(response?.result);
         }
      }
      return function cleanup() {
         controller.abort();
      };
   }, [loginStatus]);

   React.useEffect(() => {
      const userId = (user && user.id) ? user.id : 0;
      if (userId > 1) {
         fetch();
      }
      async function fetch() {
         const response = await Utils.httpPostAndUpdateList(`api/user/put/${userId}`, user);
         if (response?.errorCode) {
            if (!response.silent) {
               console.error(`Error updating user: ${response.errorCode}: ${response.errorText}`);
            }
         }
      }
   }, [user]);

   return (
      <UserContext.Provider value={userContext}>
         {children}
      </UserContext.Provider>
   );
};

export default UserProvider;