import React, { useCallback } from "react";
import { Container } from 'reactstrap';
import { ReadOnlyForm } from "./ReadOnlyForm";
import { UpdatableForm } from "./UpdatableForm";
import { FilterForm } from "./FilterForm";

import * as Utils from "./Utils.js";
import * as FormRow from "./FormRow.js";

export function setDefaultFieldOption(name, options, item) {
   let value = item[name];
   if (options) {
      if (Utils.isNullId(value)) {
         value = options.find(ii => ii.id === Utils.AllValue.id);
      }
      else if (options.length) {
         value = options.find(ii => ii.id === value);
      }
      if (!value) {
         value = options[name][0]?.id;
      }
   }
   return value;
}

export function updateFieldState(states, name, hidden, disabled) {
   if (hidden) {
      states[name] = FormRow.FieldState.Hidden;
      return;
   }
   if (disabled) {
      states[name] = FormRow.FieldState.Disabled;
      return;
   }
   states[name] = FormRow.FieldState.Normal;
};

export function updateFilterFieldState(states, name, filters, hidden) {
   if (hidden) {
      states[name] = FormRow.FieldState.Hidden;
      return;
   }
   if (filters && filters.disabled[name]) {
      states[name] = FormRow.FieldState.Disabled;
      return;
   }
   states[name] = FormRow.FieldState.Normal;
};

export const ItemForm = (props) => {
   const {
      pageName,
      containerName,
      editingEnabled,
      onItemUpdated,
      noButtons,
      location,
      getViewerStates,
      getEditorStates,
      getEditorOptions,
      renderFieldValue,
      definitions,
      toJson
   } = props;

   const renderViewerFields = useCallback((context, item) => {
      return (
         <Container className="p-0 m-0 opc-blue">
            {FormRow.getViewerFields(
               {
                  pageName: pageName,
                  containerName: containerName,
                  location: location,
                  fieldStates: (getViewerStates) ? getViewerStates(context, item) : {},
                  renderFieldValue: (field, value) => renderFieldValue(props, field?.name, value, field)
               },
               definitions,
               item).map((ii) => { return ii })
            }
         </Container>
      );
   }, [props, pageName, containerName, location, definitions, getViewerStates, renderFieldValue]);

   const renderEditorFields = useCallback((context, item) => {
      item.toJson = toJson;
      return (
         <Container className="p-0 m-0">
            {FormRow.getEditorFields(
               {
                  pageName: pageName,
                  containerName: containerName,
                  location: location,
                  editingAllowed: context.editingAllowed,
                  fieldStates: (getEditorStates) ? getEditorStates(context, item) : {},
                  fieldOptions: (getEditorOptions) ? getEditorOptions(context, item) : [],
                  renderFieldValue: (field, value) => renderFieldValue(context, field?.name, value, field),
                  ...context.callbacks
               },
               definitions,
               item).map((ii) => { return ii })
            }
         </Container>
      );
   }, [toJson, pageName, containerName, location, definitions, getEditorStates, getEditorOptions, renderFieldValue]);

   if (editingEnabled) {
      return (<UpdatableForm
         {...props}
         onItemUpdated={onItemUpdated}
         noButtons={noButtons}
         renderFields={renderEditorFields}
      />);
   }

   return (<ReadOnlyForm
      {...props}
      renderFields={renderViewerFields}
   />);
}

export const ItemFilterForm = (props) => {
   const {
      pageName,
      containerName,
      noButtons,
      location,
      getEditorStates,
      getEditorOptions,
      renderFieldValue,
      definitions,
      inListEditMode
   } = props;

   const renderFilterFields = useCallback((context) => {
      const filters = context.filters;
      const callbacks = context.callbacks;
      return (
         <Container className="p-0 m-0">
            {FormRow.getEditorFields(
               {
                  pageName: pageName,
                  containerName: containerName,
                  location: location,
                  editingAllowed: true,
                  inListEditMode: inListEditMode,
                  fieldStates: (getEditorStates) ? getEditorStates(context, filters) : {},
                  fieldOptions: (getEditorOptions) ? getEditorOptions(context, filters) : [],
                  renderFieldValue: (field, value) => renderFieldValue(context, field?.name, value, field),
                  ...callbacks
               },
               definitions,
               filters).map((ii) => { return ii })
            }
         </Container>
      );
   }, [pageName, containerName, location, definitions, getEditorStates, getEditorOptions, renderFieldValue, inListEditMode]);

   return (<FilterForm
      {...props}
      noButtons={noButtons}
      renderFilters={renderFilterFields}
   />);
}