import React, { useState, useEffect, useCallback } from "react";
import { Container, Row, Col } from 'reactstrap';
import { RowIcons } from "./RowIcons";
import { ItemList } from "./ItemList";
import Box from '@mui/material/Box';

import * as Locales from "./locales";
import * as Utils from "./Utils.js";

export const ItemHistory = (props) => {
   const {
      user,
      item,
      storageKey,
      pathApiUrl,
      childListName,
      updateCount
   } = props;

   const [editingAllowed, setEditingAllowed] = useState(user?.isEditor);
   const [items, setItems] = useState([]);
   const [expanded, setExpanded] = useState(false);
   const [dataFetched, setDataFetched] = useState(false);
   const [error, setError] = useState(null);
   const isEditor = user?.isEditor;
   const guid = item?.guid;

   useEffect(() => {
      setEditingAllowed(isEditor);
   }, [isEditor]);

   useEffect(() => {
      let newExpanded = Utils.load(`${storageKey}/expanded`, { expanded: false });
      setExpanded(newExpanded.expanded);
   }, [storageKey]);

   useEffect(() => {
      if (pathApiUrl && guid && expanded) {
         fetch(pathApiUrl, guid);
      }
      async function fetch(url, guid) {
         setDataFetched(true);
         const urlToUse = `api${url}history/${guid}`;
         const response = await Utils.httpGet(urlToUse);
         if (response?.errorCode) {
            setError(response);
            setItems([]);
         }
         else {
            setItems(oldItems => {
               let newItems = Utils.processGetResponseAndUpdateList(urlToUse, response, oldItems);
               return newItems;
            });
         }
      }
   }, [pathApiUrl, guid, expanded, updateCount]);

   const toggleList = (e, item) => {
      e.preventDefault();
      setExpanded(x => !x);
   };

   const deleteItem = useCallback(async (item) => {
      const newItem = await Utils.simpleDeleteItem(pathApiUrl, item);
      if (newItem?.errorCode) {
         setError(newItem);
         return;
      }
      setItems(oldItems => {
         return oldItems.filter((ii) => ii.key !== item.key);
      });
   }, [pathApiUrl]);

   let body = null;

   if (expanded) {
      const columns = [
         { name: Utils.FieldNames.ReleaseStatus, width: "2" },
         { name: Utils.FieldNames.ProfileGroupId, width: "2" },
         { name: "version", width: "1" }
      ];
      const sortColumn = { name: "version", descending: true };

      body =
         <ItemList
            {...props}
            items={items}
            dataFetched={dataFetched}
            error={error}
            selected={item?.id}
            nested={true}
            storageKey={storageKey}
            columns={columns}
            sortColumn={sortColumn}
            editingEnabled={editingAllowed}
            deleteOnly={true}
            onItemDeleted={deleteItem}
         />;
   }

   return (
      <Container className="fluid border-0 m-0 p-0 mt-2">
         <Box
            boxShadow={2}
            bgcolor="background.paper"
            className="m-0 p-0 mt-1 mb-2 w-100"
         >
            <Row className="m-0 fw-bold opc-yellow" style={{ marginLeft: '-1px', marginRight: '-1px' }}>
               <RowIcons
                  icons={[{ name: "toggle", state: expanded, onClick: toggleList }]}
                  right={true}
               />
               <Col
                  xs="auto"
                  className='p-0 m-0 ms-1 me-auto'
                  style={{ cursor: 'default' }}
               >
                  <Utils.StyledTooltip title={Locales.getHelpText(childListName)}>
                     <span>{Locales.getDisplayText(childListName)}</span>
                  </Utils.StyledTooltip>
               </Col>
            </Row>
         </Box>
         {body}
      </Container>);
}