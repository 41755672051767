import React from 'react';
import { Container } from 'reactstrap';
import { NavMenu, FooterMenu } from './NavMenu';
import { SideBar } from "./SideBar";

// import * as Utils from "./Utils.js";

export const Layout = (props) => {
    return (
        <div className="opc-page-root">
            <div className={`opc-page-navbar m-0 p-0`}>
                <NavMenu cacheVersion={props.cacheVersion} user={props.user} />
            </div>
            <div className="opc-page-body">
                <nav className="opc-page-sidebar">
                    <SideBar definitions={props.sidebar} cacheVersion={props.cacheVersion} user={props.user} active={props.activeSideBar} />
                </nav>
                <main className="opc-page-content mt-3">
                    <Container className="m-0 m-0" style={{ maxWidth: '5000px' }}>
                        {props.children}
                    </Container>
                </main>
            </div>
            <div className="opc-page-footer m-0 p-0">
                <FooterMenu cache={props.cache} cacheVersion={props.cacheVersion} user={props.user} />
            </div>
        </div>
    );

    /*
    return (
      <div className="opc-page-root">
         <div id="outerContainerId" className="opc-page-body">
            <nav className="opc-page-sidebar">
               <SideBar
                  definitions={props.sidebar}
                  cacheVersion={props.cacheVersion}
                  user={props.user}
                  active={props.activeSideBar}
                  currentRecord={Utils.getCurrentRecord()}
               />
            </nav>
            <div id="pageWrapId" className="m-0 p-0" style={{ flex: '1 1 auto' }}>
               <div className={`opc-page-navbar m-0 p-0`}>
                  <NavMenu cacheVersion={props.cacheVersion} user={props.user} />
               </div>
               <main className="opc-page-content ms-1 me-1">
                  <Container className="m-0 p-0 mt-3" >
                     {props.children}
                  </Container>
               </main>
            </div>
         </div>
         <div className="opc-page-footer m-0 p-0">
            <FooterMenu cache={props.cache} cacheVersion={props.cacheVersion} user={props.user} />
         </div>
      </div>
   );
   */
}

