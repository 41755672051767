import React, { useState, useEffect, useCallback } from 'react';
import { Route } from 'react-router';
import { Layout } from './components/Layout';
import { Home } from './components/Home';
import { LoginPage } from './components/LoginPage';
import { ErrorPage } from './components/ErrorPage';

import * as Utils from "./components/Utils.js";
import * as Solid from '@fortawesome/free-solid-svg-icons'
import * as WorkingGroups from './components/WorkingGroupPage';
import * as ProfileGroups from './components/ProfileGroupPage';
import * as Documents from './components/DocumentPage';
import * as DocumentVersions from './components/DocumentVersionPage';
import * as Persons from './components/PersonPage';
import * as ConformanceGroups from './components/ConformanceGroupPage';
import * as Categories from './components/CategoryPage';
import * as ConformanceUnits from './components/ConformanceUnitPage';
import * as ProfileFolders from './components/ProfileFolderPage';
import * as Profiles from './components/ProfilePage';
import * as Tags from './components/TagPage';
import * as Users from './components/UserPage';
import * as TestSuites from './components/TestSuitePage';
import * as TestCases from './components/TestCasePage';

import './custom.css'

const Pages = [
   {
      url: Utils.PagePaths.WorkingGroups,
      renderPage: (props) => <WorkingGroups.WorkingGroupPage {...props} />,
      renderList: (props) => <WorkingGroups.WorkingGroupList {...props} />
   },
   {
      url: Utils.PagePaths.Documents,
      renderPage: (props) => <Documents.DocumentPage {...props} />,
      renderList: (props) => <Documents.DocumentList {...props} />
   },
   {
      url: Utils.PagePaths.DocumentVersions,
      renderPage: (props) => <DocumentVersions.DocumentVersionPage {...props} />,
      renderList: (props) => <DocumentVersions.DocumentVersionList {...props} />
   },
   {
      url: Utils.PagePaths.Persons,
      renderPage: (props) => <Persons.PersonPage {...props} />,
      renderList: (props) => <Persons.PersonList {...props} />
   },
   {
      url: Utils.PagePaths.Tags,
      renderPage: (props) => <Tags.TagPage {...props} />,
      renderList: (props) => <Tags.TagList {...props} />
   },
   {
      url: Utils.PagePaths.ProfileGroups,
      renderPage: (props) => <ProfileGroups.ProfileGroupPage {...props} />,
      renderList: (props) => <ProfileGroups.ProfileGroupList {...props} />
   },
   {
      url: Utils.PagePaths.Profiles,
      renderPage: (props) => <Profiles.ProfilePage {...props} />,
      renderList: (props) => <Profiles.ProfileList {...props} />
   },
   {
      url: Utils.PagePaths.ProfileFolders,
      renderPage: (props) => <ProfileFolders.ProfileFolderPage {...props} />,
      renderList: (props) => <ProfileFolders.ProfileFolderList {...props} />
   },
   {
      url: Utils.PagePaths.Categories,
      renderPage: (props) => <Categories.CategoryPage {...props} />,
      renderList: (props) => <Categories.CategoryList {...props} />
   },
   {
      url: Utils.PagePaths.ConformanceGroups,
      renderPage: (props) => <ConformanceGroups.ConformanceGroupPage {...props} />,
      renderList: (props) => <ConformanceGroups.ConformanceGroupList {...props} />
   },
   {
      url: Utils.PagePaths.ConformanceUnits,
      renderPage: (props) => <ConformanceUnits.ConformanceUnitPage {...props} />,
      renderList: (props) => <ConformanceUnits.ConformanceUnitList {...props} />
   },
   {
      url: Utils.PagePaths.Users,
      renderPage: (props) => <Users.UserPage {...props} />,
      renderList: (props) => <Users.UserList {...props} />
   },
   {
      url: Utils.PagePaths.TestSuites,
      renderPage: (props) => <TestSuites.TestSuitePage {...props} />,
      renderList: (props) => <TestSuites.TestSuiteList {...props} />
   },
   {
      url: Utils.PagePaths.TestCases,
      renderPage: (props) => <TestCases.TestCasePage {...props} />,
      renderList: (props) => <TestCases.TestCaseList {...props} />
   }
];

const checkProfileGroup = (item) => Utils.getCurrentUser()?.profileGroupId === item.profileGroupId;

const ProfileSideBar = [
   {
      nodeType: "root",
      url: Utils.PagePaths.Categories,
      urlParameters: () => `${Utils.getProfileGroupQuery()}&all=1`,
      children: [
         {
            name: "folder",
            pathType: Utils.PathTypes.ProfileFolder,
            url: `${Utils.PagePaths.Categories}profilefolders/`,
            filter: (x) => {
               if (checkProfileGroup(x)) {
                  return !x.parentFolderGuid?.length;
               }
               return false;
            }
         }
      ],
      hideIfEmpty: true
   },
   {
      nodeType: "folder",
      pathType: Utils.PathTypes.ProfileFolder,
      url: Utils.PagePaths.ProfileFolders,
      urlParameters: () => `${Utils.getProfileGroupQuery()}`,
      children: [
         {
            name: "folder",
            pathType: Utils.PathTypes.ProfileFolder,
            url: `${Utils.PagePaths.ProfileFolders}children/`,
            filter: (x) => {
               if (checkProfileGroup(x)) {
                  return x.parentFolderGuid?.length;
               }
               return false;
            }
         },
         {
            name: "profile",
            pathType: Utils.PathTypes.Profile,
            url: `${Utils.PagePaths.ProfileFolders}profiles/`,
            filter: (x) => checkProfileGroup(x)
         }
      ]
   },
   {
      nodeType: "profile",
      pathType: Utils.PathTypes.Profile,
      url: Utils.PagePaths.Profiles,
      urlParameters: () => `${Utils.getProfileGroupQuery()}`,
      normalIcon: Solid.faFolderPlus,
      expandedIcon: Solid.faFolderMinus,
      noChildrenIcon: Solid.faFolder,
      iconColor: "#548235",
      children: [
         {
            name: "includedProfile",
            pathType: Utils.PathTypes.Profile,
            url: `${Utils.PagePaths.Profiles}includedprofiles/`
         }
      ]
   },
   {
      nodeType: "includedProfile",
      url: Utils.PagePaths.Profiles,
      urlParameters: () => `${Utils.getProfileGroupQuery()}`,
      suppressPathCheck: true,
      normalIcon: Solid.faFolderPlus,
      expandedIcon: Solid.faFolderMinus,
      noChildrenIcon: Solid.faFolder,
      showSelectView: true,
      iconColor: "#2F5597",
      children: [
         {
            name: "includedProfile",
            url: `${Utils.PagePaths.Profiles}includedprofiles/`
         }
      ]
   }
];

const DocumentSideBar = [
   {
      nodeType: "root",
      pathType: Utils.PathTypes.Tag,
      url: Utils.PagePaths.Tags,
      urlParameters: () => `type=${Utils.TagType.Classification}`,
      filterResults: (parent, results) => results,
      children: [
         {
            name: "workingGroups",
            pathType: Utils.PathTypes.WorkingGroup,
            url: `${Utils.PagePaths.Tags}workinggroups/`,
         }
      ]
   },
   {
      nodeType: "workingGroups",
      pathType: 6,
      url: Utils.PagePaths.WorkingGroups,
      urlParameters: () => "",
      filterResults: (parent, results) => results,
      children: [
         {
            name: "documents",
            pathType: Utils.PathTypes.Document,
            url: `${Utils.PagePaths.WorkingGroups}documents/`
         }
      ]
   },
   {
      nodeType: "documents",
      pathType: Utils.PathTypes.Document,
      url: Utils.PagePaths.Documents,
      normalIcon: Solid.faBook,
      iconColor: "#548235"
   }
];

const WorkingGroupSideBar = [
   ...DocumentSideBar
]

const ConformanceUnitSideBar = [
   {
      nodeType: "root",
      pathType: 4,
      url: Utils.PagePaths.ConformanceGroups,
      urlParameters: () => `${Utils.getProfileGroupQuery()}`,
      filterResults: (parent, results) => results,
      children: [
         {
            name: "conformanceUnits",
            pathType: 5,
            url: `${Utils.PagePaths.ConformanceGroups}conformanceunits/`
         }
      ]
   },
   {
      nodeType: "conformanceUnits",
      pathType: 5,
      url: Utils.PagePaths.ConformanceUnits,
      normalIcon: Solid.faBox,
      iconColor: "#548235"
   }
];

const TestSuiteSideBar = [
   {
      nodeType: "root",
      pathType: 9,
      url: Utils.PagePaths.TestSuites,
      urlParameters: () => `${Utils.getProfileGroupQuery()}&root=1`,
      children: [
         {
            name: "children",
            pathType: 9,
            url: `${Utils.PagePaths.TestSuites}children/`
         }
      ]
   },
   {
      nodeType: "children",
      pathType: 9,
      url: Utils.PagePaths.TestSuites,
      children: [
         {
            name: "children",
            pathType: Utils.PathTypes.TestSuites,
            url: `${Utils.PagePaths.TestSuites}children/`
         }
      ]
   }
];

const SideBarDefinitions = {
   [Utils.PageNames.Profiles]: {
      title: "sidebar_profilesTitle",
      subtitle: "sidebar_profilesSubtitle",
      definitions: ProfileSideBar,
   },
   [Utils.PageNames.WorkingGroups]: {
      title: "sidebar_workingGroupsTitle",
      subtitle: "",
      definitions: WorkingGroupSideBar
   },
   [Utils.PageNames.Documents]: {
      title: "sidebar_documentsTitle",
      subtitle: "sidebar_documentsSubtitle",
      definitions: DocumentSideBar
   },
   [Utils.PageNames.ConformanceUnits]: {
      title: "sidebar_conformanceUnitsTitle",
      subtitle: "sidebar_conformanceUnitsSubtitle",
      definitions: ConformanceUnitSideBar
   },
   [Utils.PageNames.ProfileGroups]: {
      title: "sidebar_profileGroupsTitle",
      subtitle: "",
      definitions: []
   },
   [Utils.PageNames.Persons]: {
      title: "sidebar_personsTitle",
      subtitle: "",
      definitions: []
   },
   [Utils.PageNames.Tags]: {
      title: "sidebar_tagsTitle",
      subtitle: "",
      definitions: []
   },
   [Utils.PageNames.TestSuites]: {
      title: "sidebar_testSuitesTitle",
      subtitle: "",
      definitions: TestSuiteSideBar
   }
}

function selectSideBar() {
   var location = window.location.pathname;
   const index = location.indexOf("/", 1);
   if (index > 0) {
      location = location.slice(0, index);
   }
   if (!location.endsWith("/")) {
      location += "/";
   }

   switch (location) {
      default:
      case Utils.PagePaths.Categories:
      case Utils.PagePaths.ProfileFolders:
      case Utils.PagePaths.Profiles:
         {
            return Utils.PageNames.Profiles;
         }

      case Utils.PagePaths.WorkingGroups:
         {
            return Utils.PageNames.WorkingGroups;
         }

      case Utils.PagePaths.Documents:
      case Utils.PagePaths.DocumentVersions:
         {
            return Utils.PageNames.Documents;
         }

      case Utils.PagePaths.ConformanceGroups:
      case Utils.PagePaths.ConformanceUnits:
         {
            return Utils.PageNames.ConformanceUnits;
         }

      case Utils.PagePaths.ProfileGroups:
         {
            return Utils.PageNames.ProfileGroups;
         }

      case Utils.PagePaths.Persons:
         {
            return Utils.PageNames.Persons;
         }

      case Utils.PagePaths.Tags:
         {
            return Utils.PageNames.Tags;
         }

      case Utils.PagePaths.TestSuites:
      case Utils.PagePaths.TestCases:
      case Utils.PagePaths.TestSteps:
         {
            return Utils.PageNames.TestSuites;
         }
   }
}

export default function App() {
   const [user, setUser] = useState(null);
   const [cacheVersion, setCacheVersion] = useState(0);

   const cacheUpdated = useCallback(() => {
      if (window.$cache?.version) {
         // console.info(`CACHE updated. ${window.$cache?.version}`);
         setCacheVersion(window.$cache?.version)
      }
      const newUser = Utils.getCurrentUser();
      setUser(newUser);
   }, []);

   const initializeCache = useCallback((userName) => {
      if (!window.$cache?.onUpdate) {
         window.$cache.onUpdate = cacheUpdated;
      }
      return window.$cache;
   }, [cacheUpdated]);

   useEffect(() => {
      initializeCache();
      async function fetchUser() {
         if (!user?.id || user.id <= 1) {
            const response = await Utils.httpGet(`api/login/current`);
            if (response?.errorCode) {
               if (!response.silent) {
                  console.error(`Error logging in: ${response.errorCode}: ${response.errorText}`);
               }
               Utils.setCurrentUser(
                  {
                     id: 1,
                     name: "Anonymous",
                     email: "",
                     companyName: "",
                     membershipType: 0,
                     workingGroupId: Utils.DefaultWorkingGroup,
                     profileGroupId: Utils.DefaultProfileGroup,
                     releaseStatus: 3,
                     isEditor: false,
                     profileGroupDependencies: []
                  },
                  false
               );
            }
            else {
               Utils.setCurrentUser(response?.result, true);
            }
         }
      }
      fetchUser();
   }, [initializeCache, user?.id]);

   return (
      <Layout cacheVersion={cacheVersion} user={user} sidebar={SideBarDefinitions} activeSideBar={selectSideBar()}>
         <Route exact path='/'
            render={(props) => <Home user={user} cacheVersion={cacheVersion} component={Home} {...props} />}
         />
         <Route exact path='/login'
            render={(props) => <LoginPage cacheVersion={cacheVersion} component={LoginPage}  {...props} />}
         />
         <Route exact path='/error'
            render={(props) => <ErrorPage cacheVersion={cacheVersion} component={LoginPage}  {...props} />}
         />
         <Route
            path={`/UA-Profile`}
            render={(props) => <Profiles.ProfileUri user={user} cacheVersion={cacheVersion} {...props} />}
         />
         <Route
            path={`/SecurityPolicy`}
            render={(props) => <Profiles.ProfileUri user={user} cacheVersion={cacheVersion} {...props} />}
         />
         {Pages.filter((ii) => ii.renderList).map((ii) => {
            return (
               <Route
                  key={ii.url}
                  exact path={ii.url.slice(0, ii.url.length - 1)}
                  render={(params) => ii.renderList({ ...params, user, cacheVersion })}
               />
            );
         })}
         {Pages.filter((ii) => ii.renderPage).map((ii) => {
            return (
               <Route
                  key={ii.url}
                  path={`${ii.url}:id`}
                  render={(params) => ii.renderPage({ ...params, user, cacheVersion })}
               />
            );
         })}
      </Layout>
   );
}
