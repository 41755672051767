import React, { useCallback } from "react";

import * as FormRow from "./FormRow.js";
import * as Utils from "./Utils.js";
import * as Locales from "./locales";
import { MultiSelectList } from "./MultiSelectList";
import { SingleItemPage } from "./SingleItemPage";
import { ProfileFolderList } from "./ProfileFolderPage";
import { ProfileList } from "./ProfilePage";
import { ConformanceUnitList } from "./ConformanceUnitPage";

const PageCacheName = Utils.PageNames.Categories;
const PageApiPath = Utils.PagePaths.Categories;
const PageName = PageCacheName;

export const FieldNames = {
   Name: Utils.FieldNames.Name,
   Description: Utils.FieldNames.Description,
   Sort: Utils.FieldNames.Sort,
   ReleaseStatus: Utils.FieldNames.ReleaseStatus,
   Version: Utils.FieldNames.Version,
   LastUpdateTime: Utils.FieldNames.LastUpdateTime,
   LastUpdatingUserId: Utils.FieldNames.LastUpdatingUserId,
   ProfileGroupId: Utils.FieldNames.ProfileGroupId,
   WorkingGroupId: Utils.FieldNames.WorkingGroupId,
   InlineDescription: Utils.FieldNames.InlineDescription
};

export const ChildListNames = {
   Profiles: "profiles",
   ProfileFolders: "profileFolders",
   ConformanceUnits: "conformanceUnits"
};

const FieldDefinitions = [
   {
      name: FieldNames.Name,
      defaultValue: "",
      viewControlType: FormRow.FieldType.Label,
      editControlType: FormRow.FieldType.TextInput,
      maxTextLength: 80
   },
   {
      name: FieldNames.ReleaseStatus,
      defaultValue: Utils.ReleaseStatus.Draft,
      viewControlType: FormRow.FieldType.Label,
      editControlType: FormRow.FieldType.OptionSet
   },
   {
      name: FieldNames.Sort,
      defaultValue: 0,
      viewControlType: FormRow.FieldType.Label,
      editControlType: FormRow.FieldType.NumberInput,
      minValue: 0,
      maxValue: 10000,
      editorOnly: true
   },
   {
      name: FieldNames.Version,
      defaultValue: 1,
      viewControlType: FormRow.FieldType.Label,
      editorOnly: true
   },
   {
      name: FieldNames.LastUpdateTime,
      defaultValue: "",
      viewControlType: FormRow.FieldType.Label,
      editorOnly: true
   },
   {
      name: FieldNames.LastUpdatingUserId,
      defaultValue: "",
      viewControlType: FormRow.FieldType.Label,
      editorOnly: true
   },
   {
      name: FieldNames.ProfileGroupId,
      defaultValue: Utils.DefaultProfileGroup,
      viewControlType: FormRow.FieldType.Label
   },
   {
      name: FieldNames.Description,
      defaultValue: "",
      viewControlType: FormRow.FieldType.BlockLabel,
      editControlType: FormRow.FieldType.TextArea
   }
];

const ChildDefinitions = [
   {
      name: ChildListNames.Profiles,
      url: `${PageApiPath}profiles/`,
      expanded: false,
      renderList: (props) => <ProfileList
         {...props}
         groupByName={Utils.PageNames.ProfileGroups}
         enableEditSort={true}
      />
   },
   {
      name: ChildListNames.ProfileFolders,
      url: `${PageApiPath}profilefolders/`,
      expanded: false,
      renderList: (props) => <ProfileFolderList
         {...props}
         groupByName={Utils.PageNames.ProfileFolders}
      />
   },
   {
      name: ChildListNames.ConformanceUnits,
      url: `${PageApiPath}conformanceunits/`,
      expanded: false,
      renderList: (props) => <ConformanceUnitList
         {...props}
         groupByName={Utils.PageNames.ConformanceGroups}
      />
   }
];

function renderFieldValue(context, name, item, field) {
   switch (name) {
      case FieldNames.Name: {
         return <FormRow.LinkField
            id={item.id}
            item={item}
            displayText={item.name}
            path={Utils.PagePaths.Categories}
            parentPath={context.location}
            noLink={context.noLink}
         />;
      }
      case FieldNames.LastUpdatingUserId: {
         return <FormRow.UserName id={item.lastUpdatingUserId} />;
      }
      case FieldNames.LastUpdateTime: {
         if (!item.lastUpdateTime) {
            return null;
         }
         return Utils.getDateAsString(new Date(item.lastUpdateTime));
      }
      case FieldNames.ReleaseStatus: {
         const name = Object.keys(Utils.ReleaseStatus).find((ii) => Utils.ReleaseStatus[ii] === item.releaseStatus);
         return Locales.getDisplayText(`releaseStatus_${name}`);
      }
      case FieldNames.ProfileGroupId: {
         let pg = Utils.findInCache(Utils.PagePaths.ProfileGroups, 0, item.profileGroupId);
         return (pg) ? pg.fullName : item.profileGroupId;
      }
      case FieldNames.InlineDescription: {
         return <label
            style={{ lineHeight: 1.2 }}
            className="pt-1 pb-0 pe-1 fluid w-100"
            dangerouslySetInnerHTML={{ __html: Utils.sanitizeString(item.description) }}>
         </label>
      }
      default: {
         return item[name];
      }
   }
}

function toJson(item) {
   return {
      id: item.id,
      name: item.name,
      description: item.description,
      releaseStatus: item.releaseStatus,
      sort: item.sort,
      profileGroupId: item.profileGroupId,
      workingGroupId: item.workingGroupId,
      lastUpdateTime: Utils.getDate(item.lastUpdateTime)
   }
}

function initializeNewItem(props, newItem) {
   const {
      item,
      user
   } = props;
   if (user?.profileGroupId) newItem.profileGroupId = user.profileGroupId;
   if (user?.workingGroupId) newItem.workingGroupId = user.workingGroupId;
   if (item?.name) newItem.name = `(Copy) ${item.name}`;
   if (item?.sort) newItem.sort = item.sort + 1;
   if (item?.profileGroupId) newItem.profileGroupId = item.profileGroupId;
   if (item?.workingGroupId) newItem.workingGroupId = item.workingGroupId;
}

function getEditorStates(props, item) {
   const states = {
      [FieldNames.Name]: FormRow.FieldState.Normal,
      [FieldNames.Description]: FormRow.FieldState.Normal,
      [FieldNames.Sort]: FormRow.FieldState.Normal,
      [FieldNames.Version]: FormRow.FieldState.Normal,
      [FieldNames.LastUpdateTime]: FormRow.FieldState.Hidden,
      [FieldNames.LastUpdatingUserId]: FormRow.FieldState.Hidden
   };
   if (item.releaseStatus >= Utils.ReleaseStatus.Released) {
      return {
         ...states,
         [FieldNames.Name]: FormRow.FieldState.Disabled,
         [FieldNames.Description]: FormRow.FieldState.Disabled
      };
   }
   return states;
};

function updateInitialFilter(props, filters) {
   filters[FieldNames.Search] = "";
   filters.exclude = [
      Utils.FieldNames.ProfileGroupId,
      FieldNames.SearchDescription
   ];
};

function updateBeforeApplyFilters(filters, items) {
   items.map(ii => {
      ii.search = (!filters.searchDescription) ? ii.name : ii.description;
      return ii;
   });
};

export const CategoryList = (props) => {
  
   const {
      parent,
      url,
      containerName,
      storageKey
   } = props;

   const columns = [{ name: FieldNames.InlineDescription, width: "8" }];
   const sortColumn = { name: FieldNames.Sort };

   return (
      <MultiSelectList
         {...props}
         pageName={PageName}
         filterByUserContext={true}
         containerName={containerName}
         storageKey={storageKey}
         parent={parent}
         url={url}
         pathApiUrl={PageApiPath}
         fieldDefinitions={FieldDefinitions}
         toJson={toJson}
         renderFieldValue={renderFieldValue}
         initializeNewItem={initializeNewItem}
         updateInitialFilter={updateInitialFilter}
         updateBeforeApplyFilters={updateBeforeApplyFilters}
         columns={columns}
         sortColumn={sortColumn}
      />);
}

export const CategoryPage = (props) => {

   const {
      parent,
      url,
      containerName,
      storageKey
   } = props;

   const getEditorOptions = useCallback((context, item) => {
      const releaseStatuses = Object.keys(Utils.ReleaseStatus).map((ii) => {
         return {
            id: Utils.ReleaseStatus[ii],
            name: Locales.getDisplayText(`releaseStatus_${ii}`)
         };
      });
      return {
         [FieldNames.ReleaseStatus]: releaseStatuses
      };
   }, []);

   return (
      <SingleItemPage
         {...props}
         pageName={PageName}
         pathApiUrl={PageApiPath}
         containerName={containerName}
         storageKey={storageKey}
         parent={parent}
         url={url}
         fieldDefinitions={FieldDefinitions}
         toJson={toJson}
         childDefinitions={ChildDefinitions}
         renderFieldValue={renderFieldValue}
         getEditorOptions={getEditorOptions}
         getEditorStates={getEditorStates}
         initializeNewItem={initializeNewItem}
      />);
}
