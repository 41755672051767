import React from "react";

import * as FormRow from "./FormRow.js";
import * as Utils from "./Utils.js";
import { MultiSelectList } from "./MultiSelectList";
import { SingleItemPage } from "./SingleItemPage";

const PageCacheName = Utils.PageNames.Persons;
const PageApiPath = Utils.PagePaths.Persons;
const PageName = PageCacheName;

export const FieldNames = {
   Name: Utils.FieldNames.Name,
   Email: "email"
};

const FieldDefinitions = [
   {
      name: FieldNames.Name,
      defaultValue: "",
      viewControlType: FormRow.FieldType.Label,
      editControlType: FormRow.FieldType.TextInput,
      maxTextLength: 40
   },
   {
      name: FieldNames.Email,
      defaultValue: "",
      viewControlType: FormRow.FieldType.Label,
      editControlType: FormRow.FieldType.TextInput,
      maxTextLength: 60
   }
];

function renderFieldValue(context, name, item, field) {
   switch (name) {
      case FieldNames.Name: {
         let displayText = item[name];
         const index = displayText.indexOf("@");
         if (index > 0) {
            displayText = displayText.substr(0, index);
            displayText = displayText.replace(".", " ");
         }
         return <FormRow.LinkField
            id={item.id}
            displayText={displayText}
            path={PageApiPath}
            parentPath={context.location}
            noLink={context.noLink}
         />;
      }
      case FieldNames.Email: {
         let email = item[name];
         if (!email || context.user?.isEditor) {
            return email;
         }
         const index = email.indexOf("@");
         if (index > 0) {
            return `******${email.substr(index)}`;
         }
         return "******";
      }
      default: {
         return item[name];
      }
   }
}

function toJson(item) {
   return {
      id: item.id,
      name: item.name,
      email: item.email
   }
};

function initializeNewItem(props, newItem) {
   const {
      item
   } = props;
   if (item?.name) newItem.name = `(Copy) ${item.name}`;
   if (item?.email) newItem.email = item.email;
}

export const PersonList = (props) => {
   const {
      parent,
      url,
      containerName,
      storageKey,
   } = props;

   const columns = [{ name: FieldNames.Email, width: 5 }];
   const sortColumn = { name: FieldNames.Name };

   return (
      <MultiSelectList
         {...props}
         pageName={PageName}
         containerName={containerName}
         storageKey={storageKey}
         parent={parent}
         url={url}
         pathApiUrl={PageApiPath}
         fieldDefinitions={FieldDefinitions}
         toJson={toJson}
         renderFieldValue={renderFieldValue}
         initializeNewItem={initializeNewItem}
         columns={columns}
         sortColumn={sortColumn}
      />);
}

export const PersonPage = (props) => {

   const {
      parent,
      url,
      containerName,
      storageKey
   } = props;

   return (
      <SingleItemPage
         {...props}
         pageName={PageName}
         pathApiUrl={PageApiPath}
         containerName={containerName}
         storageKey={storageKey}
         parent={parent}
         url={url}
         fieldDefinitions={FieldDefinitions}
         toJson={toJson}
         renderFieldValue={renderFieldValue}
         initializeNewItem={initializeNewItem}
      />);
}
