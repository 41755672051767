import React, { useCallback, useState, useEffect, useRef } from "react";

import * as FormRow from "./FormRow.js";
import * as Utils from "./Utils.js";
import * as Locales from "./locales";
import { MultiSelectList } from "./MultiSelectList";
import { SingleItemPage } from "./SingleItemPage";
import { FileUpload } from "./FileUpload.js";

const PageCacheName = Utils.PageNames.DocumentVersions;
const PageApiPath = Utils.PagePaths.DocumentVersions;
const PageName = PageCacheName;

export const FieldNames = {
   Name: Utils.FieldNames.Name,
   Version: "version",
   ReleaseStatus: "releaseStatus",
   PublicationDate: "publicationDate",
   HasTestSpecification: "hasTestSpecification",
   SchemaUrl: "schemaUrl",
   StorageFileName: "storageFileName",
   Topics: "topics",
   DocumentId: "documentId",
   ReviewSummary: "reviewSummary",
   ReviewDetails: "reviewDetails",
   PermanentUrl: "permanentUrl"
};

const FieldDefinitions = [
   {
      name: FieldNames.Name,
      defaultValue: "",
      viewControlType: FormRow.FieldType.Label,
      editControlType: FormRow.FieldType.TextInput,
      maxTextLength: 80
   },
   {
      name: FieldNames.Version,
      defaultValue: "1.00",
      viewControlType: FormRow.FieldType.Label,
      editControlType: FormRow.FieldType.TextInput,
      maxTextLength: 40
   },
   {
      name: FieldNames.ReleaseStatus,
      defaultValue: 2,
      viewControlType: FormRow.FieldType.Label,
      editControlType: FormRow.FieldType.OptionSet
   },
   {
      name: FieldNames.PublicationDate,
      defaultValue: Utils.getDateAsString(),
      viewControlType: FormRow.FieldType.Label,
      editControlType: FormRow.FieldType.DateTimePicker
   },
   {
      name: FieldNames.PermanentUrl,
      defaultValue: "",
      viewControlType: FormRow.FieldType.Label,
      editControlType: FormRow.FieldType.TextInput,
      maxTextLength: 80
   },
   {
      name: FieldNames.HasTestSpecification,
      defaultValue: false,
      viewControlType: FormRow.FieldType.Label,
      editControlType: FormRow.FieldType.CheckBox
   },
   {
      name: FieldNames.StorageFileName,
      defaultValue: null,
      viewControlType: FormRow.FieldType.Label,
      editControlType: FormRow.FieldType.Label
   },
   {
      name: FieldNames.Topics,
      defaultValue: "",
      viewControlType: FormRow.FieldType.BlockLabel,
      editControlType: FormRow.FieldType.TextArea,
      showLabel: true
   },
   {
      name: FieldNames.ReviewSummary,
      defaultValue: "",
      viewControlType: FormRow.FieldType.Label,
      editControlType: FormRow.FieldType.TextInput,
      maxTextLength: 80
   },
   {
      name: FieldNames.ReviewDetails,
      defaultValue: "",
      viewControlType: FormRow.FieldType.BlockLabel,
      editControlType: FormRow.FieldType.TextArea,
      showLabel: true
   }
];

const CommandDefinitions = [
   {
      name: "UploadDocument",
      renderCommand: (props) =>
         <FileUpload
            {...props}
            caption={"documentVersions_UploadDocument"}
            dialogYesText={"documentVersions_Upload"}
            uploadUrl={`api/storage/upload/${props.item.id}`}
            onCompleted={(result) => props.onCompleted(
               { ...props.item, storageFileName: result.name, storageFileId: result.id })
            }
         />
   },
   {
      name: "DeleteDocument",
      url: `/storage/delete/`,
      confirmText: () => Locales.getHelpText("documentVersions_DeleteDocumentConfirmText")
   }
];

const DownloadInfo = (props) => {
   const {
      item
   } = props;

   const [fileInfo, setFileInfo] = useState(null);
   const itemId = item?.id;
   const storageFileId = item?.storageFileId;
   const mounted = useRef(true);

   console.error(`DownloadInfo ${itemId} ${storageFileId}`)

   useEffect(() => {
      return () => {
         mounted.current = false;
      }
   }, []);

   // fetch
   useEffect(() => {
      fetch(itemId);
      async function fetch(id) {
         const response = await Utils.httpGet(`api/storage/file/${id}`);
         if (!mounted.current) {
            return;
         }
         if (response && !response.failed) {
            setFileInfo(response.result);
         }
         else {
            setFileInfo({ name: response?.errorText ?? ""});
         }
      }
   }, [itemId, storageFileId]);

   if (!fileInfo?.name) {
      return null;
   }
   return <div>
      <span className="me-1">{fileInfo.name}</span>|
      <span className="mx-1">{Utils.getDateAsString(fileInfo.modifiedTime)}</span>|
      <span className="ms-1">{Utils.getBytesAsString(fileInfo.size)}</span>
   </div>;
}

function renderFieldValue(context, name, item, field) {
   switch (name) {
      case FieldNames.Name: {
         return <FormRow.LinkField
            id={item.id}
            displayText={item.name}
            path={Utils.PagePaths.DocumentVersions}
            location={context.location}
            noLink={context.noLink}
         />;
      }
      case FieldNames.PublicationDate: {
         if (!item.publicationDate) {
            return null;
         }
         return Utils.getDateAsString(new Date(item.publicationDate));
      }
      case FieldNames.DocumentId: {
         let doc = Utils.findInCache(Utils.PagePaths.Documents, 0, item.documentId);
         return <FormRow.LinkField
            id={item.documentId}
            displayText={(doc) ? doc.name : item.documentId}
            path={Utils.PagePaths.Documents}
            parentPath={context.location}
            noLink={context.noLink}
         />;
      }
      case FieldNames.HasTestSpecification: {
         return <FormRow.CheckBoxField value={item.hasTestSpecification} />;
      }
      case FieldNames.StorageFileName: {
         if (item.storageFileName) {
            return <DownloadInfo item={item} />;
         }
         return null;
      }
      case FieldNames.ReleaseStatus: {
         const name = Object.keys(Utils.ReleaseStatus).find((ii) => Utils.ReleaseStatus[ii] === item.releaseStatus);
         return Locales.getDisplayText(`releaseStatus_${name}`);
      }
      default: {
         return item[name];
      }
   }
}

function toJson(item) {
   return {
      id: item.id,
      name: item.name,
      version: item.version,
      publicationDate: item.publicationDate ?? undefined,
      releaseStatus: item.releaseStatus,
      hasTestSpecification: item.hasTestSpecification,
      schemaUrl: item.schemaUrl,
      downloadUrl: item.downloadUrl,
      topics: item.topics,
      documentId: item.documentId,
      lastUpdateTime: Utils.getDate(item.lastUpdateTime),
      reviewSummary: item.reviewSummary,
      reviewDetails: item.reviewDetails,
      storageFileName: item.storageFileName,
      storageFileId: item.storageFileId,
      permanentUrl: item.permanentUrl
   }
}

function initializeNewItem(props, newItem) {
   const {
      item,
      pageName,
      parent
   } = props;
   newItem.version = "1.00";
   newItem.publicationDate = Utils.getDateAsString(new Date());
   newItem.releaseStatus = Utils.ReleaseStatus.Draft;
   if (parent && pageName === Utils.PageNames.Documents) {
      newItem.name = parent.name;
      newItem.documentId = parent.id;
   }
   if (item?.documentId) newItem.documentId = item.documentId;
   if (item?.name) newItem.name = `${item.name} (Copy)`;
   if (item?.version) newItem.version = `${item.version}`;
   if (item?.schemaUrl) newItem.schemaUrl = item.schemaUrl;
   if (item?.downloadUrl) newItem.downloadUrl = item.downloadUrl;
   if (item?.permanentUrl) newItem.permanentUrl = null;
};

function getEditorStates(context, item) {
   const states = {
      [FieldNames.ReleaseStatus]: FormRow.FieldState.Normal,
      [FieldNames.PermanentUrl]: FormRow.FieldState.Normal,
      [FieldNames.ReviewSummary]: FormRow.FieldState.Hidden,
      [FieldNames.ReviewDetails]: FormRow.FieldState.Hidden
   };
   if (item?.releaseStatus === Utils.ReleaseStatus.ReleaseCandidate) {
      return {
         ...states,
         [FieldNames.ReviewSummary]: FormRow.FieldState.Normal,
         [FieldNames.ReviewDetails]: FormRow.FieldState.Normal
      };
   }
   return states;
};

export const DocumentVersionList = (props) => {
  
   const {
      parent,
      url,
      containerName,
      storageKey
   } = props;

   const columns = [
      { name: FieldNames.Version, width: "2" },
      { name: FieldNames.ReleaseStatus, width: "2" },
      { name: FieldNames.PublicationDate, width: "2" }
   ];

   const sortColumn = { name: FieldNames.Version, descending: true };

   return (
      <MultiSelectList
         {...props}
         pageName={PageName}
         containerName={containerName}
         storageKey={storageKey}
         parent={parent}
         url={url}
         pathApiUrl={PageApiPath}
         fieldDefinitions={FieldDefinitions}
         toJson={toJson}
         renderFieldValue={renderFieldValue}
         initializeNewItem={initializeNewItem}
         columns={columns}
         sortColumn={sortColumn}
      />);
}

export const DocumentVersionPage = (props) => {

   const {
      parent,
      url,
      containerName,
      storageKey
   } = props;

   const getEditorOptions = useCallback((context, item) => {
      const releaseStatuses = Object.keys(Utils.ReleaseStatus).map((ii) => {
         return {
            id: Utils.ReleaseStatus[ii],
            name: Locales.getDisplayText(`releaseStatus_${ii}`)
         };
      });
      return {
         [FieldNames.ReleaseStatus]: releaseStatuses
      };
   }, []);

   return (
      <SingleItemPage
         {...props}
         pageName={PageName}
         pathApiUrl={PageApiPath}
         containerName={containerName}
         storageKey={storageKey}
         parent={parent}
         url={url}
         fieldDefinitions={FieldDefinitions}
         toJson={toJson}
         commandDefinitions={CommandDefinitions}
         commandsExpanded={true}
         renderFieldValue={renderFieldValue}
         initializeNewItem={initializeNewItem}
         getViewerStates={getEditorStates}
         getEditorStates={getEditorStates}
         getEditorOptions={getEditorOptions}
      />);
}
