import React, { useState, useEffect, useCallback, useReducer } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { ItemList } from "./ItemList";

import * as Utils from "./Utils.js";
import * as ItemForm from "./ItemForm";

function getUrlForList(props) {
   const {
      user,
      filterByUserContext,
      url,
      listEditUrl,
      parentId,
      pathApiUrl,
      inListEditMode,
      updateUrlToUse
   } = props;
   let urlToUse = url ?? pathApiUrl;
   if (!urlToUse || (parentId !== undefined && parentId <= 0)) {
      return null;
   }
   if (inListEditMode) {
      urlToUse = listEditUrl ?? pathApiUrl;
      if (urlToUse.endsWith('0')) {
         urlToUse += parentId;
      }
   }
   else if (parentId !== undefined) {
      urlToUse = `${urlToUse}${parentId}/`
   }
   urlToUse = (updateUrlToUse) ? updateUrlToUse(props, urlToUse) : urlToUse;
   if (filterByUserContext && urlToUse.lastIndexOf("pg=") < 0 && user) {
      urlToUse += ((urlToUse.lastIndexOf('?') > 0) ? '&' : '?');
      urlToUse += `pg=${user.profileGroupId ?? Utils.DefaultProfileGroup}&rs=${user.releaseStatus ?? Utils.ReleaseStatus.Released}`
   }
   if (inListEditMode && filterByUserContext && urlToUse.lastIndexOf("all=") < 0) {
      urlToUse += `&all=1`;
   }
   return urlToUse;
}

function getInitialFilters(props) {

   const {
      user,
      storageKey,
      definitions,
      query,
      filters,
      isChildList,
      reset
   } = props;

   let defaultFilters = (isChildList) ?
      { expanded: true } :
      { expanded: true, [Utils.FieldNames.ProfileGroupId]: user?.profileGroupId }

   if (!reset) {
      defaultFilters = Utils.load(storageKey + "/filters", defaultFilters);
   }

   if (!Utils.isNullId(defaultFilters[Utils.FieldNames.ProfileGroupId])) {
      defaultFilters[Utils.FieldNames.ProfileGroupId] = user?.profileGroupId;
   }

   if (definitions) {
      definitions.map(ii => {
         if (!defaultFilters[ii.name]) defaultFilters[ii.name] = ii.defaultValue;
         return ii.defaultValue;
      });
   }

   let initialFilters = (filters)
      ? {
         ...defaultFilters,
         ...filters
      } :
      defaultFilters;

   if (!initialFilters.exclude) {
      initialFilters.exclude = [];
   }

   if (!initialFilters.disabled) {
      initialFilters.disabled = {};
   }

   if (!initialFilters.unsetDataFetched) {
      initialFilters.unsetDataFetched = [];
   }

   if (query) {
      initialFilters = defaultFilters;
      initialFilters.disabled = {};

      if (initialFilters.expanded === undefined) {
         initialFilters.expanded = true;
      }

      const keys = Object.keys(initialFilters);

      keys.map((ii) => {
         let field = query.get(ii);
         if (field) {
            const number = Number(field);
            initialFilters[ii] = (number || number === 0) ? number : field;
            initialFilters["disabled"] = { ...initialFilters.disabled, [ii]: true };
         }
         return true;
      });

      Utils.save(storageKey + "/filters", initialFilters);
   }

   return initialFilters;
}

function queueReducer(state, action) {

   const {
      urlsInQueue,
      urlInUse,
      sequence,
      controller
   } = state

   const {
      type,
      url,
      isChildList,
      inListEditMode,
      listEditFilter,
      parent,
      selectedItems
   } = action

   switch (type) {
      case 'UPDATE': {
         return {
            ...state,
            isChildList,
            inListEditMode,
            listEditFilter,
            selectedItems,
            parent
         }
      }
      case 'QUEUE': {
         if (urlsInQueue.length > 0) {
            if (urlsInQueue[urlsInQueue.length - 1] === url) {
               return state;
            }
         }
         if (urlsInQueue.length === 0 && controller) {
            controller.abort();
         }
         // console.error(`${type}: ${url}`);
         return {
            ...state,
            urlsInQueue: [...urlsInQueue, url]
         }
      }
      case 'START': {
         if (!urlsInQueue?.length) {
            return state;
         }
         const nextUrl = urlsInQueue[0];
         // console.error(`${type}: ${nextUrl}`);
         return {
            ...state,
            urlInUse: nextUrl,
            urlsInQueue: urlsInQueue.slice(1),
            sequence: sequence,
            controller: new AbortController()
         }
      }
      case 'DONE': {
         if (!urlInUse || !action.sequence || action.sequence < sequence) {
            return state;
         }
         // console.error(`${type}: ${urlInUse}`);
         return {
            ...state,
            urlInUse: null,
            controller: null,
            sequence: sequence + 1
         }
      }
      default:
      {
         return state;
      }
   }
}

export const MultiSelectList = (props) => {
   const {
      user,
      filterByUserContext,
      anyoneCanEdit,
      pageName,
      containerName,
      storageKey,
      pathApiUrl,
      parent,
      url,
      listEditUrl,
      listEditFilter,
      rowUpdateUrl,
      updateUrlToUse,
      fieldDefinitions,
      toJson,
      renderFieldValue,
      defaultFilters,
      filterDefinitions,
      updateInitialFilter,
      fetchFilterData,
      getFilterEditorOptions,
      getFilterEditorStates,
      getViewerStates,
      updateBeforeApplyFilters,
      initializeNewItem,
      updateAfterChange,
      columns,
      useMultiSelect,
      allowItemUpdate,
      noCreate,
      noDelete,
      viewDetailsUrl,
      onSelectModeChanged
   } = props;

   const [queue, dispatch] = useReducer(queueReducer, {
      urlsInQueue: [],
      sequence: 1,
      isChildList: false,
      inListEditMode: false,
      listEditFilter: listEditFilter,
      selectedItems: [],
      parent: parent
   });

   const [items, setItems] = useState([]);
   const [selectedItems, setSelectedItems] = useState([]);
   const [showSelectView, setShowSelectView] = useState(props.showSelectView);
   const [dataFetched, setDataFetched] = useState(false);
   const [initializeComplete, setInitializeComplete] = useState(false);
   const [waitingForData, setWaitingForData] = useState(true);
   const [error, setError] = useState(props.error);
   const [filters, setFilters] = useState({});
   const [editingAllowed, setEditingAllowed] = useState(false);
   const [sortModeEnabled, setSortModeEnabled] = useState(false);
   const [itemEditModeEnabled, setItemEditModeEnabled] = useState(false);
   const [sortColumn, setSortColumn] = useState(props.sortColumn);
   const userProfileGroupId = user?.profileGroupId;
   const userReleaseStatus = user?.releaseStatus;
   const isEditor = Utils.isEditor(parent);
   const parentId = parent?.id;
   const isChildList = (url && parent) ? true : false;
   const inListEditMode = (isChildList && showSelectView) ? true : false;
   const location = useLocation();
   const history = useHistory();
   const context = history.location.state?.item;
   const effectiveStorageKey = storageKey ?? (containerName ?? pageName);

   // update sort.
   useEffect(() => {
      var firstUrl = Utils.load("firstUrl");
      if (!firstUrl) {
         const query = new URLSearchParams(location.search);
         const profileGroupId = query.get("pg");
         const releaseStatus = query.get("rs");
         let user = Utils.getCurrentUser();
         if (profileGroupId || releaseStatus) {
            if (profileGroupId) {
               user = { ...user, profileGroupId: Number(profileGroupId) };
            }
            if (releaseStatus) {
               user = { ...user, releaseStatus: Number(releaseStatus) };
            }
            Utils.save("firstUrl", location.pathname);
            Utils.setCurrentUser(user, true);
         }
      }
   }, [location]);

   // update sort.
   useEffect(() => {
      setSortColumn(props.sortColumn);
   }, [props.sortColumn]);

   // update eror
   useEffect(() => {
      setError(props.error);
   }, [props.error]);

   // update editor access.
   useEffect(() => {
      setEditingAllowed((isEditor || anyoneCanEdit) ? true : false);
   }, [anyoneCanEdit, isEditor]);

   // toggle select view.
   useEffect(() => {
      if (updateAfterChange) {
         updateAfterChange(parent, items);
      }
   }, [parent, items, updateAfterChange]);

   // initialize filters.
   const initializeFilters = useCallback((reset) => {
      let filters = getInitialFilters({
         user,
         storageKey: effectiveStorageKey,
         definitions: filterDefinitions,
         query: (!isChildList) ? new URLSearchParams(location.search) : null,
         filters: defaultFilters,
         isChildList,
         reset
      });
      if (updateInitialFilter) {
         updateInitialFilter(context, filters);
      }
      setFilters(filters);
   }, [effectiveStorageKey, user, filterDefinitions, context, isChildList, location, defaultFilters, updateInitialFilter]);

   // re-fetch after user change.
   useEffect(() => {
      setError(null);
      setDataFetched(false);
      initializeFilters(true);
   }, [userProfileGroupId, userReleaseStatus, initializeFilters]);

   // handles row expansion
   const renderExpanded = useCallback((context) => {
      return (<ItemForm.ItemForm
         {...context}
         pageName={pageName}
         containerName={containerName}
         definitions={fieldDefinitions}
         getViewerStates={getViewerStates}
         renderFieldValue={renderFieldValue}
         toJson={toJson}
      />);
   }, [fieldDefinitions, renderFieldValue, getViewerStates, containerName, pageName, toJson]);

   // render filters.
   const renderFilters = useCallback((context) => {
      if (filterDefinitions) {
         return (<ItemForm.ItemFilterForm
            {...context}
            pageName={pageName}
            containerName={containerName}
            definitions={filterDefinitions}
            renderFieldValue={renderFieldValue}
            noButtons={true}
            getEditorOptions={getFilterEditorOptions}
            getEditorStates={getFilterEditorStates}
            inListEditMode={inListEditMode}
            items={items}
         />);
      }
      return null;
   }, [filterDefinitions, renderFieldValue, pageName, containerName, getFilterEditorOptions, getFilterEditorStates, inListEditMode, items]);

   // fetch data needed for filters.
   useEffect(() => {
      fetch();
      async function fetch() {
         if (fetchFilterData) {
            setDataFetched(true);
            setWaitingForData(true);
            await fetchFilterData();
         }
         initializeFilters();
         if (fetchFilterData) {
            setWaitingForData(false);
            setDataFetched(false);
         }
         setInitializeComplete(true);
      }
   }, [fetchFilterData, initializeFilters]);

   // parent change.
   useEffect(() => {
      if (parentId && initializeComplete) {
         setError(null);
         setShowSelectView(false);
         setDataFetched(false);
      }
   }, [url, parentId, initializeComplete]);

   // fetch
   useEffect(() => {
      dispatch({ type: 'UPDATE', parent, selectedItems, isChildList, inListEditMode, listEditFilter });
   }, [parent, selectedItems, isChildList, inListEditMode, listEditFilter]);

   // fetch
   useEffect(() => {
      const { urlInUse, urlsInQueue, controller, sequence } = queue;
      if (urlInUse || !urlsInQueue?.length) {
         return;
      }
      const urlToUse = `api${urlsInQueue[0]}`;
      setDataFetched(true);
      setWaitingForData(true);
      dispatch({ type: 'START', url: urlsInQueue[0] })
      Utils.httpGet(urlToUse, controller)
      .then((response) => {
         if (response?.errorCode) {
            setError(response);
            setItems([]);
         }
         else {
            setItems(oldItems => {
               let newItems = Utils.processGetResponseAndUpdateList(urlToUse, response, oldItems);
               if (queue.isChildList && queue.inListEditMode) {
                  queue.selectedItems.map(ii => {
                     var item = newItems.find(jj => jj.id === ii);
                     if (!item) {
                        item = oldItems.find(jj => jj.id === ii);
                        if (item) {
                           newItems.push(item);
                        }
                     }
                     item.selected = !!item;
                     return ii;
                  });
               }
               else if (queue.isChildList) {
                  setSelectedItems(newItems.map(ii => {
                     ii.selected = false;
                     return ii.id;
                  }));
               }
               if (queue.inListEditMode && queue.listEditFilter) {
                  newItems = queue.listEditFilter(queue.parent, newItems);
               }
               return newItems;
            })
         }
      })
      .catch((error) => console.error(error))
      .finally(() => {
         setWaitingForData(false);
         dispatch({ type: 'DONE', sequence: sequence });
      });
   }, [queue]);

   // fetch
   useEffect(() => {
      if (!dataFetched && initializeComplete) {
         const urlToUse = getUrlForList({
            user,
            filters,
            filterByUserContext,
            url,
            listEditUrl,
            parentId,
            pathApiUrl,
            inListEditMode,
            updateUrlToUse
         });
         (urlToUse) ? dispatch({ type: 'QUEUE', url: urlToUse }): setDataFetched(true);
      }
   },
   [
      user,
      filterByUserContext,
      parentId,
      parent,
      url,
      listEditUrl,
      listEditFilter,
      isChildList,
      pathApiUrl,
      dataFetched,
      inListEditMode,
      filters,
      selectedItems,
      initializeComplete,
      updateUrlToUse
   ]);

   // create
   const createNewItem = () => {
      let item = {};
      if (initializeNewItem) {
         initializeNewItem(
            {
               user,
               filters,
               pageName: containerName ?? pageName,
               parent,
               items
            },
            item);
      }
      item = Utils.navigateForward(
         history,
         `${pathApiUrl}0`,
         location.pathname,
         (toJson) ? toJson(item) : item);
      if (item) {
         item.key = Utils.nextKey();
         item.expanded = true;
         item.editing = true;
         item.new = true;
         item.hasWriteAccess = true;
      }
   };

   // toggle select mode for multi-select
   const toggleSelectMode = useCallback(async (e) => {
      e.preventDefault();
      setError(null);
      setDataFetched(false);
      setShowSelectView(e => {
         if (onSelectModeChanged) {
            const newFilters = onSelectModeChanged({ filters, selectModeEnabled: !e });
            if (newFilters) {
               setFilters(newFilters);
            }
         }
         return !e
      });
   }, [onSelectModeChanged, filters]);

   // select item for multi-select
   const selectItem = useCallback(async (e, item) => {
      e.preventDefault();
      if (await Utils.multiSelectListSelectItem(url, parent, item)) {
         setItems((oldItems) => oldItems.map((ii) => ((ii.key !== item.key) ? ii : item)));
      }
      setError(item);
   }, [url, parent]);

   // enable sort edit mode.
   const onSortModeEnabled = useCallback(async (e) => {
      e.preventDefault();
      setSortModeEnabled((enabled) => {
         if (!enabled) {
            setSortColumn(Utils.FieldNames.Sort);
            setError(null);
         }
         return !enabled;
      });
   }, []);

   // enable row edit mode.
   const onItemEditModeEnabled = useCallback(async (e) => {
      e.preventDefault();
      setItemEditModeEnabled((enabled) => {
         if (!enabled) {
            setError(null);
            setSortColumn(Utils.FieldNames.Sort);
         }
         return !enabled;
      });
   }, []);

   // update sort order.
   const onSortOrderChanged = useCallback(async (list) => {
      const results = await Utils.httpPost(`api${pathApiUrl}sort`, list);
      if (results?.errorCode) {
         setError(results);
         return;
      }
      await setItems(oldItems => {
         return oldItems.map(ii => {
            const sort = results.records.find(jj => jj.id === ii.id);
            if (sort) {
               ii.sort = sort.sort;
            }
            return ii;
         });
      });
   }, [pathApiUrl]);

   // update
   const updateItem = useCallback(async (context) => {
      const { field, item } = context;
      if (!item || !field || !itemEditModeEnabled) {
         return null;
      }
      item[field] = (item[field]) ? false : true;
      const urlToUse = getUrlForList({
         user,
         parentId,
         url: rowUpdateUrl,
         pathApiUrl,
         updateUrlToUse,
         filters,
         inListEditMode
      });
      if (urlToUse) {
         let updatedItem = await Utils.simpleUpdateItem(urlToUse, item, true);
         if (updatedItem) {
            setItems((oldItems) => {
               return oldItems.map((ii) => ((ii.key !== updatedItem.key) ? ii : updatedItem));
            });
         }
      }
   }, [user, parentId, filters, inListEditMode, rowUpdateUrl, pathApiUrl, updateUrlToUse, itemEditModeEnabled]);

   // delete
   const deleteItem = useCallback(async (item) => {
      if (useMultiSelect) {
         item.selected = true;
         if (await Utils.multiSelectListSelectItem(url, parent, item)) {
            setItems((oldItems) => {
               const newItems = oldItems.filter((ii) => ii.key !== item.key);
               setSelectedItems(newItems.map(ii => ii.id));
               return newItems;
            });
         }
         setError(item);
      }
      else {
         const newItem = await Utils.simpleDeleteItem(pathApiUrl, item);
         if (newItem?.errorCode) {
            return;
         }
         setItems((oldItems) => oldItems.filter((ii) => ii.key !== item.key));
      }
   }, [useMultiSelect, pathApiUrl, url, parent]);

   // filters changed.
   const filtersChanged = useCallback((newFilters) => {
      setFilters((oldFilters) => {
         if (oldFilters.unsetDataFetched) {
            oldFilters.unsetDataFetched.every(name => {
               if (oldFilters[name] !== newFilters[name]) {
                  setDataFetched(false);
                  return false;
               }
               return true;
            });
         }
         Utils.save(`${effectiveStorageKey}/filters`, newFilters);
         return newFilters;
      });
   }, [effectiveStorageKey]);

   // apply filters
   const applyFilters = useCallback((newFilters, items) => {
      if (newFilters.search) {
         items.map(ii => {
            ii.search = (newFilters.searchDescription) ? ii.description : ii.name;
            return ii;
         });
      }
      if (newFilters.profileGroupId && items?.length) {
         if (!items[0].profileGroupId) {
            newFilters.profileGroupId = null;
         }
      }
      if (updateBeforeApplyFilters) {
         updateBeforeApplyFilters(newFilters, items);
      }
      const results = Utils.applyFilters(newFilters, items, null, false);
      return results;
   }, [updateBeforeApplyFilters]);

   // view details
   const viewDetails = useCallback(async (e, item) => {
      if (!item) {
         return null;
      }
      const urlToUse = getUrlForList({
         user,
         parentId,
         item,
         url: viewDetailsUrl,
         pathApiUrl,
         updateUrlToUse,
         doViewDetails: true
      });
      if (urlToUse) {
         Utils.navigateForward(history, urlToUse, location.pathname, item);
      }
   }, [viewDetailsUrl, history, location, user, parentId, pathApiUrl, updateUrlToUse]);

   return (
      <ItemList
         {...props}
         location={location}
         error={error}
         items={items}
         dataFetched={dataFetched && !waitingForData}
         editingEnabled={editingAllowed}
         storageKey={effectiveStorageKey}
         containerName={containerName ?? pageName}
         fields={fieldDefinitions}
         columns={columns}
         sortColumn={sortColumn}
         onItemCreated={(!noCreate) ? createNewItem : undefined}
         onItemDeleted={((!isChildList && !noDelete) ? deleteItem : undefined)}
         onItemUnlinked={((useMultiSelect && !inListEditMode) ? deleteItem : undefined)}
         selectModeEnabled={(useMultiSelect) ? showSelectView : undefined}
         toggleSelectMode={(useMultiSelect) ? toggleSelectMode : undefined}
         onSelectItem={(useMultiSelect) ? selectItem : undefined}
         sortModeEnabled={sortModeEnabled}
         onSortModeEnabled={(!useMultiSelect) ? onSortModeEnabled : undefined}
         onSortOrderChanged={(!useMultiSelect) ? onSortOrderChanged : undefined}
         itemEditModeEnabled={itemEditModeEnabled}
         toggleItemEditMode={(allowItemUpdate) ? onItemEditModeEnabled : undefined}
         onItemUpdated={(allowItemUpdate) ? updateItem : undefined}
         onViewDetail={(viewDetailsUrl) ? viewDetails : undefined}
         renderFieldValue={renderFieldValue}
         renderExpanded={renderExpanded}
         filters={filters}
         filtersChanged={filtersChanged}
         applyFilters={applyFilters}
         renderFilters={renderFilters}
      />);
}