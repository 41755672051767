import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';

import {
   Collapse,
   Container,
   Navbar,
   NavbarBrand,
   NavbarToggler,
   NavLink,
   Nav,
   UncontrolledDropdown,
   DropdownToggle,
   DropdownItem,
   DropdownMenu,
   NavbarText
} from 'reactstrap';

import './NavMenu.css';
import { LoginMenu } from "./LoginMenu";
import { PageTitle } from './PageTitle';

import * as Utils from "./Utils.js";
import * as Locales from "./locales";

export const NavMenu = (props) => {
   const [collapsed, setCollapsed] = useState(true);
   const [user, setUser] = useState(props.user);
   const [workingGroups, setWorkingGroups] = useState([]);
   const [profileGroups, setProfileGroups] = useState([]);
   const [workingGroup, setWorkingGroup] = useState(null);
   const [profileGroup, setProfileGroup] = useState(null);
   const [releaseStatus, setReleaseStatus] = useState(Utils.ReleaseStatus.Released);
   const [mainLogoUrl, setMainLogoUrl] = useState("/images/opclogo.jpg");

   useEffect(() => {
      const url = window.location.href;
      if (url.includes('staging') || url.includes('development')) setMainLogoUrl("/images/construction_barrier.png");
   }, []);

   useEffect(() => {
      const data = [
         { name: Utils.PagePaths.WorkingGroups, path: Utils.PagePaths.WorkingGroups },
         { name: Utils.PagePaths.ProfileGroups, path: Utils.PagePaths.ProfileGroups }
      ];
      async function fetch() {
         await Utils.fetchMultipleLists(data);
         const wg = Utils.findInCache(Utils.PagePaths.WorkingGroups);
         if (wg) {
            setWorkingGroups(wg);
         }
         const pg = Utils.findInCache(Utils.PagePaths.ProfileGroups);
         if (pg) {
            setProfileGroups(pg);
         }
      }
      fetch(data);
   }, [props.cacheVersion]);

   useEffect(() => {
      setUser(props.user);
      Utils.updateProfileGroupHistory(props.user, profileGroups, workingGroups);
   }, [props.user, profileGroups, workingGroups]);

   useEffect(() => {
      let wg;
      let pg;
      let rs;
      if (!user) {
         wg = workingGroups?.find((ii) => ii.shortName === "UACore");
         pg = profileGroups?.find((ii) => ii.name === "1.05" && ii.workingGroupId === wg?.id);
         rs = Utils.ReleaseStatus.Released;
      }
      else {
         pg = profileGroups.find((ii) => ii.id === user.profileGroupId);
         wg = workingGroups?.find((ii) => ii.id === (user.workingGroupId ?? pg?.workingGroupId));
         rs = user.releaseStatus;
      }
      setWorkingGroup(wg);
      setProfileGroup(pg);
      setReleaseStatus(rs)
   }, [user, workingGroups, profileGroups]);

   const toggleNavbar = () => setCollapsed(prevState => !prevState);

   const getNavLink = (name) => {
      const pathName = `${name.substr(0, 1).toUpperCase()}${name.substr(1)}`;
      return <NavLink tag={Link} to={Utils.PagePaths[pathName]}>{Locales.getDisplayText(`itemList_${name}`)}</NavLink>
   }

   async function updateContext(e, user, workingGroupId, profileGroupId, releaseStatusId) {
      e.preventDefault();
      let updatedUser = { ...user };
      if (user) {
         updatedUser.workingGroupId = (workingGroupId) ?? ((workingGroup?.id) ?? -1);
         updatedUser.profileGroupId = (profileGroupId) ?? ((profileGroup?.id) ?? -1);
         updatedUser.releaseStatus = (releaseStatusId) ?? releaseStatus;

         if (updatedUser.id > 1) {
            updatedUser = await Utils.httpPostAndUpdateList(`api/user/put/${user.id}`, updatedUser);
            if (updatedUser?.errorCode) {
               console.error(`[UpdateUser] ${updatedUser?.errorCode}: ${updatedUser?.errorText}`);
            }
         }

         Utils.setCurrentUser(updatedUser, true);
      }
   }

   const viewMenu = (!user?.isEditor) ?
      [
         Utils.PageNames.Profiles,
         Utils.PageNames.ConformanceUnits,
         Utils.PageNames.TestSuites,
         Utils.PageNames.WorkingGroups,
         Utils.PageNames.Documents
      ] :
      [
         Utils.PageNames.Profiles,
         Utils.PageNames.ProfileFolders,
         Utils.PageNames.Categories,
         Utils.PageNames.ConformanceGroups,
         Utils.PageNames.ConformanceUnits, 
         Utils.PageNames.TestSuites,
         Utils.PageNames.WorkingGroups,
         Utils.PageNames.Documents,
         Utils.PageNames.Persons,
         Utils.PageNames.Tags,
         Utils.PageNames.Users
      ];

   let logo = null;
   if (workingGroup?.logoUrl) {
      logo = <NavbarBrand className="m-0 p-0 pe-2" tag={Link} to={`${Utils.PagePaths.WorkingGroups}${workingGroup.id}`} >
         <img
            style={{ border: '2px solid white', background: 'white' }}
            src={`${workingGroup.logoUrl}`}
            height="46px"
            alt={`${workingGroup.name}`}
         />
      </NavbarBrand>
   }

   return (
      <header>
         <Navbar className="navbar-expand-sm navbar-toggleable-sm mt-sm-10">
            <Container className="p-0 m-0 me-1 fluid" style={{ maxWidth: '5000px' }}>
               <NavbarBrand className="m-0 p-0 ps-2 pe-2" tag={Link} to="/">
                  <img
                     style={{ border: '2px solid white', backgroundColor: 'white' }}
                     src={mainLogoUrl}
                     height="46px"
                     alt="OPC Foundation"
                  />
               </NavbarBrand>
               <NavbarText ><PageTitle cacheVersion={props.cacheVersion} /></NavbarText>
               <NavbarToggler onClick={toggleNavbar} className="me-2" />
               <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!collapsed} navbar>
                  <Nav className="ms-auto" navbar>
                     {logo}
                     <UncontrolledDropdown nav inNavbar>
                        <DropdownToggle nav caret>
                           {Locales.getDisplayText(
                              `${((workingGroup) ?
                                 workingGroup.shortName :
                                 Locales.getDisplayText("workingGroupId")
                              )} ${((profileGroup) ?
                                 profileGroup.name :
                                 Locales.getDisplayText("profileGroupId")
                              )}`)}
                        </DropdownToggle>
                        <DropdownMenu className="border-0" right>
                           <DropdownItem>
                              <NavLink tag={Link} to={`${Utils.PagePaths.Users}${(user?.id) ?? 1}?edit=1`}>
                                 {Locales.getDisplayText("changeGroup")}
                              </NavLink>
                           </DropdownItem>
                           <DropdownItem>
                              <NavLink tag={Link} to={`${Utils.PagePaths.WorkingGroups}${(workingGroup?.id) ?? ''}`}>
                                 {Locales.getDisplayText("workingGroupId")}
                              </NavLink>
                           </DropdownItem>
                           <DropdownItem>
                              <NavLink tag={Link} to={`${Utils.PagePaths.ProfileGroups}${(profileGroup?.id) ?? ''}`}>
                                 {Locales.getDisplayText("profileGroupId")}
                              </NavLink>
                           </DropdownItem>
                           <DropdownItem divider={true}></DropdownItem>
                           {Utils.getProfileGroupHistory().map((ii) => {
                              return (
                                 <DropdownItem key={ii.id} onClick={(e) => updateContext(e, user, ii.workingGroupId, ii.id, null)}>
                                    <span className="nav-item" >
                                       {ii.name}
                                    </span>
                                 </DropdownItem>);
                           })}
                        </DropdownMenu>
                     </UncontrolledDropdown>
                     <UncontrolledDropdown nav inNavbar>
                        <DropdownToggle nav caret>
                           {Locales.getDisplayText(`releaseStatus_${Object.keys(Utils.ReleaseStatus).find((ii) => Utils.ReleaseStatus[ii] === releaseStatus)}`)}
                        </DropdownToggle>
                        <DropdownMenu className="border-0" right>
                           {Utils.ReleaseStatusFilters.map((ii) => {
                              return (
                                 <DropdownItem key={ii.id} onClick={(e) => updateContext(e, user, null, null, ii.id)}>
                                    <span className="nav-item p-0">
                                       {Locales.getDisplayText(ii.name)}
                                    </span>
                                 </DropdownItem>);
                           })}
                        </DropdownMenu>
                     </UncontrolledDropdown>
                     <UncontrolledDropdown nav inNavbar>
                        <DropdownToggle nav caret>Views</DropdownToggle>
                        <DropdownMenu className="border-0" right>
                           {viewMenu.map(ii => { return <DropdownItem key={ii}>{getNavLink(ii)}</DropdownItem>; })}
                        </DropdownMenu>
                     </UncontrolledDropdown>
                     <LoginMenu user={user} />
                  </Nav>
               </Collapse>
            </Container>
         </Navbar>
      </header>
   );
}

export const FooterMenu = (props) => {
   return (
      <footer>
         <Navbar>
            <Container className="p-0 m-0 fluid" style={{ maxWidth: '5000px' }}>
               <NavbarText className="me-auto">
                  {`OPC Foundation, All Rights Reserved. © ${new Date().getFullYear()}`}
               </NavbarText>
               <NavbarText className="me-1">Report issues <a href='https://mantis.opcfoundation.org/set_project.php?project_id=81&amp;make_default=no' target='_blank' rel='noopener noreferrer'>here</a>.</NavbarText>
               <NavbarText className='text-muted'>{`${Utils.AppVersion}`}</NavbarText>
            </Container>
         </Navbar>
      </footer>
   );
}
