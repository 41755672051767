import React from "react";

import * as FormRow from "./FormRow.js";
import * as Utils from "./Utils.js";
import * as Locales from "./locales";
import { MultiSelectList } from "./MultiSelectList";
import { SingleItemPage } from "./SingleItemPage";
import { PersonList } from "./PersonPage";
import { DocumentList } from "./DocumentPage";
import { ProfileGroupList } from "./ProfileGroupPage";

const PageCacheName = Utils.PageNames.WorkingGroups;
const PageApiPath = Utils.PagePaths.WorkingGroups;
const PageName = PageCacheName;

export const FieldNames = {
   ShortName: "shortName",
   AccessGroupName: "accessGroupName",
   BaseProfileUri: "baseProfileUri",
   Description: "description",
   Organization: "organization",
   CollaborationUrl: "collaborationUrl",
   MarketingUrl: "marketingUrl",
   LogoUrl: "logoUrl",
   Classification: "classification",
   Markets: "markets",
   InlineDescription: "inlineDescription",
   Search: "search",
   SearchDescription: "searchDescription",
   Status: "status",
   KickoffDate: "kickoffDate",
   KickoffSummary: "kickoffSummary",
   KickoffDetails: "kickoffDetails"
};

export const ChildListNames = {
   Chairs: "chairs",
   Documents: "documents",
   ProfileGroups: "profileGroups"
};

export const WorkingGroupStatus = {
   Proposed: 1,
   Approved: 2,
   Active: 3,
   Completed: 4,
   Inactive: 5
}

const FieldDefinitions = [
   {
      name: Utils.FieldNames.Name,
      defaultValue: "",
      viewControlType: FormRow.FieldType.Label,
      editControlType: FormRow.FieldType.TextInput,
      maxTextLength: 80
   },
   {
      name: FieldNames.ShortName,
      defaultValue: "",
      viewControlType: FormRow.FieldType.Label,
      editControlType: FormRow.FieldType.TextInput,
      maxTextLength: 40
   },
   {
      name: FieldNames.AccessGroupName,
      defaultValue: "",
      viewControlType: FormRow.FieldType.Label,
      editControlType: FormRow.FieldType.TextInput,
      maxTextLength: 80,
      editorOnly: true
   },
   {
      name: FieldNames.BaseProfileUri,
      defaultValue: "http://opcfoundation.org/UA-Profile/",
      viewControlType: FormRow.FieldType.Label,
      editControlType: FormRow.FieldType.TextInput,
      maxTextLength: 80,
      editorOnly: true
   },
   {
      name: FieldNames.Organization,
      defaultValue: "",
      viewControlType: FormRow.FieldType.Label,
      editControlType: FormRow.FieldType.TextInput,
      maxTextLength: 80
   },
   {
      name: FieldNames.CollaborationUrl,
      defaultValue: "https://sites.google.com/opcfoundation.online/",
      viewControlType: FormRow.FieldType.Label,
      editControlType: FormRow.FieldType.TextInput,
      maxTextLength: 80
   },
   {
      name: FieldNames.MarketingUrl,
      defaultValue: "https://opcfoundation.org/markets-collaboration/",
      viewControlType: FormRow.FieldType.Label,
      editControlType: FormRow.FieldType.TextInput,
      maxTextLength: 80
   },
   {
      name: FieldNames.LogoUrl,
      defaultValue: "",
      viewControlType: FormRow.FieldType.Label,
      editControlType: FormRow.FieldType.TextInput,
      maxTextLength: 80,
      editorOnly: true
   },
   {
      name: FieldNames.Classification,
      defaultValue: "",
      viewControlType: FormRow.FieldType.ChipArray,
      editControlType: FormRow.FieldType.TextInput,
      maxTextLength: 80
   },
   {
      name: FieldNames.Markets,
      defaultValue: "",
      viewControlType: FormRow.FieldType.ChipArray,
      editControlType: FormRow.FieldType.TextInput,
      maxTextLength: 80
   },
   {
      name: Utils.FieldNames.Sort,
      defaultValue: 1,
      viewControlType: FormRow.FieldType.Label,
      editControlType: FormRow.FieldType.NumberInput,
      minValue: 1,
      maxValue: 10000,
      editorOnly: true
   },
   {
      name: FieldNames.Status,
      defaultValue: WorkingGroupStatus.Active,
      viewControlType: FormRow.FieldType.Label,
      editControlType: FormRow.FieldType.OptionSet
   },
   {
      name: FieldNames.Description,
      defaultValue: "",
      viewControlType: FormRow.FieldType.BlockLabel,
      editControlType: FormRow.FieldType.TextArea,
      showLabel: true
   },
   {
      name: FieldNames.KickoffDate,
      defaultValue: "",
      viewControlType: FormRow.FieldType.Label,
      editControlType: FormRow.FieldType.DateTimePicker
   },
   {
      name: FieldNames.KickoffSummary,
      defaultValue: "",
      viewControlType: FormRow.FieldType.Label,
      editControlType: FormRow.FieldType.TextInput,
      maxTextLength: 80
   },
   {
      name: FieldNames.KickoffDetails,
      defaultValue: "",
      viewControlType: FormRow.FieldType.BlockLabel,
      editControlType: FormRow.FieldType.TextArea,
      showLabel: true
   }
];

const FilterDefinitions = [
   {
      name: FieldNames.Search,
      defaultValue: "",
      editControlType: FormRow.FieldType.TextInput,
      maxTextLength: 40
   },
   {
      name: FieldNames.SearchDescription,
      defaultValue: false,
      editControlType: FormRow.FieldType.CheckBox
   }
];

const ChildDefinitions = [
   {
      name: ChildListNames.Documents,
      url: "/workinggroup/documents/",
      renderList: (props) => <DocumentList {...props} />
   },
   {
      name: ChildListNames.Chairs,
      url: "/workinggroup/chairs/",
      renderList: (props) => <PersonList {...props} useMultiSelect={true} />
   },
   {
      name: ChildListNames.ProfileGroups,
      url: "/workinggroup/profilegroups/",
      renderList: (props) => <ProfileGroupList {...props} enableEditSort={true} />
   }
];

function renderFieldValue(context, name, item, field) {
   const value = FormRow.renderFieldValue(context, name, item, field, Utils.PagePaths.WorkingGroups);
   if (value) {
      return value;
   }
   switch (name) {
      case Utils.FieldNames.Name: {
         return <FormRow.LinkField
            id={item.id}
            displayText={item.name}
            path={PageApiPath}
            noLink={context.noLink}
            parentPath={context.parentPath}
         />;
      }
      case FieldNames.CollaborationUrl:
      case FieldNames.MarketingUrl: {
         return <a
            className='m-0 p-0 nav-link'
            target='_blank'
            rel='noopener noreferrer'
            href={item[name]}
         >
            {item[name]}
         </a>;
      }
      case FieldNames.LogoUrl: {
         const url = item[name];
         if (url?.length) {
            return <img
               style={{ border: '2px solid white', background: 'white' }}
               src={url}
               height="46px"
               alt={item[Utils.FieldNames.Name]}
            />;
         }
         return url;
      }
      case FieldNames.InlineDescription: {
         return <label
            style={{ lineHeight: 1.2 }}
            className="pt-1 pb-0 pe-1 fluid w-100"
            dangerouslySetInnerHTML={{ __html: Utils.sanitizeString(item.description) }}>
         </label>
      }
      case FieldNames.Status: {
         return Utils.toString(WorkingGroupStatus, item.status, "workingGroupStatus");
      }
      case FieldNames.KickoffDate: {
         if (!item.kickoffDate?.length) {
            return null;
         }
         return Utils.getDateAsString(item.kickoffDate);
      }
      default: {
         return item[name];
      }
   }
}

function toJson(item) {
   return {
      id: item.id,
      name: item.name,
      shortName: item.shortName,
      accessGroupName: item.accessGroupName,
      description: item.description,
      baseProfileUri: item.baseProfileUri,
      organization: item.organization,
      collaborationUrl: item.collaborationUrl,
      marketingUrl: item.marketingUrl,
      logoUrl: item.logoUrl,
      classification: item.classification,
      markets: item.markets,
      sort: Number(item.sort),
      lastUpdateTime: Utils.getDate(item.lastUpdateTime),
      status: item.status,
      kickoffDate: item.kickoffDate,
      kickoffSummary: item.kickoffSummary,
      kickoffDetails: item.kickoffDetails
   }
};

function initializeNewItem(props, newItem) {
   const {
      item
   } = props;
   if (item) newItem.status = WorkingGroupStatus.Proposed;
   if (item?.name) newItem.name = `(Copy) ${item.name}`;
   if (item?.shortName) newItem.shortName = `(Copy) ${item.shortName}`;
   if (item?.accessGroupName) newItem.accessGroupName = item.accessGroupName;
   if (item?.baseProfileUri) newItem.baseProfileUri = item.baseProfileUri;
   if (item?.organization) newItem.organization = item.organization;
   if (item?.collaborationUrl) newItem.collaborationUrl = item.collaborationUrl;
   if (item?.classification) newItem.classification = item.classification;
   if (item?.markets) newItem.markets = item.markets;
   if (item?.sort) newItem.sort = item.sort + 1;
}

function getEditorOptions(context, item) {
   const workingGroupStatuses = Object.keys(WorkingGroupStatus).map((ii) => {
      return {
         id: WorkingGroupStatus[ii],
         name: Locales.getDisplayText(`workingGroupStatus_${ii}`)
      };
   });
   return {
      [FieldNames.Status]: workingGroupStatuses
   };
};

function getEditorStates(context, item) {
   const states = {
      [FieldNames.Status]: FormRow.FieldState.Normal,
      [FieldNames.KickoffDate]: FormRow.FieldState.Hidden,
      [FieldNames.KickoffSummary]: FormRow.FieldState.Hidden,
      [FieldNames.KickoffDetails]: FormRow.FieldState.Hidden
   };
   if (item?.status < WorkingGroupStatus.Active) {
      return {
         ...states,
         [FieldNames.KickoffDate]: FormRow.FieldState.Normal,
         [FieldNames.KickoffSummary]: FormRow.FieldState.Normal,
         [FieldNames.KickoffDetails]: FormRow.FieldState.Normal
      };
   }
   return states;
};

function updateInitialFilter(context, filters) {
   filters[FieldNames.Search] = "";
   filters.exclude = [
      Utils.FieldNames.ProfileGroupId,
      FieldNames.SearchDescription
   ];
};

function updateBeforeApplyFilters(filters, items) {
   if (!filters.searchDescription) {
      items.map(ii => {
         ii.search = `${ii.name} ${ii.shortName}`;
         return ii;
      });
   }
};

export const WorkingGroupList = (props) => {
  
   const {
      parent,
      url,
      containerName,
      storageKey
   } = props;

   const columns = [{ name: FieldNames.InlineDescription, width: 8 }];
   const sortColumn = { name: FieldNames.Name };

   return (
      <MultiSelectList
         {...props}
         pageName={PageName}
         containerName={containerName}
         storageKey={storageKey}
         parent={parent}
         url={url}
         pathApiUrl={PageApiPath}
         fieldDefinitions={FieldDefinitions}
         toJson={toJson}
         renderFieldValue={renderFieldValue}
         initializeNewItem={initializeNewItem}
         filterDefinitions={FilterDefinitions}
         updateInitialFilter={updateInitialFilter}
         updateBeforeApplyFilters={updateBeforeApplyFilters}
         columns={columns}
         sortColumn={sortColumn}
      />);
}

export const WorkingGroupPage = (props) => {

   const {
      parent,
      url,
      containerName,
      storageKey
   } = props;

   return (
      <SingleItemPage
         {...props}
         pageName={PageName}
         pathApiUrl={PageApiPath}
         containerName={containerName}
         storageKey={storageKey}
         parent={parent}
         url={url}
         fieldDefinitions={FieldDefinitions}
         toJson={toJson}
         childDefinitions={ChildDefinitions}
         renderFieldValue={renderFieldValue}
         getViewerStates={getEditorStates}
         getEditorStates={getEditorStates}
         getEditorOptions={getEditorOptions}
         initializeNewItem={initializeNewItem}
      />);
}
