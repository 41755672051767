import React, { useState, useEffect, useCallback } from "react";
import { Col, Container } from 'reactstrap';
import { ButtonRow, ErrorRow } from "./FormRow";

import * as Utils from "./Utils.js";

export function UpdatableForm(props) {
   const {
      anyoneCanEdit,
      onItemUpdated,
      noButtons,
      renderFields
   } = props;

   const [item, setItem] = useState(props.item);
   const [changed, setChanged] = useState(false);
   const [editingAllowed, setEditingAllowed] = useState(Utils.isEditor(props.item) || anyoneCanEdit);

   useEffect(() => {
      const userAccess = (Utils.isEditor(props.item) || props.anyoneCanEdit);
      setEditingAllowed(userAccess && (item.new || item.hasWriteAccess || item.hasWriteAccess === undefined));
      setItem(props.item);
   }, [props.user, props.item, item.hasWriteAccess, item.new, props.anyoneCanEdit]);

   useEffect(() => {
      if (changed && item && noButtons) {
         onItemUpdated(item);
         setChanged(false);
      }
   }, [changed, noButtons, item, onItemUpdated]);

   const onTextChanged = useCallback((e) => {
      if (!editingAllowed) {
         return;
      }
      const { name, value } = e.target;
      setItem(oldItem => {
         return { ...oldItem, [name]: value };
      });
      setChanged(true);
   }, [editingAllowed]);

   const onNumberChanged = useCallback((e, props) => {
      if (!editingAllowed) {
         return;
      }
      const { name, value } = e.target;
      let newValue = Number(value);
      if (newValue < props.min) {
         newValue = props.min;
      }
      if (newValue > props.max) {
         newValue = props.max;
      }
      setItem(oldItem => {
         return { ...oldItem, [name]: newValue};
      });
      setChanged(true);
   }, [editingAllowed]);

   const onDateTimeChanged = useCallback((e) => {
      if (!editingAllowed) {
         return;
      }
      const { name, value } = e;
      if (!(value instanceof Date) || isNaN(value)) {
         return;
      }
      setItem(oldItem => {
         return { ...oldItem, [name]: Utils.getDateAsString(value) };
      });
      setChanged(true);
   }, [editingAllowed]);

   const onSelectChanged = useCallback((e, value, reason) => {
      if (!editingAllowed) {
         return;
      }
      let { id } = e.currentTarget;
      const index = id.indexOf('-');
      if (index > 0) {
         id = id.substring(0, index);
      }
      if (value) {
         setItem(oldItem => {
            return { ...oldItem, [id]: value.id };
         });
      }
      setChanged(true);
   }, [editingAllowed]);

   const onRadioChanged = useCallback((e) => {
      if (!editingAllowed) {
         return;
      }
      const { name, value } = e.target;
      setItem(oldItem => {
         return { ...oldItem, [name]: Number(value) };
      });
      setChanged(true);
   }, [editingAllowed]);

   const onCheckChanged = useCallback((e) => {
      if (!editingAllowed) {
         return;
      }
      const { name, checked } = e.target;
      setItem(oldItem => {
         return { ...oldItem, [name]: checked };
      });
      setChanged(true);
   }, [editingAllowed]);

   const onFileChanged = useCallback((e) => {
      const { name, files } = e.target;
      setItem(oldItem => {
         return { ...oldItem, [name]: (files?.length) ? files[0] : null };
      });
      setChanged(true);
   }, []);

   const submitForm = useCallback((e, status) => {
      e.preventDefault();
      if (!editingAllowed) {
         return;
      }
      onItemUpdated(item, status);
      setChanged(false);
   }, [onItemUpdated, item, editingAllowed]);

   const resetForm = useCallback((e) => {
      e.preventDefault();
      setItem(props.item);
      setChanged(false);
   }, [props.item]);

   const error = (item?.errorCode?.length)
      ? <ErrorRow item={item} failed={item.failed} />
      : null;

   const callbacks = {
      onTextChanged: onTextChanged,
      onSelectChanged: onSelectChanged,
      onCheckChanged: onCheckChanged,
      onNumberChanged: onNumberChanged,
      onDateTimeChanged: onDateTimeChanged,
      onRadioChanged: onRadioChanged,
      onFileChanged: onFileChanged
   };

   const fields = renderFields({
      ...props,
      callbacks: callbacks,
      editingAllowed: editingAllowed
   }, item);

   const buttons = (noButtons) ? null :
      <ButtonRow
         newRecord={item.new}
         disabled={!changed}
      />;

   return (
      <Col xs="12" className={"p-1 m-0 pb-1 bg-white opc-blue" + ((props.borderStyle) ? " " + props.borderStyle : "")}>
         <form onSubmit={submitForm} onReset={resetForm}>
            <Container className="p-0 m-0">
               {error}
               {fields}
               {buttons}
            </Container>
         </form>
      </Col>);
}