import { withStyles } from '@mui/styles';
import { Tooltip } from '@mui/material';
import { useEffect, useRef } from 'react';
import { BuildVersion } from "../version.js";
import * as Locales from "./locales";

export const AppVersion = BuildVersion;
export const DefaultWorkingGroup = 1;   // UA
export const DefaultProfileGroup = 171; // UA 1.05
export const DefaultCacheTimeout = 60;

window.$currentUser = {};

export const setCurrentUser = (user, noPreserveContext) => {
   const oldUser = window.$currentUser;
   window.$currentUser = (user) ?? {};

   if (oldUser?.profileGroupId !== window.$currentUser?.profileGroupId ||
      oldUser?.releaseStatus !== window.$currentUser?.releaseStatus) {
      delete window.$cache[PageNames.Categories];
      delete window.$cache[PageNames.ConformanceGroups];
      delete window.$cache[PageNames.ConformanceUnits];
      delete window.$cache[PageNames.ProfileFolders];
      delete window.$cache[PageNames.Profiles];
   }
   // preserve context when switching to anonymous.
   if (!noPreserveContext && oldUser?.profileGroupId) {
      user.profileGroupId = oldUser.profileGroupId;
      user.workingGroupId = oldUser.workingGroupId;
      user.profileGroupDependencies = oldUser.profileGroupDependencies;
      user.releaseStatus = oldUser.releaseStatus;
   }
   cacheIncrementVersion();
}

export const getCurrentUser = () => window.$currentUser;

export function isEditor(item) {
   const user = getCurrentUser();
   if (user?.isAdministrator) {
      return true;
   }
   if (user?.editableWorkingGroups && item?.workingGroupId) {
      return (user?.isEditor && user?.editableWorkingGroups?.find(x => x === item?.workingGroupId)) ? true : false;
   }
   return (user?.isEditor) ?? false;
}

export const setCurrentRecord = (url, item, key, checkDependecies) => {
   const id = item.id;

   var firstUrl = load("firstUrl");

   if (!firstUrl) {
      save("firstUrl", url)
      var user = window.$currentUser;
      if (user) {
         if (item.profileGroupId && item.profileGroupId !== user.profileGroupId) {
            if (!checkDependecies || !user.profileGroupDependencies || !user.profileGroupDependencies.find(ii => ii === item.profileGroupId)) {
               user = { ...user, profileGroupId: item.profileGroupId, workingGroupId: item.workingGroupId }
            }
         }
         if (item.releaseStatus && item.releaseStatus < user.releaseStatus) {
            user = { ...user, releaseStatus: item.releaseStatus }
         }
         if (user !== window.$currentUser) {
            setCurrentUser(user, true);
         }
      }
   }

   window.$currentRecord = { url, id, key };
   cacheIncrementVersion();
}

export const getProfileGroupHistory = () => window.$profileGroupHistory ?? [];

export function updateProfileGroupHistory(user, profileGroups, workingGroups) {
   var recentlyUsed = window.$profileGroupHistory ?? [];
   if (user) {
      const pg = profileGroups.find(ii => ii.id === user.profileGroupId);
      if (pg) {
         const wg = workingGroups.find(ii => ii.id === pg.workingGroupId);
         if (wg) {
            const existing = recentlyUsed.find(ii => ii.id === pg.id);
            if (existing) {
               window.$profileGroupHistory = [existing, ...recentlyUsed.filter(ii => ii.id !== pg.id)]
            }
            else {
               const addition = { id: pg.id, name: `${wg.shortName} ${pg.name}`, workingGroupId: wg.id };
               window.$profileGroupHistory = [addition, ...recentlyUsed.slice(0, 3)];
            }
         }
      }
   }
}

export const getCurrentRecord = () => window.$currentRecord;

export function navigateForward(history, url, returnUrl, item) {
   return history.push(
      url,
      {
         url: returnUrl ?? history.location.pathname,
         item: item
      }
   );
}

export function navigateBack(history, containerUrl, item) {
   if (history.location.state) {
      if (history.location.state.url) {
         return history.push(
            history.location.state.url,
            {
               url: history.location.state.url,
               item: item
            });
      }
   }
   if (containerUrl) {
      return history.push(containerUrl, { item: item });
   }
   history.goBack();
}

export function getProfileGroupQuery() {
   const user = window.$currentUser;
   if (user?.profileGroupId && user?.releaseStatus) {
      return `pg=${user.profileGroupId}&rs=${user.releaseStatus}`;
   }
   return "";
}

export function getReleaseStatusQuery() {
   const user = window.$currentUser;
   if (user?.profileGroupId && user?.releaseStatus) {
      return `rs=${user.releaseStatus}`;
   }
   return "";
}

window.$nextKey = 10000;

export const nextKey = () => {
   window.$nextKey++;
   return window.$nextKey;
}

window.$waiters = { busy: false, queue: [] };
export const getWaiters = () => {
   return window.$waiters;
}

export const PageNames = {
   WorkingGroups: "workingGroups",
   Tags: "tags",
   Documents: "documents",
   DocumentVersions: "documentVersions",
   Persons: "persons",
   ProfileGroups: "profileGroups",
   Profiles: "profiles",
   ProfileFolders: "profileFolders",
   Categories: "categories",
   ConformanceGroups: "conformanceGroups",
   ConformanceUnits: "conformanceUnits",
   Users: "users",
   PageTitle: "pageTitle",
   ProfileGroup: "profileGroup",
   TestSuites: "testSuites",
   TestCases: "testCases",
   TestSteps: "testSteps"
}

export const PagePaths = {
   WorkingGroups: "/workinggroup/",
   Tags: "/tag/",
   Documents: "/document/",
   DocumentVersions: "/documentversion/",
   Persons: "/person/",
   ProfileGroups: "/profilegroup/",
   Profiles: "/profile/",
   ProfileFolders: "/profilefolder/",
   Categories: "/category/",
   ConformanceGroups: "/conformancegroup/",
   ConformanceUnits: "/conformanceunit/",
   Users: "/user/",
   TestSuites: "/testsuite/",
   TestCases: "/testcase/",
   TestSteps: "/teststep/"
}

export const NameToPath = {
   [PageNames.WorkingGroups]: PagePaths.WorkingGroups,
   [PageNames.Documents]: PagePaths.Documents,
   [PageNames.DocumentVersions]: PagePaths.DocumentVersions,
   [PageNames.Persons]: PagePaths.Persons,
   [PageNames.ProfileGroups]: PagePaths.ProfileGroups,
   [PageNames.Profiles]: PagePaths.Profiles,
   [PageNames.ProfileFolders]: PagePaths.ProfileFolders,
   [PageNames.Categories]: PagePaths.Categories,
   [PageNames.ConformanceGroups]: PagePaths.ConformanceGroups,
   [PageNames.ConformanceUnits]: PagePaths.ConformanceUnits,
   [PageNames.Users]: PagePaths.Users,
   [PageNames.TestSuites]: PagePaths.TestSuites,
   [PageNames.TestCases]: PagePaths.TestCases,
   [PageNames.TestSteps]: PagePaths.TestSteps
}

export const PathTypes = {
   Catagory: 1,
   ProfileFolder: 2,
   Profile: 3,
   ConformanceGroup:  4,
   ConformanceUnit: 5,
   WorkingGroup:  6,
   Document: 7,
   Tag: 8,
   TestSuite: 9
}

export const EmptyGuid = "00000000-0000-0000-0000-000000000000";

window.$cache = {};

export function updatePageTitle(title) {
   if (window.document.title !== title) {
      window.document.title = title;
      updateCache(PageNames.PageTitle, [title], true);
   }
}

export function getPageTitle() {
   const titles = findInCache(PageNames.PageTitle, 0);
   if (Array.isArray(titles) && titles?.length) {
      return titles[0];
   }
   return window.document.title;
}

export function contextPush(name, id, record, index) {
   let stack = [];
   stack = findInCache("$context", 0);
   if (!stack) {
      stack = [];
   }
   const entry = { name: name, id: id, record: record };
   if (!index || index > stack.length) {
      let found = false;
      for (let ii = stack.length - 1; ii >= 0; ii--) {
         if (name === stack[ii].name && id === stack[ii].id) {
            stack[ii].record = record;
            if (ii < stack.length - 1) {
               stack.splice(ii+1);
            }
            found = true;
            break;
         }
      }
      if (!found) {
         stack.push(entry);
      }
   }
   else {
      stack[index - 1] = entry;
      if (index < stack.length) {
         stack.splice(index);
      }
   }
   updateCache("$context", stack, true);
}

export function findInCache(name, maxAge, id) {
   if (!name) {
      return null;
   }
   let value = window.$cache[name];
   if (!value || !value.records) {
      return null;
   }
   if (maxAge > 0 && new Date().getTime() > value.timestamp + (maxAge * 1000)) {
      return null;
   }
   if (!id) {
      return value.records;
   }
   return value.records.find((ii) => ii.id === id);
}

export function findGuidInCache(name, maxAge, guid, pgid) {
   if (!name) {
      return null;
   }
   let value = window.$cache[name];
   if (!value || !value.records) {
      return null;
   }
   if (maxAge > 0 && new Date().getTime() > value.timestamp + (maxAge * 1000)) {
      return null;
   }
   return value.records.find((ii) => ii.guid === guid && ii.profileGroupId === pgid);
}

export function isInCache(name, maxAge, id) {
   if (!name) {
      return false;
   }
   let value = window.$cache[name];
   if (!value || !value.records) {
      return false;
   }
   if (maxAge > 0 && new Date().getTime() > value.timestamp + (maxAge * 1000)) {
      return false;
   }
   if (!id) {
      return value.isComplete;
   }
   return value.records.find((ii) => ii.id === id) !== undefined;
}

export function isGuidInCache(name, maxAge, guid, pgid) {
   if (!name) {
      return false;
   }
   let value = window.$cache[name];
   if (!value || !value.records) {
      return false;
   }
   if (maxAge > 0 && new Date().getTime() > value.timestamp + (maxAge * 1000)) {
      return false;
   }
   return value.records.find((ii) => ii.guid === guid && ii.profileGroupId === pgid) !== undefined;
}

export async function getVersionedRecords(name) {
   let records = findGuidInCache(name, 600);
   if (!records) {
      const results = await httpGetAndUpdateList(`api${NameToPath[name]}?all=1&${getProfileGroupQuery()}`);
      if (results && !results?.errorCode) {
         updateCache(name, results, true);
         records = results;
      }
   }
   return records;
}

export function updateCache(name, records, replace) {
   if (!name) {
      return null;
   }
   let value = window.$cache[name];
   if (!value) {
      window.$cache[name] = value = {
         timestamp: 0,
         records: []
      };
   }

   if (replace) {
      value.isComplete = true;
      if (records) {
         value.records.map(ii => {
            if (!ii) {
               return false;
            }
            let index = records.findIndex((jj) => jj.id === ii.id);
            if (index >= 0) {
               let existing = value.records[index];
               existing = { ...ii, ...existing };
               delete existing.errorCode;
               delete existing.errorText;
               value.records[index] = existing;
            }
            return true;
         });
         value.records = records;
      }
   }
   else if (records) {
      records.map(ii => {
         if (!ii) {
            return false;
         }
         let index = value.records.findIndex((jj) => jj.id === ii.id);
         if (index >= 0) {
            let existing = value.records[index];
            existing = { ...existing, ...ii };
            delete existing.errorCode;
            delete existing.errorText;
            value.records[index] = existing;
         } else {
            if (!ii.key) {
               ii.key = nextKey();
            }
            value.records.push(ii);
         }
         return true;
      });
   }

   value.timestamp = new Date().getTime();
   cacheIncrementVersion();
   return value.records;
}

export function deleteFromCache(name) {
   if (!name) {
      return null;
   }
   const keys = Object.keys(window.$cache);
   keys.map(ii => {
      if (ii.startsWith(name))
         delete window.$cache[ii];
      return ii;
   });
}

export function cacheIncrementVersion() {
   let version = window.$cache.version;
   if (!version) {
      window.$cache.version = version = 1;
      return 1;
   }
   window.$cache.version = ++version;
   if (window.$cache.onUpdate) {
      window.$cache.onUpdate();
   }
   return version;
}

export function cacheVersion() {
   let value = window.$cache["version"];
   if (!value) {
      window.$cache["version"] = value = 1;
   }
   return value;
}

export function getDate(date) {
   if (date && date?.length > 0) {
      return date;
   }
   return new Date();
}

export function getDateAsString(date) {
   if (!date) {
      return "";
   }
   if (!(date instanceof Date)) {
      date = new Date(date);
   }
   const yyyy = date.getFullYear().toString();
   const mm = (date.getMonth() + 1).toString().padStart(2, '0');
   const dd = date.getDate().toString().padStart(2, '0');
   return `${yyyy}-${mm}-${dd}`;
}

export function getBytesAsString(bytes) {
   if (!bytes) {
      return "";
   }

   let value = bytes.toString();
   let units = "bytes";

   if (bytes > 1024 && bytes < 1048576) {
      value = (bytes / 1024).toPrecision(6);
      units = "KB";
   }
   else if (bytes >= 1048576 && bytes < 1073741824) {
      value = (bytes / 1048576).toPrecision(6);
      units = "MB";
   }
   else if (bytes > 1024) {
      value = (bytes / 1073741824).toPrecision(6);
      units = "GB";
   }

   const index = value.indexOf(".");

   if (index > 0) {
      value = value.slice(0, index + 3);
   }

   return `${value} ${units}`;
}

export const FieldNames = {
   Id: "id",
   Name: "name",
   Description: "description",
   Sort: "sort",
   ReleaseStatus: "releaseStatus",
   Version: "version",
   LastUpdateTime: "lastUpdateTime",
   LastUpdatingUserId: "lastUpdatingUserId",
   ProfileGroupId: "profileGroupId",
   WorkingGroupId: "workingGroupId",
   InlineDescription: "inlineDescription",
   Search: "search",
   SearchDescription: "searchDescription"
};

export const AllValue = {
   id: -1,
   name: "All"
};

export const NoneValue = {
   id: -1,
   name: "None"
};

export const ReleaseStatus = {
   Draft: 1,
   ReleaseCandidate: 2,
   Released: 3,
   Deprecated: 4,
   Archived: 5
};

export function toString(list, value, prefix) {
   const name = Object.keys(list).find((ii) => list[ii] === value);
   return Locales.getDisplayText(`${prefix}_${name}`);
}

export const TagType = {
   Classification: 1,
   License: 2,
   Markets: 3,
   Keyword: 4,
   Namespace: 5
};

export const BooleanValue = {
   NotSet: -1,
   No: 0,
   Yes: 1
}

export const ReleaseStatusFilters = [
   { name: "releaseStatusFilter_Released", id: ReleaseStatus.Released },
   { name: "releaseStatusFilter_ReleaseCandidate", id: ReleaseStatus.ReleaseCandidate },
   { name: "releaseStatusFilter_Draft", id: ReleaseStatus.Draft }
];

export const DefaultTables = [
   { name: PageNames.WorkingGroups, path: PagePaths.WorkingGroups, maxAge: 600 },
   { name: PageNames.ProfileGroups, path: PagePaths.ProfileGroups, maxAge: 600 }
];

export function getUserProfileGroupOptions(selectAll) {
   let options = [];
   if (selectAll) {
      options.push(AllValue);
   }
   const user = getCurrentUser();
   let profileGroup = findInCache(PagePaths.ProfileGroups, 0, user.profileGroupId);
   if (profileGroup) {
      options.push({ id: profileGroup.id, name: profileGroup.fullName, sort: 0 });
      if (profileGroup.requiredProfileGroups) {
         profileGroup.requiredProfileGroups.map((ii) => {
            let pg = findInCache(PagePaths.ProfileGroups, 0, ii);
            if (pg) {
               options.push({ id: ii, name: pg.fullName, sort: ii });
            }
            return ii;
         });
      }
   }
   else if (user?.profileGroupId) {
      options.push({ id: user.profileGroupId, name: user.profileGroupId, sort: 0 });
   }
   return options;
}

export function log(message) {
   let time = new Date().getTime().toString();
   time = time.substring(time.length - 4);
   console.error(time + ': ' + message);
}

export function Exception(code, message) {
   const error = new Error(message);
   error.code = code;
   return error;
}

export function sortByName(a, b) {
   if (!a || !b) {
      return (!a) ? (!b) ? 0 : -1 : +1;
   }

   if (a.name === b.name) {
      return 0;
   }

   return (a.name < b.name) ? -1 : +1;
}

export function sortBySortThenName(a, b) {
   if (!a || !b) {
      return (!a) ? (!b) ? 0 : -1 : +1;
   }

   if (a.sort === b.sort) {
      return sortByName(a, b);
   }

   if (a.sort === undefined || b.sort === undefined) {
      return (a.sort === undefined) ? ((b.sort === undefined) ? sortByName(a, b) : -1) : +1;
   }

   return (a.sort < b.sort) ? -1 : +1;
}

const IgnoredFilterFields = [
   'expanded',
   'editing',
   'new',
   'disabled',
   'states',
   'applyFilters'
];

export const FilterResult = {
   NoMatch: 0,
   Match: 1,
   Ignore: 2
};

function applyFiltersToField(filters, item, debug) {
   if (!item) {
      return FilterResult.NoMatch;
   }

   if (item.new || (item.editing && item.expanded)) {
      return FilterResult.Match;
   }

   if (!filters) {
      return FilterResult.Match;
   }

   const keys = Object.keys(filters);

   for (let ii = 0; ii < keys.length; ii++) {
      const name = keys[ii];

      if (IgnoredFilterFields.find((jj) => name === jj)) {
         continue;
      }

      if (filters.exclude && filters.exclude.find((jj) => name === jj)) {
         continue;
      }

      const field = item[name];
      const pattern = filters[name];

      if (field === undefined || field === null) {
         if (pattern && pattern !== -1 && typeof pattern !== 'object') {
            if (debug) console.info(`A ${name} ${field} ${pattern}`);
            return FilterResult.NoMatch;
         }
         continue;
      }

      if (pattern !== undefined && pattern !== null) {
         if (typeof field === 'boolean') {
            if (pattern !== -1 && ((field && pattern <= 0) || (!field && pattern > 0))) {
               if (debug) console.info(`B ${name} ${field} ${pattern}`);
               return FilterResult.NoMatch;
            }
            continue;
         }

         if (typeof field === 'number') {
            if (pattern !== -1 && field !== Number(pattern)) {
               if (debug) console.info(`C ${name} ${field} ${pattern}`);
               return FilterResult.NoMatch;
            }
            continue;
         }

         if (typeof pattern === 'string') {
            if (pattern.length > 0) {
               if (field.toUpperCase().indexOf(pattern.toUpperCase()) < 0) {
                  if (debug) console.info(`D ${name} ${field} ${pattern}`);
                  return FilterResult.NoMatch;
               }
            }
            continue;
         }
      }
   }

   return FilterResult.Match;
}

export function applyFilters(filters, items, applyFilters, debug) {
   if (!items) {
      return [];
   }
   if (applyFilters) {
      return applyFilters(filters, items);
   }
   return items.filter((ii) => applyFiltersToField(filters, ii, debug) === FilterResult.Match);
}

export async function readResponseBody(url, response) {
   const content = response.headers.get("Content-Type");

   if (content && content.indexOf("json") < 0) {
      console.error("URL: " + url);
      console.error("UnexpectedResponse: " + await response.text());
      return null;
   }

   return await response.json();
}

window.$requestCount = 0;

export async function httpGet(url, controller) {
   try {
      console.info(`httpGet (${window.$requestCount++}): ${url}`);
      const response = await fetch(url, (controller) ? { signal: controller.signal } : {});
      if (response.redirected) {
         console.info(`httpRedirect: ${response.url}`);
         window.location.href = response.url;
      }
      if (response.ok) {
         const result = await readResponseBody(url, response);
         if (result && !result.failed) {
            return result;
         }
         else {
            console.info(`httpGet: ${result?.errorCode} ${result?.errorText}`);
            return {
               errorCode: result?.errorCode,
               errorText: result?.errorText
            }
         }
      }
      else {
         console.info(`httpGet: ${response.status} ${response.statusText}`);
         return {
            errorCode: `HTTP ${response.status}`,
            errorText: response.statusText
         }
      }
   }
   catch (exception) {
      if (exception.code) {
         console.info(`httpGet: ${exception.code} ${exception.message}`);
         return {
            errorCode: exception.code,
            errorText: exception.message
         }
      } else {
         console.info(`httpGet: UnexpectedError ${exception}`);
         return {
            errorCode: 'UnexpectedError',
            errorText: exception.toString()
         }
      }
   }
};

export function processGetResponseAndUpdateList(url, response, records) {
   if (!response || response.errorCode) {
      return records;
   }
   let newRecords = response.result ?? [];
   console.info(`httpResponse: ${newRecords.length} records [${url}]`);
   if (records) {
      records.map(ii => {
         let index = newRecords.findIndex((jj) => jj.id === ii.id);
         if (index >= 0) {
            let record = newRecords[index];
            record = { ...ii, ...newRecords[index] };
            delete record.errorCode;
            delete record.errorText;
            newRecords[index] = record;
         }
         return true;
      });
   }
   newRecords.map(ii => {
      if (!ii.key) {
         ii.key = nextKey();
      }
      return true;
   });
   return newRecords;
}

export function processGetReponseAndUpdateItem(url, response, item) {
   if (!response || response.errorCode) {
      return item;
   }
   let newItem = response.result;
   console.info(`httpResponse: ${newItem?.name} [${url}]`);
   if (item !== undefined) {
      newItem = { ...item, ...newItem };
      delete newItem.errorCode;
      delete newItem.errorText;
   }
   if (!newItem.key) {
      newItem.key = nextKey();
   }
   return newItem;
}

export async function httpGetAndUpdateList(url, records) {
   try {
      const response = await fetch(url);
      if (response.ok) {
         const result = await readResponseBody(url, response);
         if (result && !result.failed) {
            let newRecords = result.result;
            // console.info(`httpGetAndUpdateList: ${url} [${newRecords.length} records]`);
            if (records) {
               records.map(ii => {
                  let index = newRecords.findIndex((jj) => jj.id === ii.id);
                  if (index >= 0) {
                     let record = newRecords[index];
                     record = { ...ii, ...newRecords[index] };
                     delete record.errorCode;
                     delete record.errorText;
                     newRecords[index] = record;
                  }
                  return true;
               });
            }
            newRecords.map(ii => {
               if (!ii.key) {
                  ii.key = nextKey();
               }
               return true;
            });
            return newRecords;
         }
         else {
            console.error(`httpGetAndUpdateList: ${url} ${result?.errorCode} ${result?.errorText}`);
            return {
               errorCode: result?.errorCode ?? "CouldNotParseResponse",
               errorText: result?.errorText
            }
         }
      }
      else {
         console.error(`httpGetAndUpdateList: ${url} HTTP ${response.status} ${response.statusText}`);
         return {
            errorCode: `HTTP ${response.status}`,
            errorText: response.statusText
         }
      }
   }
   catch (exception) {
      if (exception.code) {
         return {
            errorCode: exception.code,
            errorText: exception.message
         }
      } else {
         return {
            errorCode: 'UnexpectedError',
            errorText: exception.toString()
         }
      }
   }
};

export async function httpGetAndUpdateItem(url, item) {
   try {
      if (!item) {
         item = {};
      }
      // console.info(`httpGetAndUpdateItem: ${url}`);
      const response = await fetch(url);
      if (response.ok) {
         const result = await readResponseBody(url, response);
         if (result && !result.failed) {
            let newItem = result.result;
            if (item !== undefined) {
               newItem = { ...item, ...newItem };
               delete newItem.errorCode;
               delete newItem.errorText;
            }
            if (!newItem.key) {
               newItem.key = nextKey();
            }
            return newItem;
         }
         else {
            item.errorCode = result?.errorCode;
            item.errorText = result?.errorText;
         }
      }
      else {
         item.errorCode = `HTTP ${response.status}`;
         item.errorText = response.statusText;
      }
   }
   catch (exception) {
      if (exception.code) {
         item.errorCode = exception.code;
         item.errorText = exception.message;
      } else {
         item.errorCode = 'UnexpectedError';
         item.errorText = exception.toString();
      }
   }

   return item;
};

async function fetchMultipleItems(definition, path) {
   const newRecords = await httpGetAndUpdateList(path, null);
   if (newRecords?.errorCode) {
      console.error(`${newRecords.errorCode}: ${newRecords.errorText}`);
      return false;
   }
   updateCache(definition.name, newRecords, true);
   return true;
};

export async function fetchMultipleLists(data) {
   let waiters = getWaiters();
   if (waiters.busy) {
      return new Promise(resolve => {
         return waiters.queue.push({
            resolve: resolve,
            data: data
         })
      });
   }

   waiters.busy = true;

   if (data) {
      for (let ii = 0; ii < data.length; ii++) {
         try {
            const definition = { ...data[ii] };

            if (!definition.path) {
               continue;
            }

            let path = `api${definition.path}?${getProfileGroupQuery()}`;

            if (definition.maxAge === undefined) {
               definition.maxAge = 120;
            }

            if (definition.maxAge > 0) {
               if (isInCache(definition.name, definition.maxAge)) {
                  continue;
               }
            }

            await fetchMultipleItems(definition, path);
         }
         catch (exception) {
            console.error("web error: " + exception);
            console.error(`data: ${JSON.stringify(data[ii])}`);
         }
      }
   }

   waiters.busy = false;

   if (waiters.queue.length > 0) {
      const queue = waiters.queue; 
      waiters.queue = [];

      await queue.map(async (ii) => {
         ii.resolve(await fetchMultipleLists(ii.data));
      })
   }

   return true;
}

export function isNullId(id) {
   if (id && id !== -1) {
      return false;
   }
   return true;
}

export function toOptionValue(id) {
   if (id) {
      if ((id.id && id.id > 0) || id > 0) {
         return id.toString();
      }
   }
   return -1;
}

export function toOptionGuidValue(id) {
   if (id) {
      if (id.guid && id.guid.length) {
         return id.guid;
      }
   }
   return -1;
}

export function toProfileGroupList(input) {
   let output = [];

   if (input && input.length) {
      input.map((ii) => output.push({ id: ((ii.id) ?? -1), name: (ii.fullName) ?? ii.name, sort: ((ii.sort) ?? 0) }));
   }

   return output;
}

export function toGuidList(input) {
   let output = [];

   if (input && input.length) {
      input.map((ii) => output.push({ id: ((ii.guid) ?? -1), name: ii.name, sort: ((ii.sort) ?? 0) }));
   }

   return output;
}

export function load(key, defaultValue) {

   if (key) {
      try {
         const json = sessionStorage.getItem(key);
         if (json) {
            return JSON.parse(json);
         }
      }
      catch (error) {
         console.warn("Could not restore " + key + "from storage: " + error);
      }
   }

   return defaultValue;
}

export function save(key, value) {

   if (key) {
      try {

         if (key === 'undefined') {
            return;
         }

         if (value) {
            sessionStorage.setItem(key, JSON.stringify(value));
         }
         else {
            sessionStorage.removeItem(key);
         }
      }
      catch (error) {
         console.warn("Could not save " + key + "to storage: " + error);
      }
   }
}

export function useAsync(onInvoke, onSuccess) {
   useEffect(() => {
      let mounted = true;
      onInvoke().then(ii => {
         if (mounted) onSuccess(ii);
      });
      return () => { mounted = false };
   }, [onInvoke, onSuccess]);
}

export const StyledTooltip = withStyles({
   tooltip: {
      color: "white",
      backgroundColor: "#203864",
      fontSize: "smaller"
   }
})((props) => (
   <Tooltip enterDelay={3000} enterNextDelay={3000} {...props} />
));

export function toDateString(date) {
   if (!date) {
      return "";
   }

   let value = date.toString();

   let index = value.lastIndexOf('.');

   if (index !== -1) {
      value = value.substr(0, index);
   }

   return value.replace("T", " ");
}

export function getMaxSort(records, filterBy) {
   if (!records || !records.length) {
      return 1;
   }

   if (filterBy) {
      records = records.filter(filterBy)
   }

   let max = 0;

   records.map((ii) => {
      if (max < ii.sort) max = ii.sort;
      return max;
   });

   return max;
}

export function sanitizeString(value) {
   let sanitizedValue = "";
   if (value) {
      let ii = 0;
      let end = -1;
      for (ii = 0; ii < value.length; ii++) {
         if (value[ii] === '\n') {
            sanitizedValue += "<br/>";
            continue;
         }
         if (value[ii] === '<') {
            let test = value.substr(ii + 1).trim();
            if (test.startsWith("/")) {
               test = test.substr(1).trim();
            }
            if (test.startsWith("script")) {
               end = value.indexOf("script", ii);
               ii = end + 5;
               end = value.indexOf(">", end);
               if (end > 0) {
                  ii = end;
               }
               sanitizedValue += " script ";
               continue;
            }
         }
         sanitizedValue += value[ii];
      }
   }
   return sanitizedValue;
}

export function getId(id, location, apiPath) {
   if (id) {
      return id;
   }

   if (location && apiPath) {
      let index = location.pathname.indexOf(apiPath);
      if (index >= 0) {
         var parsedId = location.pathname.substring(index + apiPath.length);
         if (parsedId) {
            index = parsedId.indexOf("/");
            if (index >= 0) {
               parsedId = parsedId.substring(0, index);
            }
            id = Number(parsedId);
            return id;
         }
      }
   }

   return 0;
}

export function getKey(location, name) {
   if (location) {
      let index = location.pathname.indexOf("?");
      if (index >= 0) {
         var parsedId = location.pathname.substring(index + 1);
         if (parsedId) {
            const param = `${name}=`;
            const start = parsedId.indexOf(param);
            if (start >= 0) {
               const end = parsedId.indexOf("&", start);
               if (end >= 0) {
                  parsedId = parsedId.slice(start + param.length, end);
               }
               else {
                  parsedId = parsedId.substring(start + param.length);
               }
               const key = Number(parsedId);
               return key;
            }
         }
      }
   }

   return 0;
}

export function setDefaultOption(name, options, filters) {
   let value = null;
   const option = options[name];
   if (isNullId(option)) {
      value = option.find(ii => ii.id === AllValue.id);
   }
   else if (option?.length) {
      value = option.find(ii => ii.id === filters[name]);
   }
   if (!value) {
      filters[name] = options[name][0]?.id;
   }
}

export async function httpPost(url, item) {
   try {
      const requestOptions = {
         method: 'POST',
         headers: { 'Content-Type': 'application/json' },
         body: JSON.stringify((item.toJson) ? item.toJson(item) : item)
      };

      console.info(`httpPost: ${url}`);
      // console.info("BODY: " + requestOptions.body);
      const response = await fetch(url, requestOptions);
      if (response.redirected) {
         console.info(`httpRedirect: ${response.url}`);
         window.location.href = response.url;
      }
      if (response.ok) {
         const result = await readResponseBody(url, response);
         if (result) {
            if (!result.failed) {
               if (item.updateWithResult) {
                  item.updateWithResult(result.result, item);
               }
               else {
                  item = { ...item, ...result.result };
               }
               item.new = false;
               item.hasWriteAccess = result.result.hasWriteAccess;
            }
            else {
               item.errorCode = result.errorCode;
               item.errorText = result.errorText;
               item.expanded = true
            }
         }
      }
      else {
         item.errorCode = `HTTP ${response.status}`;
         item.errorText = response.statusText;
         item.expanded = true;
      }
   }
   catch (exception) {
      if (exception.code) {
         item.errorCode = exception.code;
         item.errorText = exception.message;
      } else {
         item.errorCode = 'UnexpectedError';
         item.errorText = exception.toString();
      }
      item.expanded = true;
   }

   return item;
}

async function httpCheckResponse(url, response) {
   if (response.ok) {
      return await readResponseBody(url, response);
   }
   return {
      errorCode: `HTTP ${response.status}`,
      errorText: response.statusText,
      failed: true
   };
}

export async function httpUpload(url, params, file, onDone) {
   try {
      const chunkSize = 262144;
      let offset = 0;
      let effectiveParams = ((params) ? `${params}&` : "?") + `offset=0&final=${file.size}`;
      console.info(`CHUNK: 0 ${chunkSize}`);
      let blob = file.slice(0, chunkSize);
      offset += chunkSize;

      const checkStatus = async (previous) => {
         const requestOptions = { method: 'POST', body: new FormData() };
         effectiveParams = ((params) ? `${params}&` : "?");
         effectiveParams += `opid=${previous?.operationId ?? ''}&luid=${previous?.lastUpdateId ?? ''}`;
         effectiveParams += `&offset=${file.size}&final=${file.size}`;
         fetch(`${url}${effectiveParams}`, requestOptions).then(async (response) => {
            let result = await httpCheckResponse(url, response);
            if (onDone) {
               onDone(result);
            }
            if (result.incomplete) {
               await checkStatus(result);
            }
         });
      }
      const processResponse = async (response) => {
         let result = await httpCheckResponse(url, response);
         if (offset >= file.size) {
            if (onDone) {
               onDone(result);
            }
            if (result.incomplete) {
               await checkStatus(result);
            }
         }
         if (offset < file.size) {
            console.info(`CHUNK: ${offset} ${chunkSize}`);
            blob = file.slice(offset, offset + chunkSize);
            const data = new FormData();
            data.append('File', blob, file.name);

            const requestOptions = { method: 'POST', body: data };
            effectiveParams = ((params) ? `${params}&` : "?") + `offset=${offset}&final=${file.size}`;
            fetch(`${url}${effectiveParams}`, requestOptions).then(async (response) => {
               await processResponse(response);
            });

            offset += chunkSize;
         }
      }

      const data = new FormData();
      data.append('File', blob, file.name);
      const requestOptions = { method: 'POST', body: data };
      fetch(`${url}${effectiveParams}`, requestOptions).then(async (response) => {
         await processResponse(response);
      });

      //const data = new FormData();
      //data.append('File', file);
      //const requestOptions = {
      //   method: 'POST',
      //   body: data
      //};
      //console.info(`httpUpload: ${url} ${reader.result}`);
      //await fetch(`${url}${params}`, requestOptions);

      //console.info(`httpUpload: ${url}`);

      //const requestOptions = {
      //   method: 'POST',
      //   body: reader.result
      //};
      //fetch(`${url}${params}`, requestOptions).then(async (response) => {
      //   let result = await httpCheckResponse(url, response);
      //   if (onDone) {
      //      onDone(result);
      //   }
         //if (result.incomplete) {
         //   const statusUrl = `${url}/status/${result.operationId}`;
         //   let lastUpdateId = result.lastUpdateId;
         //   while (true) {
         //      const response = await fetch(`${statusUrl}?up=${lastUpdateId}`);
         //      result = await httpCheckResponse(statusUrl, response);
         //      if (onDone) {
         //         onDone(result);
         //      }
         //      if (!result.incomplete) {
         //         return result;
         //      }
         //      lastUpdateId = result.lastUpdateId;
         //   }
      //   //}
      //});
   }
   catch (exception) {
      if (exception.code) {
         return {
            errorCode: exception.code,
            errorText: exception.message,
            failed: true
         };
      }
      return {
         errorCode: 'UnexpectedError',
         errorText: exception.toString(),
         failed: true
      };
   }
}

export function processPostReponseAndUpdateList(response, item, list) {
   if (!response || response.errorCode) {
      return list;
   }
   if (list) {
      if (!list.find((ii) => ii.key === item.key)) {
         list.push(item);
      }
      list = list.map((ii) => {
         if (ii.key !== item.key) {
            delete ii.errorCode;
            delete ii.errorText;
            return ii;
         }
         return item;
      });
   }
   return list;
}

export async function httpPostAndUpdateList(url, item, list) {
   try {
      const requestOptions = {
         method: 'POST',
         headers: { 'Content-Type': 'application/json' },
         body: JSON.stringify((item.toJson) ? item.toJson(item) : item)
      };

      console.info(`httpPostAndUpdateList: ${url}`);
      // console.info("BODY: " + requestOptions.body);
      const response = await fetch(url, requestOptions);

      if (response.ok) {
         const result = await readResponseBody(url, response);

         if (result) {
            item.errorCode = result.errorCode;
            item.errorText = result.errorText;

            if (!result.failed) {
               if (item.updateWithResult) {
                  item.updateWithResult(result.result, item);
               }
               else {
                  item = { ...item, ...result.result };
               }

               item.new = false;
               item.hasWriteAccess = result.result.hasWriteAccess;
            }
         }
      }
      else {
         item.errorCode = `HTTP ${response.status}`;
         item.errorText = response.statusText;
      }
   }
   catch (exception) {
      if (exception.code) {
         item.errorCode = exception.code;
         item.errorText = exception.message;
      } else {
         item.errorCode = 'UnexpectedError';
         item.errorText = exception.toString();
      }
   }

   if (list) {
      if (!list.find((ii) => ii.key === item.key)) {
         list.push(item);
      }
      list = list.map((ii) => {
         if (ii.key !== item.key) {
            delete ii.errorCode;
            delete ii.errorText;
            return ii;
         }
         return item;
      });
      return list;
   }

   return item;
}

export async function deleteItem(url, item, list) {
   try {
      console.info(`deleteItem: ${url}`);
      const response = await fetch(url);

      if (response.ok) {
         const result = await readResponseBody(url, response);

         if (result) {
            item.errorCode = result.errorCode;
            item.errorText = result.errorText;
            item.expanded = true;

            if (!result.failed) {
               item.id = 0;
               item.expanded = false;
            }
         }
      }
      else {
         item.errorCode = `HTTP ${response.status}`;
         item.errorText = response.statusText;
         item.expanded = true;
      }
   }
   catch (exception) {
      if (exception.code) {
         item.errorCode = exception.code;
         item.errorText = exception.message;
      } else {
         item.errorCode = 'UnexpectedError';
         item.errorText = exception.toString();
      }
      item.expanded = true;
   }

   if (list) {
      list = list.filter((ii) => (item.id !== 0 || ii.key !== item.key));
      list.map((ii) => {
         if (ii.key !== item.key) {
            delete ii.errorCode;
            delete ii.errorText;
         }
         return true;
      });
      return list;
   }

   return item;
}

export async function multiSelectListSelectItem(url, parent, item) {
   if (item) {
      delete item.errorCode;
      delete item.errorText;
      if (url && parent) {
         if (!item.selected) {
            await httpGetAndUpdateItem(`api${url}add/${parent?.id ?? 0}/${item?.id}`, item);
         }
         else {
            await httpGetAndUpdateItem(`api${url}remove/${parent?.id??0}/${item?.id}`, item);
         }
      }
      if (!item.errorCode) {
         item.selected = !item.selected;
         return item;
      }      
   }
   return null;
}

export function createNewItem(fields, parentFieldName, parentId) {
   let item = {
      id: 0,
      expanded: true,
      editing: true,
      new: true
   };

   fields.map((ii) => {
      return item[ii.name] = ii.defaultValue
   });

   if (parentFieldName && parentId) {
      item[parentFieldName] = parentId;
   }

   if (item.profileGroupId) {
      const user = getCurrentUser();
      item.profileGroupId = (user?.profileGroupId) ?? item.profileGroupId;
   }

   return item;
}

export async function simpleFetchItem(url, id, useRawUrl) {
   if (id === -1) {
      const newItem = {
         key: nextKey(),
         id: -1,
         expanded: true,
         editing: true,
         new: true,
         hasWriteAccess: true
      };
      return newItem;
   }
   if (id) {
      const urlToUse = (useRawUrl) ? `api${url}${id}` : `api${url}get/${id}`;
      const newItem = await httpGetAndUpdateItem(urlToUse);
      if (!newItem.errorCode) {
         updateCache(url, [newItem]);
      } else {
         console.error(`[simpleFetchItem] ${newItem.errorCode}: ${newItem.errorText}`);
      }
      return newItem;
   }
   return null;
}

export async function findOrFetchById(url, id) {
   const item = findInCache(url, 0, id)
   if (item) {
      return item;
   }
   return await simpleFetchItem(url, id);
}

export async function simpleFetchItemByGuid(url, guid, pgid) {
   if (guid) {
      const newItem = await httpGetAndUpdateItem(`api${url}getbyguid/${guid}/${pgid??""}`);
      if (!newItem.errorCode) {
         updateCache(url, [newItem]);
      } else {
         console.error(`[simpleFetchItemByGuid] ${newItem.errorCode}: ${newItem.errorText}`);
      }
      return newItem;
   }
   return null;
}

export async function findOrFetchByGuid(url, guid, pgid) {
   const item = findGuidInCache(url, 0, guid, pgid)
   if (item) {
      return item;
   }
   return await simpleFetchItemByGuid(url, guid, pgid);
}

export async function simpleUpdateItem(url, itemToUpdate, useRawUrl) {
   if (itemToUpdate) {
      delete itemToUpdate.errorCode;
      delete itemToUpdate.errorText;
      const urlToUse = (useRawUrl) ? `api${url}${itemToUpdate.id}` : `api${url}put/${itemToUpdate.id}`;
      const newItem = await httpPostAndUpdateList(urlToUse, itemToUpdate);
      if (!newItem.errorCode) {
         updateCache(url, [newItem]);
      }
      return newItem;
   }
   return null;
}

export async function simpleUpdateItemInList(url, itemToUpdate, list) {
   if (itemToUpdate) {
      delete itemToUpdate.errorCode;
      delete itemToUpdate.errorText;
      const newList = await httpPostAndUpdateList(`api${url}put/${itemToUpdate.id}`, itemToUpdate, list);
      if (!itemToUpdate.errorCode) {
         updateCache(url, newList);
      }
      return newList;
   }
   return null;
}

export async function updateComponentItem(url, parent, item) {
   if (item) {
      delete item.errorCode;
      delete item.errorText;
      var result = await httpPost(`api${url}update/${parent?.id ?? 0}/${item?.id}`, item);
      if (result.errorCode) {
         return null;
      }
      return result;
   }
   return null;
}

export async function simpleDeleteItem(url, itemToDelete, useRawUrl) {
   if (itemToDelete) {
      delete itemToDelete.errorCode;
      delete itemToDelete.errorText;
      if (!itemToDelete.id) {
         return itemToDelete;
      }
      const urlToUse = (useRawUrl) ? `api${url}${itemToDelete.id}` : `api${url}delete/${itemToDelete.id}`;
      await httpGetAndUpdateItem(urlToUse, itemToDelete);
      if (!itemToDelete.errorCode) {
         deleteFromCache(url, itemToDelete.id);
      }
      return { ...itemToDelete };
   }
   return null;
}

export function useWhyDidYouUpdate(name, props) {
   // Get a mutable ref object where we can store props ...
   // ... for comparison next time this hook runs.
   const previousProps = useRef();

   useEffect(() => {
      if (previousProps.current) {
         // Get all keys from previous and current props
         const allKeys = Object.keys({ ...previousProps.current, ...props });
         // Use this object to keep track of changed props
         const changesObj = {};
         // Iterate through keys
         allKeys.forEach(key => {
            // If previous is different from current
            if (previousProps.current[key] !== props[key]) {
               // Add to changesObj
               if (key === "items") {
                  changesObj[key] = {
                     from: "[...]",
                     to: "[...]"
                  };
               }
               else {
                  changesObj[key] = {
                     from: previousProps.current[key],
                     to: props[key]
                  };
               }
            }
         });

         // If changesObj not empty then output to console
         if (Object.keys(changesObj).length) {
            console.error(`[why-did-you-update] ${name}, ${JSON.stringify(changesObj)}`);
         }
      }

      // Finally update previousProps with current props for next hook call
      previousProps.current = props;
   });
}

export function useEffectDebug(effectHook, dependencies, name) {
   // Get a mutable ref object where we can store dependencies ...
   // ... for comparison next time this hook runs.
   const previousDependencies = useRef();

   useEffect(() => {
      if (previousDependencies.current) {
         // Get all keys from previous and current dependencies
         const allKeys = Object.keys({ ...previousDependencies.current, ...dependencies });
         // Use this object to keep track of changed dependencies
         const changesObj = {};
         // Iterate through keys
         allKeys.forEach(key => {
            // If previous is different from current
            if (previousDependencies.current[key] !== dependencies[key]) {
               // Add to changesObj
               changesObj[key] = {
                  from: previousDependencies.current[key],
                  to: dependencies[key]
               };
            }
         });

         // If changesObj not empty then output to console
         if (Object.keys(changesObj).length) {
            console.error(`[why-did-you-update] ${name}, ${JSON.stringify(changesObj)}`);
         }
      }

      // Finally update previousdependencies with current dependencies for next hook call
      previousDependencies.current = dependencies;
   });

   useEffect(effectHook, [...dependencies, effectHook]);
}

async function fetchProfileGroup(id) {
   const url = `api${PagePaths.ProfileGroups}get/${id}`;
   const response = await httpGet(url);
   if (response?.errorCode) {
      return null;
   }
   let item = processGetReponseAndUpdateItem(url, response);
   return item;
}

export async function fetchProfileGroupDependencies(id) {
   const key = `${PagePaths.ProfileGroups}dependencies`
   const items = findInCache(key, DefaultCacheTimeout);
   if (items) {
      return items;
   }
   var primary = await fetchProfileGroup(id);
   if (primary) {
      let core = null;
      if (primary.coreDependencyId) {
         core = await fetchProfileGroup(primary.coreDependencyId);
      }
      const url = `api${key}/${id}`
      const response = await httpGet(url);
      if (response?.errorCode) {
         return [primary];
      }
      let items = processGetResponseAndUpdateList(url, response);
      if (core) {
         items = [core, ...(items.sort(sortBySortThenName))];
      }
      items = [primary, ...(items.sort(sortBySortThenName))];
      updateCache(key, items, true);
      return items;
   }
   return [];
}

export async function fetchRecords(url, args, all) {
   const key = `api${url}${(args) ? (`?${args}${(all) ? '&all=1' : ''}`): ''}`;
   let items = findInCache(key, DefaultCacheTimeout);
   if (items) {
      return items;
   }
   const response = await httpGet(key);
   if (response?.errorCode) {
      return [];
   }
   items = processGetResponseAndUpdateList(key, response);
   updateCache(key, items, true);
   return items.sort(sortBySortThenName);
}

// select group
export function groupByProfileGroup(profileGroups, item) {
   const group = profileGroups.find(ii => ii.id === item.profileGroupId);
   if (!group) {
      return { id: -1, name: `${Locales.getDisplayText("notAssigned")}`, sort: 0, expanded: true };
   }
   return {
      id: group.id,
      name: `${group.fullName}`,
      sort: group.sort,
      expanded: true
   }
}

// select group
export function selectGroupByGuid(item, list, parentPgId, guidFieldName, pgidFieldName) {
   const group = list.find(
      ii => ii.guid === item[guidFieldName] &&
         ii.profileGroupId === item[pgidFieldName]);
   if (!group) {
      return { id: -1, name: Locales.getDisplayText("notAssigned"), expanded: true };
   }
   let name = `${group.name}`;
   if (group.profileGroupId !== parentPgId) {
      const pg = findInCache(PagePaths.ProfileGroups, 0, group.profileGroupId);
      if (pg) {
         name = `[${pg.fullName}] ${group.name}`;
      }
   }
   return { id: group.guid, name: name, sort: group.sort, expanded: true };
}

